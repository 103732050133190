import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";
import { Checkbox, Select, Slider } from 'antd';
import { getShiftCodeList } from "../../../shiftManagement/actionMethods/actionMethods";



export default function PlanActualReportFilter (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [selectedDepartment,SetSelectedDepartemnt] = useState(...props.department.map(el=>el.sub_department));
    const [department,SetDeparment]= useState([]);
    const [employee,SetEmployee]=useState([]);
    // const [filterSlide,SetFilterSlide]=useState(100);
    const [filterFOP,SetFilterFOP] =  useState(false);
    const [initial,SetInitial]= useState(false)
    const [filterBy, SetFilterBy] = useState('day');
    const [shiftCode,SetShiftCode]= useState(['p','s','m'])
    const [shiftCodeList,SetShiftCodeList]= useState([]);
    const [shiftTime,SetShiftTime]= useState([]);
    const [onTime,SetOnTime] = useState([30,-30]);
    const [lateTime,SetLateTime] = useState([60,-60]);

    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;

    const marks= {
    "-8":"-4h",
    "-7":"-3h:30m", 
    "-6": '-3h',
    "-5": '-2h30m',
    "-4": '-2h',
    "-3": '-1h30m',
    "-2": '-1h', 
    "-1": '-30m',
    0: '0m',
    1: '30m',
    2: '1h',
    3: '1h:30m',
    4: '2h',
    5: '2h:30min',
    6: '3h',
    7: '3h:30m',
    8: '4h',
  };

    
    let workday =props.workday;
    // const [volume,SetVolume]= useState('');    

    useEffect(()=>{

      if(!initial && props.department.length > 0){
        // SetSelectedDepartemnt(props.department.map(el=>el.name))
        
        let dept =props.department.reduce((prev,current)=>[...prev,...current.sub_department,current.name],[])
       
        SetSelectedDepartemnt(dept)

        // SetSelectedDepartemnt(dept)
        SetDeparment(dept);
        SetInitial(true);
      }
      let requestBody={}

      
    },[props.department,initial])

    useEffect(()=>{
      getShiftCodeList(userSession,org_id,sub_org_id).then(res=>{
        if(res.status===200){
          SetShiftCodeList(res.data);
        }

      })

    },[])

    function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }

    function filterResultHandler(){
        SetFilterFOP(true);
        let start= workday.start_hour.split(":");
        let end =workday.end_hour.split(":");
       
        let sftCode =[];
        let smallShiftCode = shiftCodeList.map(el=>el.shift_group)
        for(let i=0;i<shiftCode.length;i++){
          sftCode.push(...smallShiftCode.filter(el=>el.includes(shiftCode[i]) || el.toLowerCase().includes(shiftCode[i]) ));
        }
        
        if(shiftCode.length == 0){
          // sftCode.push('unknown');
          sftCode.push(...smallShiftCode);
        }
        else if(shiftCode.indexOf('u') > -1){
          // sftCode.push('unknown');
        }

        
let allShiftTime = [
  {
    "key": "s",
    "value": "s",
    "children": "Afternoon / Evening (s)",
    "start": "09:30",
    "end": "18:00"
    // "end": "18:30"
  },
  {
    "key": "p",
    "value": "p",
    "children": "Morning (P)",
    "start": props.workday.start_hour ==="00:00:00"?"00:00":"00:30",
    // "end": "10:30"
    "end": "10:00"
  },
  {
    "key": "m",
    "value": "m",
    "children": "Night (M)",
    "start": "17:30",
    "end": "01:00"
    // "end": "01:30"
  }
]
        let requestBody={
          date:moment(dates.start).format('YYYY-MM-DD') ,
          start_date:moment(dates.start).format('YYYY-MM-DD') ,
          end_date:moment(dates.end).format('YYYY-MM-DD') ,
          department:selectedDepartment,
          report_by:filterBy,
          employee:props.employee,
          shift:sftCode,
          shiftTime:shiftTime.length > 0?shiftTime:allShiftTime,
          yesTime:onTime,
          noTime:lateTime
        }


        props.filterResultHandler(requestBody,true);
    }

    function exportToExcel(){
        props.exportEnable(true);
    }
    
    useEffect(()=>{
      return()=>{
        props.filterResultHandler({},false);
      }
    },[])
    useEffect(()=>{

      setDate((prev)=>({end:props.date,start:props.date}) )
    },[props.date])



function onChangeDepartment(val){

  let dept =props.department.reduce((prev,current)=>[...prev,...current.sub_department,current.name],[])


  // let dept =props.department
  if(val.indexOf('all')> -1){
      let value =[];

      for(let i=0;i<dept.length;i++){
        value.push(dept[i])
      }

      if((val.length -1) == value.length ){
        SetSelectedDepartemnt([]);  

      }else{
        SetEmployee(props.employee)
        SetSelectedDepartemnt(value);
      }

      
  }else{

    SetEmployee(props.employee.filter(el=>val.indexOf(el.name) > -1))
    SetSelectedDepartemnt(val);
  }
  props.filterResultHandler({},false)
  SetExportEnable(false);
  // SetExportEnable(false);
}

function onChangeShift(val,label){


  let dept= ['s','p','m'];
  let allShift =[
  {
    "key": "s",
    "value": "s",
    "children": "Afternoon / Evening (s)",
    "start": "09:30",
    "end": "18:00"
    // "end": "18:30"
  },
  {
    "key": "p",
    "value": "p",
    "children": "Morning (P)",
    "start": props.workday.start_hour ==="00:00:00"?"00:00":"00:30",
    // "end": "10:30"
    "end": "10:00"
  },
  {
    "key": "m",
    "value": "m",
    "children": "Night (M)",
    "start": "17:30",
    "end": "01:00"
    // "end": "01:30"
  }
]
console.log("allShift",allShift,props.workday.start_hour)
  if(val.indexOf('all')> -1){
      let value =[];

      
      for(let i=0;i<dept.length;i++){
        value.push(dept[i])
      }

      if((val.length -1) == value.length ){
        SetShiftCode([]);  
        SetEmployee([])
        SetShiftTime([]);
      }else{
        // SetEmployee(props.employee)
        SetShiftCode(value);
        SetShiftTime(allShift);
      }

      
  }else{

    // SetEmployee(props.employee.filter(el=>val.indexOf(el.name) > -1))
    SetShiftCode(val);
    SetShiftTime(label);
  }
  props.filterResultHandler({},false)
  SetExportEnable(false);
  // SetExportEnable(false);
}

    
function convertMinutesToHoursAndMinutes(minutes) {
    const isNegative = minutes < 0;
    
    const absoluteMinutes = Math.abs(minutes);
    const hours = Math.floor(absoluteMinutes / 60);
    const remainingMinutes = absoluteMinutes % 60;
    let sign = isNegative?"-":""
    return  sign+Math.abs(hours)+"hr:"+ Math.abs(remainingMinutes)+"min";
}

const tipFormatter = (index, value) => {
    if (index === 0 && value > 0) return convertMinutesToHoursAndMinutes(0);
    if (index === 1 && value < 0) return convertMinutesToHoursAndMinutes(0);
    return convertMinutesToHoursAndMinutes(value);
  };
function onTimeHandler(val){
 
  if(val[0] < 0 &&  val[1] > 0){
    SetOnTime(val);
  }
}

  function lateTimeHandler(val){
    

    if(val[0] < 0 &&  val[1] > 0){
      SetLateTime(val);
    }
  }

  
    return (<>
<Row className="">

                  <Col lg={2} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        value={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false);props.filterResultHandler({},false);SetInitial(false);props.dataReloadOnboard({start:date,end:dates.end}); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Date")}
                        maxDate={dates.end}
                        // minDate={new Date('2023-01-01')}
                        minDate={new Date('2023-01-01')}
                        
                      />
                   
                    </div>
                  </Col>
                                    <Col lg={2} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("End Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.end}
                        value={dates.end}
                        onChange={(date) => {handleDate(date, "end");SetExportEnable(false);props.filterResultHandler({},false);SetInitial(false);props.dataReloadOnboard({start:dates.start,end:date}) }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Date")}
                        maxDate={props.date}
                        // minDate={new Date('2023-01-01')}
                        minDate={dates.start}
                        
                      />
                   
                    </div>
                  </Col>
                  <Col lg={3} className="p-r-0 report-filter report-filter2">
                      <label>
                        {getTranslatedText("Department")}
                      </label>
                        <Select
                          onChange={(e)=>onChangeDepartment(e)}
                          style={{width:"100%"}}
                          mode="multiple"
                          value={selectedDepartment}
                        >
                          <Select.Option value="all">Select All</Select.Option>
                          {/* {props.department?props.department.map((el,index)=> <Select.Option key={"dept"+index+el.name} value={el.name}>{el.name}</Select.Option>):""} */}
                          {department?department.map((el,index)=> <Select.Option key={"dept"+index+el} value={el}>{el}</Select.Option>):""}
                          

                        </Select>
                  </Col>
                  <Col lg={2} className="p-r-0 report-filter report-filter2">
                      <label>
                        {getTranslatedText("Shift")}
                      </label>
                        <Select
                          onChange={(e,label)=>onChangeShift(e,label)}
                          style={{width:"100%"}}
                          mode="multiple"
                          value={shiftCode}
                        >
                          <Select.Option value="all">Select All</Select.Option>
                          <Select.Option key={'p'} start={"00:00"} end={"10:30"} add={0}>Morning (P)</Select.Option>
                          <Select.Option key={'s'} start={"10:00"} end={"18:30"}>Afternoon / Evening (s)</Select.Option>
                          <Select.Option key={'m'} start={"18:00"} end={"00:30"}>Night (M)</Select.Option>
                          
                          
                        </Select>
                  </Col>
                  

<Col lg={3} style={{left:0}} className="report-filter report-filter-less">
<label>
                        {getTranslatedText("Report By")}
                      </label>
<div className="ant-checkbox-group">
                      <Checkbox  checked={filterBy=='day'} defaultChecked={true} onChange={()=>{SetFilterBy("day");props.filterResultHandler({},false);}} >Day</Checkbox>
                      <Checkbox checked={filterBy=='week'} defaultChecked={false} onChange={()=>{SetFilterBy("week");props.filterResultHandler({},false);}}>Week</Checkbox>
                      <Checkbox checked={filterBy=='month'} defaultChecked={false} onChange={()=>{SetFilterBy("month");props.filterResultHandler({},false);}}>Month</Checkbox>
                      </div>

 </Col>
                  </Row>
                  <Row>
                    <Col lg={5} style={{paddingRight:0,marginTop:10}}>
                      
  <div className="mgSpace mgSpacenewTooltip">
 <label>
    On Time
  </label>
  <Slider range 
  
    tipFormatter={(index) => convertMinutesToHoursAndMinutes(index) }
    min={-240}
    max={240}
    value={onTime}
    tooltipVisible
    tipProps={{ placement: 'top', visible: true ,className: 'custom-tooltip-class',}}
    onChange={(val)=>{return  <span className="custom-tooltip-class">{onTimeHandler(val)}</span>}}
    classNames={["myTooltip"]}  
  />
  
  </div>
 </Col> 

                    
                    <Col lg={5} style={{paddingRight:0,marginTop:10}}>
<div className="mgSpace mgSpacenewTooltip2">
 <label>
    Late
  </label>
  <Slider 
    range 
    value={lateTime}
    max={240}
    min={-240} 
    // min={onTime && onTime[0]?onTime[0]:-240}
    onChange={(val)=>{ lateTimeHandler(val)}} 
  
  tooltipVisible
        tipProps={{ placement: 'top', visible: true }}
         tipFormatter={(index) => convertMinutesToHoursAndMinutes(index)}
  />
  </div>

                    </Col>

<Col lg={2} className="">
  <label>
    Action
  </label>
  <div className="btndiv">
                <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler()} disabled={dates.start=='' }>Submit</button>
                <button className="btn btn-submit btn-filter btn-export" onClick={exportToExcel} disabled={!filterFOP} >Export</button>
                </div>
</Col>

<Col lg={12}>
</Col>

                </Row>
                </>
                )

}