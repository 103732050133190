import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";
import { Slider, Tooltip  } from 'antd';
import { Tabs, Table, Collapse } from 'antd';
import Highcharts from 'highcharts';
import ReactHighcharts from 'react-highcharts';
import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import { getFopReportFilter, getProcessCyclecount } from "../../actionMethods/actionMethods";
import Scrollbars from "react-custom-scrollbars";
import spinnerLoader from "../../../assets/images/loader.svg";
import ExcelJS from 'exceljs';
import TraceLogo from '../../../assets/traceplusImages/trace_logo.png'
import { ToastContainer, toast } from "react-toastify";
import { getPastReportData } from "../../actionMethods/actionMethods";



const { TabPane } = Tabs;
const { Panel } = Collapse;


export default function MovementPlanActual (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    const [processActive,SetProcessActive] = useState([]);
    const [errorResult,SetErrorResult]= useState("");
    
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };

    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;
    
      
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [plannedEmp,SetPlannedEmp]= useState([])
    const [filterFOP,SetFilterFOP] =  useState(false);
    const [reportData,SetReportData]= useState([]);
    const indonesiaTimeZone = 'Asia/Jakarta';  
    const [loader,SetLoader]= useState(true);
    const [defaultChart,SetDefaultChart] = useState('process');
    
    const [config,SetConfig]= useState({
        chart: {
            type: 'spline', // Change type to spline
            height:300
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: []
        },
        yAxis: {
          min:0,
            title: {
                text: ''
            },
                        labels: {
                enabled: true,
            },
            labels: {
                useHTML:true,
                formatter: function () {
                        return this.value+"%"                         
                }
            },
            plotLines: [
                {
                    color: 'red',
                    width: 3,
                    value: 100,
                    dashStyle: 'longdashdot',
                    zIndex:999,
                    label: {
                        text: "100",
                        align: 'left',
                        x: 0
                    }
                }],

        },

        tooltip: {
    useHTML: true,
    formatter: function () {
        var tooltip = '<div class="coveringspace"><table class="customTableSource">';
        tooltip += '<tr><td colspan="4" style="text-align: right;"><b>' + this.points[0].key + '</b></td></tr>';
        tooltip += '<tr><th>Function</th><th>Plan</th><th>Actual</th><th>Compliance</th></tr>';
        for (var i = 0; i < this.points.length; i++) {
            var point = this.points[i];
            var processName = '<span class="bgNameTooltip" style="background:' + point.color +'"></span>' + point.series.name; // Dynamic process name
            tooltip += '<tr>';
            tooltip += '<td style="text-align:left">' + processName + '</td>';
            tooltip += '<td>' + point.point.plan + '</td>';
            tooltip += '<td>' + point.point.actual + '</td>';
            tooltip += '<td>' + point.point.compliance.toFixed(2) +'%</td>';
            tooltip += '</tr>';
        }
        tooltip += '</table></div>';
        return tooltip;
    },
    shared: true,
    visible: true // Set tooltip always visible
},
        plotOptions: {
            spline: {
                marker: {
                    enabled: true
                }
            }
        },
        series: []
      });
    const [allProcessData,SetAllProcessData] = useState([]);
    const [functionList,SetFunctionList]= useState([ 
    {
      "category_id": 188, 
      "category_name": "Hub Incoming Lead"
    }, 
    {
      "category_id": 189, 
      "category_name": "Hub Outgoing Lead"
    }, 
    {
      "category_id": 190, 
      "category_name": "System DG Outbound"
    }, 
    {
      "category_id": 191, 
      "category_name": "Data Center Inbound"
    }, 
    {
      "category_id": 192, 
      "category_name": "Admin Hub"
    }, 
    {
      "category_id": 193, 
      "category_name": "Packing Inbound"
    }, 
    {
      "category_id": 194, 
      "category_name": "Data Center Outbound"
    }, 
    {
      "category_id": 195, 
      "category_name": "System Outbound"
    }, 
    {
      "category_id": 196, 
      "category_name": "Checker Inbound"
    }, 
    {
      "category_id": 197, 
      "category_name": "Checker Outbound"
    }, 
    {
      "category_id": 198, 
      "category_name": "Hub Incoming Data Lead"
    }, 
    {
      "category_id": 199, 
      "category_name": "Packing Outbound"
    }, 
    {
      "category_id": 200, 
      "category_name": "Tracer"
    }, 
    {
      "category_id": 201, 
      "category_name": "Data Entry Lead"
    }, 
    {
      "category_id": 202, 
      "category_name": "Hub Data Entry"
    }, 
    {
      "category_id": 203, 
      "category_name": "Packing Kayu"
    }, 
    {
      "category_id": 204, 
      "category_name": "HUB ASSISTANT MANAGER"
    }, 
    {
      "category_id": 205, 
      "category_name": "HUB OUTGOING SUPERVISOR"
    }, 
    {
      "category_id": 206, 
      "category_name": "HUB INCOMING SUPERVISOR"
    }, 
    {
      "category_id": 207, 
      "category_name": "HUB DATA ENTRY SUPERVISOR"
    }
  ], 
)
    const [processList,SetProcessList]= useState(props.filterPassData.process);
  
    useEffect(()=>{
      
      SetProcessList(props.filterPassData?.process)
    },[props.filterPassData.process])


    
  const actualAndPlannedRenderer = ({ value, valueFormatted, colDef }) => {
    const tooltipValue = valueFormatted && valueFormatted.tooltipValue ? valueFormatted.tooltipValue : value;
    var eGui = document.createElement('div');
  eGui.innerHTML = '<span class="icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.0.0-alpha3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M288 128C217.3 128 160 185.3 160 256s57.33 128 128 128c70.64 0 128-57.32 128-127.9C416 185.4 358.7 128 288 128zM288 352c-52.93 0-96-43.06-96-96s43.07-96 96-96c52.94 0 96 43.02 96 96.01C384 308.9 340.1 352 288 352zM572.5 238.1C518.3 115.5 410.9 32 288 32S57.69 115.6 3.469 238.1C1.563 243.4 0 251 0 256c0 4.977 1.562 12.6 3.469 17.03C57.72 396.5 165.1 480 288 480s230.3-83.58 284.5-206.1C574.4 268.6 576 260.1 576 256C576 251 574.4 243.4 572.5 238.1zM543.2 260.2C492.3 376 394.5 448 288 448c-106.5 0-204.3-71.98-255-187.3C32.58 259.6 32.05 256.9 31.1 256.2c.0547-1.146 .5859-3.783 .7695-4.363C83.68 135.1 181.5 64 288 64c106.5 0 204.3 71.98 255 187.3c.3945 1.08 .9238 3.713 .9785 4.443C543.9 256.9 543.4 259.6 543.2 260.2z"/></svg></span>';
   return eGui;

   
  
  return (

    <div title={`Planned: ${tooltipValue.plan}, Actual: ${tooltipValue.actual}`} className="actualRender">
      {value}
    </div>
  );
};
    function titleAGTooltip(params,hour){        
    
    var op =document.createElement('div');

    var eGui = document.createElement('div');
    if(isFinite(params.data['hour_'+hour+'_compliance'])){
      
      eGui.innerHTML=`<div class="element-icon ant-table-cell">
                      <div class="tooltipAreaTitle">${params.data['hour_'+hour+'_compliance']+"%"}</div>
                    </div>`;
    }else{
      eGui.innerHTML=`<div class="element-icon ant-table-cell">
                      <div class="tooltipAreaTitle">0%</div>
                    </div>`;
    }
          
     return eGui;



}


const AGGridTable = (activeProcess,data2 ) => {
    
    const data = formatDataForActiveProcess(activeProcess,data2);

    let arr =[];
    let newAr=[];
    let header =['5 AM','6 AM','7 AM','8 AM','9 AM','10 AM','11 AM','12 PM','1 PM','2 PM','3 PM','4 PM','5 PM','6 PM','7 PM','8 PM','9 PM','10 PM','11 PM','12 AM','1 AM','2 AM','3 AM','4 AM'];
    let hourIndex = [5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0,1,2,3,4]

    const columnDefs = [
    { headerName: 'Function', field: 'name', width: 200, pinned: 'left', resizable: true },
     ...hourIndex.map((i)=> {

     
     let index = hourIndex.indexOf(i);
    
     return ({
      headerName: header[index],
      field: 'hour_' +i+"_compliance",
      width: 80,
      resizable: true,
      key:'hour_'+i+'_plan',
      tooltipComponent: 'customTooltip', // Set custom tooltip component
      // tooltipField:'hour_' +i+"_compliance",
      tooltipValueGetter: (params) =>`
        Planned: ${params.data['hour_'+i+'_plan'] || 0} 
        Actual: ${params.data['hour_'+i+'_actual']|| 0}`,

      cellRenderer:(params)=>titleAGTooltip(params,i),
      // cellRendererFramework: MyCellRenderer,
    }) 
    }
   ),
  ];
   
    if(data.function.length > 0){
    for(let i=0;i<data.function.length;i++){
      let name = data.function[i];
      let single={};
      single.name=data.function[i];
      for(let j=0;j<24;j++){
        let hourData= data.data.find(el=>el['Time Hour']  == j);
        
        single[`hour_${j}_actual`] = hourData[`${slugify(data.function[i])}_actual`]
        single[`hour_${j}_plan`] = hourData[`${slugify(data.function[i])}_plan`]
        single[`hour_${j}_compliance`] = !hourData[`${slugify(data.function[i])}_actual`]  && !hourData[`${slugify(data.function[i])}_plan`]?100:hourData[`${slugify(data.function[i])}_compliance`]
      }
      newAr.push(single);
    //  arr.push(<AgGridColumn field={slugify(data.function[i])} headerName={ data.function[i]}  />) 
    }
  }else{
    
    let name = activeProcess;
      let single={};
      single.name= name.replaceAll("_"," ").toLowerCase();
      for(let j=0;j<24;j++){        
        single[`hour_${j}_actual`] =0
        single[`hour_${j}_plan`] = 0
        single[`hour_${j}_compliance`] = 100
      }
      newAr.push(single);
      
  }
    
  const gridOptions = {
    rowHeight: 35, // Set row height to 35px
  };

  if(newAr.length ===0){

  }


  return (
        <div className="ag-theme-alpine" style={{ height: (newAr.length * 40) + 100, width: '100%' }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={newAr}
        gridOptions={gridOptions}
        enableSorting={true}
        enableFilter={true}
        tooltipShowDelay={500}
        
      />
        </div>)
};

 function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}
function capitalizeFirstLetterNew(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


  function slugify(string) {
    return string.toString().toLowerCase().replace(/[^a-zA-Z0-9]/g, "_");
  }

  function GetRandomDigit(min=0,max=10){
    return  Math.floor(Math.random() * (max - min + 1)) + min;
  }

 function generateDataDynamic(apiData, processList) {
  const formattedData = [];

  // Iterate over each process in the API response
  for (const processName in apiData.data) {
  
    if (apiData.data.hasOwnProperty(processName)) {
   
      for (const hour in apiData.data[processName]) {
          
          if(apiData.data[processName].hasOwnProperty(hour) && (hour ==='active'|| hour ==='plan' || hour ==='compliance')){
          
          }
         else if (apiData.data[processName].hasOwnProperty(hour)) {

          // Create an object to store data for the current process and hour
          const processData = {
            key: slugify(processName),
            name:processName,
            hour: hour,
            functions: [],

            
          };

          processData[`${slugify(processName)}_actual`] =apiData.data[processName][hour]['active'];
          processData[`${slugify(processName)}_plan`] =apiData.data[processName][hour]['plan'];
          processData[`${slugify(processName)}_compliance`] =!apiData.data[processName][hour]['active'] && !apiData.data[processName][hour]['plan']?100:apiData.data[processName][hour]['compliance'];

          // Iterate over each function for the current hour
          for (const functionName in apiData.data[processName][hour]) {
            if((functionName ==='active'|| functionName ==='plan' || functionName ==='compliance')){

            }
            else if (apiData.data[processName][hour].hasOwnProperty(functionName) ) {
              const funcData = apiData.data[processName][hour][functionName];
              const formattedFunctionName = functionName.toLowerCase().replace(/\s+/g, '_');
              
              // Add function data to the current process
              processData.functions.push({
                name: functionName,
                plan: funcData.plan,
                actual: funcData.active,
                compliance:parseFloat(funcData.compliance) 

              });
            }else{
              processData.functions.push({
                name: functionName,
                plan: 0,
                actual: 0,
                compliance:100 
              });
            }
          }

          // Add the current process data to the formatted data array
          formattedData.push(processData);
        }
      }
    }
  }


let arr =[];

for(let i =0;i<24;i++){
  let single = {};
  single.hour = i;
  let currentHour = formattedData.filter(el=>el.hour== i);
  if(currentHour){
  for(let j=0;j<currentHour.length;j++){
    
      single[`${currentHour[j].key}_plan`] = currentHour[j][`${currentHour[j].key}_plan`] ;
      single[`${currentHour[j].key}_actual`] = currentHour[j][`${currentHour[j].key}_actual`] ;
      single[`${currentHour[j].key}_compliance`] = !currentHour[j][`${currentHour[j].key}_plan`]&& !currentHour[j][`${currentHour[j].key}_actual`]?100:currentHour[j][`${currentHour[j].key}_compliance`] ;
    
      single[`${currentHour[j].key}_name`] = currentHour[j].name
      single[`${currentHour[j].key}_function`] = currentHour[j].functions
      
  }

  }else{
    
      for(let j=0;j<processList.length;j++){
        single[`${slugify(processList[j])}_plan`] = 0
        single[`${slugify(processList[j])}_compliance`] = 100
        single[`${slugify(processList[j])}_actual`] = 0
        single[`${slugify(processList[j])}_name`] = processList[j]
        single[`${slugify(processList[j])}_function`] = []
      }

  }
  arr.push(single);


}

  return arr;
}



function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }
useEffect(()=>{

  async function filterResultHandler(){

    try{
      
        if(props.filterActive){
        
          const apiData =  await getProcessCyclecount(userSession,org_id,sub_org_id,props.filterPassData.start_date,props.filterPassData.end_date);
          let func =[];
          Object.keys(apiData.data).forEach(key=>{
            func.push(key);
          })
          let size = Object.keys(apiData.data).length
          
          if( size ==0){
            SetErrorResult("No Data available");
            SetLoader(false);
          }else{
             SetErrorResult("");
          let arr = generateDataDynamic(apiData, func);
          let pro = formatDataForActiveProcess(slugify(props.filterPassData.process[0]),arr)
           
          SetAllProcessData(arr);
          SetProcessActive(props.filterPassData.process[0])
  
          let newAr=[];
          let header =['5 AM','6 AM','7 AM','8 AM','9 AM','10 AM','11 AM','12 PM','1 PM','2 PM','3 PM','4 PM','5 PM','6 PM','7 PM','8 PM','9 PM','10 PM','11 PM','12 AM','1 AM','2 AM','3 AM','4 AM'];
          let hourIndex = [5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0,1,2,3,4]
          let series =[];
          
              //function
             let single={};
            let process = props.filterPassData.process[0]
             single.name=process;
            single.data=[];

            if(defaultChart=="process"){
              for(let i=0; i< hourIndex.length;i++){
              let ct= arr.find(el=>el.hour === hourIndex[i]);
              
                single.data.push({
                  y:ct[`${slugify(process)}_compliance`] && isFinite(ct[`${slugify(process)}_compliance`])?ct[`${slugify(process)}_compliance`]:100,
                  compliance:ct[`${slugify(process)}_compliance`] && isFinite(ct[`${slugify(process)}_compliance`])?ct[`${slugify(process)}_compliance`]:100,
                  actual:ct[`${slugify(process)}_actual`] && isFinite(ct[`${slugify(process)}_actual`])?ct[`${slugify(process)}_actual`]:0,
                  plan:ct[`${slugify(process)}_plan`] && isFinite(ct[`${slugify(process)}_plan`])? ct[`${slugify(process)}_plan`]:0                 
                  
            })
            }
            
                series.push(single);
               
                    let newConfig={...config};
                    newConfig.xAxis.categories=header;
                    newConfig.series = series; 

                    SetConfig(newConfig);
            }else{
              
              
              ChangeChart(props.filterPassData.process[0],arr,defaultChart)
            }
          
          SetLoader(false);

          }
          
        }
    }catch(error){
      
    }
}


filterResultHandler()

},[props.filterPassData, props.filterFOP,]);

function formatDataForActiveProcess(activeProcess,data){
    let funcList=[];
    const formattedData = data.map(hourData => {
        const rowData = {
            'Time Hour': hourData.hour
        };

        // Add function data to rowData
        if(hourData[`${activeProcess}_function`]){

        
        hourData[`${activeProcess}_function`].forEach((func) =>{
          if(funcList.indexOf(func.name)=== -1){
            funcList.push(func.name);
          }

          rowData[`${slugify(func.name)}_actual`] = func.actual
          rowData[`${slugify(func.name)}_plan`] = func.plan
          rowData[`${slugify(func.name)}_compliance`] =!func.actual && !func.plan?100:func.compliance
        })
        
      }else{
        hourData[`${activeProcess}_function`] = []
      }

        return rowData;
    });
    

    return ({data:formattedData,function:funcList});

};



useEffect(()=>{
  if(props.exportEnable){
    
    generateExcelFile(allProcessData,processList);
  }
},[props.exportEnable,processList,allProcessData])

  const generateExcelFile = async (allProcessData,processList) => {
    
    const workbook = new ExcelJS.Workbook();
    const imageId = await getImageId(workbook, TraceLogo);
    for(let i=0;i<processList.length; i++){
      
      await generateSheet(workbook, processList[i],formatDataForActiveProcess(slugify(processList[i]),allProcessData) ,imageId);
    }
    
  
  const fileName = 'MovementPlanActualReport '+moment(props.filterPassData.start_date).format('YYYY-MM-DD')+" - "+moment(props.filterPassData.end_date).format('YYYY-MM-DD');
    const writeFile = (fileName, content) => {
              const link = document.createElement("a");
              const blob = new Blob([content], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;"
              });
              link.download = fileName;
              link.href = URL.createObjectURL(blob);
              link.click();
            };
          //const stream = fs.createWriteStream(filename);
          
          workbook.xlsx.writeBuffer().then((buffer) => {
              writeFile(fileName, buffer);
          });
      props.exportFalseFOP();
  };


    const generateSheet = async (workbook, sheetName,data, logoID) => {
    // Add a new worksheet
    try{
    
    const worksheet = workbook.addWorksheet(sheetName);

     worksheet.addImage(logoID, {
      tl: { col: 0, row: 1 },
      ext: { width: 153, height: 34 },
    });

    worksheet.addRow([])    
    worksheet.addRow([])    
    worksheet.addRow([])
    worksheet.addRow([])    
    worksheet.mergeCells('A1:B4');    
    worksheet.addRow([])

    let header =['5 AM','6 AM','7 AM','8 AM','9 AM','10 AM','11 AM','12 PM','1 PM','2 PM','3 PM','4 PM','5 PM','6 PM','7 PM','8 PM','9 PM','10 PM','11 PM','12 AM','1 AM','2 AM','3 AM','4 AM'];
    let hourIndex = [5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0,1,2,3,4]
    let column= [{ name: 'Function' }] 
    header.map(el=>{
      
      column.push({name:el+" Plan"},{name:el+" Actual"},{name:el+" Compliance"})
    
    })
    
    let newAr =[]
    let graph=[];
    let quickchart = {
  "type": "line",
  "data": {
    "labels": header,
    "datasets": []
  },
}
let series=[];
    for(let i=0;i<data.function.length;i++){
        let single=[];
        let gr={}
        let name = data.function[i];
        gr.label= name;
        gr.data=[];
        gr.fill = false;
        single.push(name);
      // single.name=data.function[i];
      for(let j=0;j<hourIndex.length;j++){
        let hourData= data.data.find(el=>el['Time Hour']  == hourIndex[j]);
        gr.data.push(!hourData[`${slugify(data.function[i])}_plan`] &&!hourData[`${slugify(data.function[i])}_actual`]?100:hourData[`${slugify(data.function[i])}_compliance`] || 0);
        single.push(hourData[`${slugify(data.function[i])}_plan`] || 0);
        single.push(hourData[`${slugify(data.function[i])}_actual`] || 0);
        single.push(!hourData[`${slugify(data.function[i])}_plan`] &&!hourData[`${slugify(data.function[i])}_actual`]?100:hourData[`${slugify(data.function[i])}_compliance`] || 0);
      }
      series.push(gr);
      newAr.push(single);
    //  arr.push(<AgGridColumn field={slugify(data.function[i])} headerName={ data.function[i]}  />) 
    }
    quickchart.data.datasets = series;

    let imageUrl= `https://quickchart.io/chart?v=2.9.4&c=${encodeURIComponent(JSON.stringify(quickchart))}`;


    let tableStartRow =  13;

   worksheet.addRow(['Start Date', moment(props.filterPassData.start_date).format('YYYY-MM-DD')]).getCell(2).font = { bold: true };
   worksheet.addRow(['End Date', moment(props.filterPassData.end_date).format('YYYY-MM-DD')]).getCell(2).font = { bold: true };
   worksheet.addRow(['Movement Report', "Plan vs Actual"]).getCell(2).font = { bold: true };
   worksheet.addRow(['Site', "Lion Parcel MH"]).getCell(2).font = { bold: true };
   worksheet.addRow([]);
   worksheet.addRow(['Process',sheetName]).getCell(2).font = { bold: true };
   worksheet.addRow([]);

   worksheet.addRow([])
   worksheet.addRow(["Time","Type",...data.function.map(el=>el)]);
   const headerRow = worksheet.getRow(14);

// Apply styling to the header row
headerRow.eachCell((cell) => {
    cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4f81bc' } // Yellow background color
    };
    cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' } // Black font color
    };
});

    let excelTable= [];

    for(let j=0;j<hourIndex.length;j++){
        let act=[];
        let plan=[];
        let comp=[];
        for(let i=0;i<data.function.length;i++){
          let hourData= data.data.find(el=>el['Time Hour']  == hourIndex[j]);
          
          plan.push(hourData[`${slugify(data.function[i])}_plan`] || 0)
          act.push(hourData[`${slugify(data.function[i])}_actual`]  || 0)
          comp.push(!hourData[`${slugify(data.function[i])}_plan`]  &&!hourData[`${slugify(data.function[i])}_actual`]?100:hourData[`${slugify(data.function[i])}_compliance`]  || 0)
          // worksheet.addRow([hourIndex[j],"Actual",...processList.map(el=> {return hourData[`${slugify(el)}_actual`]})]);
          // worksheet.addRow([hourIndex[j],"Plan",...processList.map(el=> {return hourData[`${slugify(el)}_plan`]})]);
          // worksheet.addRow([hourIndex[j],"Compliance",...processList.map(el=> { return hourData[`${slugify(el)}_compliance`]})]);
          tableStartRow = tableStartRow+ 3;
        }
        worksheet.addRow([header[j],"Actual",...act]);
        worksheet.addRow([header[j],"Plan",...plan]);
        worksheet.addRow([header[j],"Compliance",...comp]);

    }
   
   
    // worksheet.addTable({
    //   name: sheetName.replaceAll(' ', '_'),
    //   ref: `A13`,
    //   columns: column,
    //   rows: newAr,
    // });
    // const table = worksheet.getTable(sheetName.replaceAll(' ', '_'));

    // worksheet.columns.forEach(column => {
    //   const lengths = column.values.map(v => v?v.toString().length:1);
    //   const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
    //   column.width = maxLength + 1;
    // });
    // table.commit();

    const imageId = await getImageId(workbook, imageUrl);
        // let dimension = await getImageSize(imageUrl);
    


    worksheet.addImage(imageId, {
      tl: { col:data.function.length + 3, row: 5 },
      ext: { width: 800, height: 500 },
    });



    }catch(error){
      

    }
    


  };
  const getImageId = async (workbook, imageUrl) => {
    const response = await fetch(imageUrl);
    const arrayBuffer = await response.arrayBuffer();
    const base64Image = Buffer.from(arrayBuffer).toString('base64');

    return workbook.addImage({
      base64: base64Image,
      extension: 'png', // Change the extension based on your image format
    });
  };

  

  

  

  function AGGridEMptyMEssage(key){
    let msg;
        msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
    return msg
  }


const compressedTableStyles = {
  margin: 0,
  padding: 0,
};
const compressedRowStyles = {
  lineHeight: '1.2rem', // Adjust the line height as needed
};


 const getImageSize = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
      toast.error("Fail to export please again after sometime");
    }
  const blob = await response.blob();
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.src = URL.createObjectURL(blob);
  });
};
 
function ChangeChart(process,allProcessData,chartby){
    let pro = formatDataForActiveProcess(slugify(process),allProcessData)
    
         let newAr=[];
          let header =['5 AM','6 AM','7 AM','8 AM','9 AM','10 AM','11 AM','12 PM','1 PM','2 PM','3 PM','4 PM','5 PM','6 PM','7 PM','8 PM','9 PM','10 PM','11 PM','12 AM','1 AM','2 AM','3 AM','4 AM'];
          let hourIndex = [5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0,1,2,3,4]
          let series =[];
  if(chartby == 'function'){
 //function

          for(let j=0;j<pro.function.length;j++){
            
            let single={};
            single.name=pro.function[j];
            single.data=[];
            for(let i=0; i< hourIndex.length;i++){
              let ct= pro.data.find(el=>el['Time Hour'] === hourIndex[i]);
              
                single.data.push({
                  y:!ct[`${slugify(pro.function[j])}_actual`] && !ct[`${slugify(pro.function[j])}_plan`]?100:ct[`${slugify(pro.function[j])}_compliance`] && isFinite(ct[`${slugify(pro.function[j])}_compliance`])?ct[`${slugify(pro.function[j])}_compliance`]:0,
                compliance:!ct[`${slugify(pro.function[j])}_actual`] && !ct[`${slugify(pro.function[j])}_plan`]?100:ct[`${slugify(pro.function[j])}_compliance`] && isFinite(ct[`${slugify(pro.function[j])}_compliance`])?ct[`${slugify(pro.function[j])}_compliance`]:0,
                actual:ct[`${slugify(pro.function[j])}_actual`] && isFinite(ct[`${slugify(pro.function[j])}_actual`])?ct[`${slugify(pro.function[j])}_actual`]:0,
                plan:ct[`${slugify(pro.function[j])}_plan`] && isFinite(ct[`${slugify(pro.function[j])}_plan`])? ct[`${slugify(pro.function[j])}_plan`]:0
            })
            }
            series.push(single);

          }
  }else{

            let single={};
            single.name=process;
            single.data=[];
            
            for(let i=0; i< hourIndex.length;i++){
              let ct= allProcessData.find(el=>el.hour === hourIndex[i]);
              
              
                single.data.push({
                  y:ct[`${slugify(process)}_compliance`] && isFinite(ct[`${slugify(process)}_compliance`])?ct[`${slugify(process)}_compliance`]:0,
                  compliance:!ct[`${slugify(process)}_actual`] && !ct[`${slugify(process)}_plan`]?100:ct[`${slugify(process)}_compliance`] && isFinite(ct[`${slugify(process)}_compliance`])?ct[`${slugify(process)}_compliance`]:0,
                  actual:ct[`${slugify(process)}_actual`] && isFinite(ct[`${slugify(process)}_actual`])?ct[`${slugify(process)}_actual`]:0,
                  plan:ct[`${slugify(process)}_plan`] && isFinite(ct[`${slugify(process)}_plan`])? ct[`${slugify(process)}_plan`]:0
            })
            }
            series.push(single);


  }

        
          
             
          let newConfig={...config};
          newConfig.xAxis.categories=header;
          newConfig.series = series; 

          SetConfig(newConfig);

}



    
     return (props.filterActive?
      loader?                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:errorResult?<div className="errorDiv" style={{background:"#e7e7e7",padding:"10px 20px",borderLeft:"4px solid red"}}>{errorResult}</div>:
    <div className="App">
    <ToastContainer/>

      {processList && processList.length > 1?<div className="buttonFilterArea buttonFilterArea2 buttonFilterArea3">
          { processList.map(el=>  {
         return <button className={processActive===el?"buttonChartFilter buttonChartFilterActive button btn":"buttonChartFilter button btn"} onClick={()=>{SetProcessActive(el);ChangeChart(el,allProcessData,defaultChart)}} style={{textTransform:"capitalize"}} key={"process"+el}>{el}</button>  
      }) }
      </div>:""}

    <h4 style={{margin:"20px 0px",fontSize:18}} className="fncBtn">Movement Report:  <span style={{color:"#ef5e8c",textTransform:"capitalize"}}>{processActive}</span></h4>
      {/* <ReactHighcharts config={config}/> */}
<div className={"keyAGTableSmallPlan"} style={{minHeight:300}}>
  
    {/* <GridExample/> */}

      {AGGridTable(slugify(processActive),allProcessData)}


<hr/>
<h4 style={{margin:"20px 0px",fontSize:18}} className="fncBtn"><span style={{color:"#ef5e8c"}}>Overview</span>:   <button className={defaultChart=="process"?"btn btn-submit btn-active":"btn btn-submit"} style={{marginLeft:5,textTransform:"capitalize"}} onClick={()=>{SetDefaultChart("process"); ChangeChart(processActive,allProcessData,'process')}}>{processActive}</button> <button className={defaultChart=="function"?"btn btn-submit btn-active":"btn btn-submit"} onClick={()=>{SetDefaultChart('function');ChangeChart(processActive,allProcessData,'function')}}>Function</button></h4>
<div className="chartArea" style={{minHeight:400}}>
  
  <ReactHighcharts config={config}/>
</div>
</div>
    </div>:""
    )

}