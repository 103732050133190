import logo from './logo.svg';
import { BrowserRouter } from 'react-router-dom';
import {withRouter} from 'react-router-dom'
import './App.css';
import './assets/styles/aside.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/common.scss'
import './assets/styles/responsive.css'
import LeftSideBar from './components/leftsidebar';
import RightSideComponents from './components/rightsideComponents';
import { useEffect,useState } from 'react';
import Modal from 'react-modal'
import ReactModal from 'react-modal'
import {getRoleDetail,getOrgWebPages,getHolidayList} from './common/actionMethods'
import {logoutUser} from './login/actionMethods/actionMethods'
import { useHistory } from 'react-router-dom'; 
// import mqttService from './components/mqttService';
// import DemoHoliday from './assets/holiday.json'
import spinnerLoader from  "./assets/images/loader.svg";
import { getworkingday } from './productivity/actionMethods/actionMethods';
import GoogleTranslateWidget from './common/GoogleTranslateWidget';
import './assets/styles/language.css'
const customStyle = {
  overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },
  content: {
      position: 'absolute',
      width: '25rem',
      height: '14rem',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '0'
  }
}
const customStyle2 = {
  overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },
  content: {
      position: 'absolute',
      width: '30rem',
      height: '300px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '0'
  }
}


function App(props) {

  let isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'))?JSON.parse(localStorage.getItem('isLoggedIn')):false;
  let userDetails = localStorage.getItem('userLoginDetails')?JSON.parse(localStorage.getItem('userLoginDetails')):[]
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
  let workdayDetail = localStorage.getItem('workingday')?JSON.parse(localStorage.getItem('workingday')):[]
  let userDetails2 =  JSON.parse(localStorage.getItem("userLoginDetails"));

  const [loggedInApp,SetLoggedInApp] = useState(isLoggedIn);
  const [role,SetRole]=useState([])
  const [expireModal,SetExpireModal] = useState(false);
  const [timer,SetTimer]=useState(10);
  const [modalShowWarn,SetModalShowWarn] =useState(false);
  const [weekDays,SetWeekDays] =useState([]);
  const [holidayList,SetHolidayList] =useState([]);
  const [apiCall,SetAPICall]= useState(false);
  const [workday,setWorkday] = useState({})
  const [pages,SetPages] = useState([])
  const [loaderTill,SetLoaderTill]= useState(new Date('2024-02-27')) 
  const [modalMaintainence,SetModalMaintainence]= useState(false);

  useEffect(()=>{
    if(loggedInApp){
      if(new Date() < new Date('2024-02-20')){
        SetModalMaintainence(true);
      }
    }

  },[loggedInApp])
  let history = useHistory ();
  ReactModal.setAppElement('*'); 


  function loggedInParent(val){
    SetAPICall(false);
    SetLoggedInApp(true);
  }




  useEffect(() => {
    if(expireModal){

    
    if(timer == 0 || timer < 0){
      handleLogout()
    }
    if(timer<=0){
      SetExpireModal(false);
      
      handleLogout()
      return
    }

    const interval = setInterval(() => {
        SetTimer((prev)=> prev - 1);
      }, 1000);
    
      return () => clearInterval(interval);
    }
    
    }, [timer,expireModal]);

    

  useEffect(() => {

    
    if(loggedInApp){
async function fetchData() {
  try {
  

    const orgWebPages = await getOrgWebPages(userDetails.session, userDetails.org_id, userDetails.sub_org_id);
   

    if(orgWebPages.status===300){
      localStorage.clear();
      props.history.push('/login');
    }

    let result = Object.values(orgWebPages);
    let arr = [];
    const formattedWeekdays = [];
    let formattedHolidays=[];
    // if(orgWebPages.status==200){
      for (let i = 0; i < result.length; i++) {

      if (result && result[i].length > 0) {
        
        if(userDetails && userDetails.employee_id === 1308){
          arr.push(...result[i].filter(el=>el.page_name !="Shift Management"))
      }else{
          arr.push(...result[i]);
        }    
        


      }
    }
  
    

    SetPages(arr);

    const holidayList = await getHolidayList(userDetails.session, userDetails.org_id, userDetails.sub_org_id);

    if (holidayList.status === 200) {
      if (holidayList.data.length > 0) {
        const weekdaysData = JSON.parse(holidayList.data[0].weekdays).weekdays;

      // Mapping for day names
const dayNameMapping = {
  "sunday": "Sunday",
  "monday": "Monday",
  "tuesday": "Tuesday",
  "wednesday": "Wednesday",
  "thursday": "Thursday",
  "friday": "Friday",
  "saturday": "Saturday"
};

// Parse and format weekdays information

const dayIndexMapping = {
  "monday": 1,
  "tuesday": 2,
  "wednesday": 3,
  "thursday": 4,
  "friday": 5,
  "saturday": 6,
  "sunday": 7
};

weekdaysData.forEach(dayInfo => {
  const [day, workingStatus] = Object.entries(dayInfo)[0];
  if (day) {
    formattedWeekdays.push({
      day: dayIndexMapping[day],
      name: dayNameMapping[day],
      working: workingStatus === "yes"
    });
  }
});

SetWeekDays(formattedWeekdays);
const holidaysData = JSON.parse(holidayList.data[0].holidays).holidays;

// Parse and format holidays information
 formattedHolidays = holidaysData.map(holidayInfo => {
  const [date, name] = Object.entries(holidayInfo)[0];
  return {
    name,
    date
  };
});
SetHolidayList(formattedHolidays);

        //SetAPICall(true);
      } 
    }

    const workingDay = await getworkingday(userDetails.org_id, userDetails.sub_org_id, userDetails.session);
//     const workingDay = {
//   "data": {
//     "end_hour": "06:59:00", 
//     "id": 5, 
//     "org_id": 32, 
//     "same_day": "yes", 
//     "start_hour": "07:00:00", 
//     "sub_org_id": 0
//   }, 
//   "message": "Success", 
//   "status": 200
// };
    

    if (workingDay.status === 200) {
      setWorkday(workingDay.data);
      localStorage.setItem('workingday', JSON.stringify(workingDay.data));
    }
    
    SetAPICall(true);
  } catch (error) {
    
    // Handle errors as needed
  }
}


fetchData();

  }
  if(!loggedInApp){
    SetAPICall(true);
  }

}, [loggedInApp]);


function handleLogout() {
  SetExpireModal(false);

  let requestBody = {}
  requestBody.token = localStorage.getItem('tokenAuthTrace')?localStorage.getItem('tokenAuthTrace'):'tituw3958589'
  requestBody.session = userDetails.session?userDetails.session:'123456789'
  localStorage.removeItem('isLoggedIn')
  localStorage.removeItem('userLoginDetails')
  localStorage.removeItem('selectedDate');
  localStorage.removeItem('tokenAuthTrace');
    logoutUser(requestBody).then(res => {
      if (res && res.status == 200 ) {
              localStorage.removeItem('isLoggedIn')
              localStorage.removeItem('userLoginDetails')
              localStorage.removeItem('selectedDate')
              localStorage.removeItem('tokenAuthTrace')            
              SetLoggedInApp(false);  
              history.push(`/login`)
        
      }
  })
}

  return (
    
      <div className="mainAppDiv">
    
      {
        isLoggedIn && userDetails2? 
        <LeftSideBar role={role}  workday={workday} pages={pages} loggedInParent={loggedInParent}/> : ''
      }
      {
        isLoggedIn && userDetails2?<div className='googleWidget'><GoogleTranslateWidget/></div>:""
       }

       {apiCall? <RightSideComponents loggedInParent={loggedInParent} pages={pages} role={role} weekDays={weekDays} holidayList={holidayList}  workday={workday}/>:<div className='holidayLoading'><img src={spinnerLoader} /></div>}
         {/* {apiCall? <RightSideComponents loggedInParent={loggedInParent} pages={pages} role={role} weekDays={weekDays} holidayList={holidayList}  workday={workday}/>:<h1>Hello Before Spinner</h1>} */}
        <Modal
                isOpen={modalMaintainence}
                style={customStyle2}
            >
                <div className='logoutModal'>
                    <div className="logoutTitle">Note</div>
                    <div className='logoutDesc' style={{fontSize:"16px",padding:"20px 30px"}}>
                   <ul style={{listStyle:"none",marginBottom:"10px",marginLeft:"0px",padding:"0px"}}>
                    <li style={{marginBottom:"5px"}}>Maintenance and Data Migration is in progres...</li>
<li style={{marginBottom:"10px"}}>Normally will take less than <strong>48Hrs</strong> </li>
<li style={{marginBottom:"0px"}}>We apologize for any inconvenience and appreciate your patience. Thank you for understanding.</li></ul>
                    </div>

                    <div className='actionButtons'>
                        
                        <span className='btnText okBtn' style={{width:"100%"}} onClick={()=>SetModalMaintainence(false)}>Ok</span>
                    </div>
                </div>
            </Modal>


            <Modal
                            isOpen={expireModal}
                style={customStyle}
            > <div className='logoutModal'>
                    <div className="logoutTitle">Logout</div>
                    <div className='logoutDesc' style={{fontSize:"14px"}}>Session expired<br/>
                    Redirecting to login in <span style={{color:"#f16b95"}}>{timer}</span> second.
                    </div>

                    <div className='actionButtons'>
                        {/* <span className='btnText borderRight' onClick={handleLogout}>No</span> */}
                        <span className='btnText okBtn' style={{width:"100%"}} onClick={handleLogout}>Ok</span>
                    </div>
                </div>
              
            </Modal>
            
      </div>
    
  );
}



export default withRouter(App);
