import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";
import {  Checkbox, Select, Slider} from 'antd';
import { Tabs } from 'antd';



export default function ReportByUseCaseFilter (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    
    
    // const [filterSlide,SetFilterSlide]=useState(100);
    const [filterFOP,SetFilterFOP] =  useState(false);
    const [threeshold,SetThreeshold] = useState(50);
    const [reportBy,SetReportBy] = useState('week');
    


    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;


    
    let workday =props.workday;
    // const [volume,SetVolume]= useState('');    



    function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }

    function filterResultHandler(){
        SetFilterFOP(true);
      
        let requestBody={
          date:moment(dates.start).format('YYYY-MM-DD') ,
          start_date:moment(dates.start).format('YYYY-MM-DD') ,
          end_date:moment(dates.end).format('YYYY-MM-DD') ,
          threeshold:threeshold,
          reportBy:reportBy
        }


        props.filterResultHandler(requestBody,true);
    }

    function exportToExcel(){
        props.exportEnable(true);
    }
    
    useEffect(()=>{
      return()=>{
        props.filterResultHandler({},false);
      }
    },[])
    useEffect(()=>{

      setDate((prev)=>({end:props.date,start:props.date}) )
    },[props.date])

    function onTimeHandler(val){
 
  
    SetThreeshold(val);
  
}

    
    return (
    
<Row className="">

                  <Col lg={1} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        value={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false);props.filterResultHandler({},false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Date")}
                        maxDate={dates.end}
                        // minDate={new Date('2023-01-01')}
                        minDate={new Date('2023-01-01')}
                        
                      />
                   
                    </div>
                  </Col>

                  <Col lg={1} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("End Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.end}
                        value={dates.end}
                        onChange={(date) => {handleDate(date, "end");SetExportEnable(false);props.filterResultHandler({},false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Date")}
                        maxDate={dates.end}
                        // minDate={new Date('2023-01-01')}
                        minDate={new Date('2023-01-01')}
                        
                      />
                   
                    </div>
                  </Col>

                        

            <Col lg={5} style={{paddingRight:0,marginTop:10}}>
                      
  <div className="mgSpace mgSpacenewTooltip">
 <label>
    Low Performance Limit
  </label>
  <Slider  

    max={100}
    value={threeshold}    
    onChange={(val)=>onTimeHandler(val)}
    classNames={["myTooltip"]}  
  />
  
  </div>
 </Col> 


<Col lg={3} style={{left:0}} className="report-filter report-filter-less">
<label>
                        {getTranslatedText("Report By")}
                      </label>
<div className="ant-checkbox-group">
                      <Checkbox  checked={reportBy=='day'} defaultChecked={true} onChange={()=>{SetReportBy("day");props.filterResultHandler({},false);}} >Day</Checkbox>
                      <Checkbox checked={reportBy=='week'} defaultChecked={false} onChange={()=>{SetReportBy("week");props.filterResultHandler({},false);}}>Week</Checkbox>
                      <Checkbox checked={reportBy=='month'} defaultChecked={false} onChange={()=>{SetReportBy("month");props.filterResultHandler({},false);}}>Month</Checkbox>
                      </div>

 </Col>





<Col lg={2} className="">
  <label>
    Action
  </label>
  <div className="btndiv">
                <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler()} disabled={dates.start=='' }>Submit</button>
                <button className="btn btn-submit btn-filter btn-export" onClick={exportToExcel} disabled={true} >Export</button>
                </div>
</Col>


                </Row>
                )

}