import React,{useEffect,useState} from 'react'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import calenderIcon from '../assets/traceplusImages/calendar_icon.svg'
// import helpIcon from '../assets/images/info.png
import {ReactComponent as HelpIcon} from '../assets/images/info-circle.svg'
import axios from 'axios';
import DashboardLanguage from '../components/dashboardLanguage';
import { Select } from 'antd';
const { Option } = Select;

const API_URL = 'https://translate-pa.googleapis.com/v1/translateHtml';
const API_KEY = 'AIzaSyATBXajvzQLTDHEQbcpq0Ihe0vWDHmO520';


function CommonDatePicker(props) {

   let date = props.selectedDate? props.selectedDate:localStorage.getItem('selectedDate') ? new Date(localStorage.getItem('selectedDate')) : new Date((new Date().setDate(new Date().getDate() - 1)));
  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let language = localStorage.getItem('selectedLanguage') || 'en';
  const [selectedLanguage,setSelectedLanguage] = useState(language);
  let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  

    if(parseInt(userDetails?.org_id)== 31 && date < new Date('2023-08-01') ){
        date =  new Date();    
    }
    function handleDateSelect(date) {
        if(!props.dontSave){
            localStorage.setItem('selectedDate', date)    
        }
        props.handleSelectDate(date)
    }
   
    const isWeekday = (date) => {
        let offWeek = props.weekDays.filter(item=>!item.working).map(el=>el.day);
        let holiday=props.holiday?props.holiday:[]
        const day = moment(date).isoWeekday();
    
        if(offWeek.indexOf(day) > -1 || holiday.find(item=>moment(item.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD') ) ){
            return     
        }else{
        return day ;
        }
        
        
  };

    const handleMouseEnter = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'block'
        }
    }

    const handleMouseLeave = (id) => {
        let doc = document.getElementById(id)

        if (doc) {
            doc.style.display = 'none'
        }
    }

    const API_URL = 'https://translate-pa.googleapis.com/v1/translateHtml';
const API_KEY = 'AIzaSyATBXajvzQLTDHEQbcpq0Ihe0vWDHmO520';

const translateHtml = async (htmlContent, sourceLang, targetLang) => {
  const headers = {
    'Accept': '*/*',
    'Accept-Language': 'en-US,en;q=0.5',
    'X-goog-api-key': API_KEY,
    'Content-Type': 'application/json+protobuf',
    // 'Origin': 'http://localhost:3000',
    'Connection': 'keep-alive',
    // 'Referer': 'http://localhost:3000/',
    'Sec-Fetch-Dest': 'empty',
    'Sec-Fetch-Mode': 'cors',
    'Sec-Fetch-Site': 'cross-site',
    'Pragma': 'no-cache',
    'Cache-Control': 'no-cache',
    'TE': 'trailers'
  };

  
  try {
    const response = await axios.post(API_URL, 
        htmlContent, { headers });

    return response.data;
  } catch (error) {
    console.error('Error translating HTML:', error);
    return null;
  }
};


useEffect(() => {

    
    if(selectedLanguage==='en'){
        return
    }
    window.document.documentElement.setAttribute('lang', selectedLanguage);
    const scanAndTranslate = async () => {
      const pageText = extractTextContent(document.getElementById('root'),['ant-select','ant-select-selection-item']);

      const translatedText = await translateHtml( [[pageText, 'en', 'id'],"te"]); 

       const translatedWords = translatedText[0];

      replaceWords(document.getElementById('root'), pageText, translatedWords,['ant-select','ant-select-selection-item']);
    //   replaceTextContent(document.body, translatedText);
    };

    const intervalId = setTimeout(scanAndTranslate, 3000); // 5 seconds interval

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [selectedLanguage]);
 const extractTextContent = (element,excludedClasses) => {
    if (!element) return '';
    let textContent = [];
    const nodeIterator = document.createNodeIterator(element, NodeFilter.SHOW_TEXT);
    let currentNode;
    while ((currentNode = nodeIterator.nextNode())) {
        
        if(currentNode.textContent.replaceAll('/n','').trim().length > 0){
        if (
      currentNode.parentElement &&
      !excludedClasses.some(cls => currentNode.parentElement.classList.contains(cls)) && 
      currentNode.textContent.replaceAll('\n', '').trim().length > 0
    ) {
      textContent.push(currentNode.textContent);
    }else{

    }
        }
    }
    return textContent;
  };

  const replaceWords = (element, originalWords, translatedWords,excludedClasses) => {
  if (!element) return;
  const nodeIterator = document.createNodeIterator(element, NodeFilter.SHOW_TEXT);
  let currentNode;
  let wordIndex = 0;
  console.log("translatedWords",translatedWords,originalWords);
  while ((currentNode = nodeIterator.nextNode())) {
    const textContent = currentNode.textContent;
    if (textContent.replaceAll('\n', '').trim().length > 0) {
      const words = textContent.trim().split(/\s+/);      
    if(
      currentNode.parentElement &&
      !excludedClasses.some(cls => currentNode.parentElement.classList.contains(cls)) &&
      currentNode.textContent.replaceAll('\n', '').trim().length > 0
    ){
        console.log("current Parent class",currentNode.parentElement.classList)
        const translateWord = translatedWords[wordIndex];
                currentNode.textContent = translateWord;
                wordIndex++
    }

    }
  }
};

  const handleLanguageChange = () => {
    const selectedLanguage = document.querySelector('.goog-te-combo').value;
    if (selectedLanguage !== 'en') {
      window.location.reload();
    }
  };
  function changeLanguage(val){
    console.log("language val",val);
    setSelectedLanguage(val);
    localStorage.setItem('selectedLanguage',val);
    window.location.reload();
  }




    return (
        <>

        {/* <DashboardLanguage
                      selectedLangValue={selectedLanguage}
                      changeLanguage={changeLanguage}
                    /> */}
            <div className="dashboardLanguageMainDiv m-r-md">
            <Select value={language} 
            style={{width:130,textAlign:"left",padding:"0px 10px"}}
            onChange={changeLanguage}
            >
                <Option value="en">English</Option>
                <Option value="id">Indonesia</Option>
            
            </Select>
        </div>
        <div className='commonHeadingDateMainDiv'>
        <React.Fragment>
            {
                props.hideIcon ? '' : <img src={calenderIcon} />
            }   
            <DatePicker
                selected={date}
                onChange={d => handleDateSelect(d)}
                dateFormat={props.dateFormat?props.dateFormat:'MMM dd'}
                isClearable={false}
                maxDate={moment() >= moment().set({hour:props.workday.end_hour.split[0] || 5 ,minute:props.workday.end_hour.split[1] || 0})?moment().toDate():moment().subtract(addDay,'day').toDate()}
                minDate={parseInt(userDetails?.org_id)== 31 && moment().subtract(120, "days").toDate() > new Date('2023-08-01')?new Date('2023-08-01'):new Date('2023-01-01')}
                placeholderText={'Select Date'}
                popperPlacement={props.popperPlacement?props.popperPlacement:'bottom-end'}
                filterDate={isWeekday}

            />
            {
                props.icon ? <img src={props.icon} />:""
            }
            <React.Fragment>
                                            <div className='helpSmallInfo iconSmallHelp' 
                                                onMouseEnter={() => handleMouseEnter(`masterDate`)}
                                                onMouseLeave={() => handleMouseLeave(`masterDate`)}
                                            >
                                                    <HelpIcon/>
                                            </div>

                                            <div className='tooltipNewStyle' id={`masterDate`} >
                                              <h5>Master Date</h5>
                                              <p>This is the master date. This determines the date of the data being viewed.</p>
                                              
                                              </div>
                                        </React.Fragment>
                                
        </React.Fragment>    
        </div>    
        </>

    )
}

export default (CommonDatePicker)