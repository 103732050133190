import React, { useState, useEffect, useRef, useMemo } from "react";
import { CommonHeading } from "../../common/commonHeading";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactModal from "react-modal";

import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
// import DatePicker from "react-multi-date-picker";
import MultipleDatePicker from "react-multiple-datepicker";
import "react-toastify/dist/ReactToastify.css";

import "../../assets/styles/common.scss";
import "../styles/dashboard.scss";
import TopAreaPerformance from "./topAreaPerformance";
// import helpIcon from "../../assets/traceplusImages/help-icon.png";
// import {ReactComponent as helpIcon} from '../../assets/images/info-circle.svg'
import pinkArrowIcon from "../../assets/traceplusImages/pink_outline_right_arrow_icon.svg";
import selectedPinkArrowIcon from "../../assets/traceplusImages/pink_right_arrow_icon.svg";

import {
  getFacilityCounterAttendance,
  getFacilityCounterReport,
  getFunctionCycleCountPlan,
  getFunctionList,
  getLanguageTranslation,
  getProcessCycleCountPlan,
  setSelectedLanguage,
} from "../actionMethods/actionMethods";

import EmployeeList from "../../manPowerManagement/components/employeeList";

import SiteMangementList from "../../siteManagement/components/index";

import DashboardChart from "./dashboardChart";
import moment from "moment";
import "antd/dist/antd.css";
import DashboardLanguage from "../../components/dashboardLanguage";
import spinnerLoader from "../../assets/images/loader.svg";
import CommonDatePicker from "../../common/commonDatePicker";
import { prepareDateObj } from "./helper";
import { titles } from "./constant";
import { getTranslatedText } from "../../common/utilities";
import {
  getDepartmentList,
  getEmployeeList,
} from "../../manPowerManagement/actionMethods/actionMethods";
import { getSiteLocations } from "../../siteManagement/actionMethods/actionMethods";
import { getDashboardReportOverview, getProcessCyclecount, getProcessList } from "../../report/actionMethods/actionMethods";
import { PresetStatusColorTypes } from "antd/lib/_util/colors";
import AgGridEmployeeList from "../../manPowerManagement/components/agGridEmployeeList";
import { atRiskEmployee } from "../../manPowerManagement/actionMethods/actionMethods";

import { sidebarMenuIftems } from "../../common/menuItems";
import DeviceStatus from "../../components/DeviceStatus";
import { getRoleDetail } from "../../common/actionMethods";
import { ReactComponent as Image404 } from "../../assets/images/11132-ai.svg";
import { getPproductivityOverviewAll } from "../../productivity/actionMethods/actionMethods";
import StackChart from "./StackAreaChart";
import { getBreakTimeReport } from "../../report/actionMethods/actionMethods";
import ChartReport from "../../report/componenets/ChartReport";

import { sidebarMenuItems } from "../../common/menuItems";
import {
  getCategoryList,
  getCycleCountPlan,
} from "../../realtimeTracker/actionMethods/actionMethods";
import Scrollbars from "react-custom-scrollbars";
import {
  getPerformanceTodayData,
  getCycleCountData,
  getFacilityWidget,
  getFacilityCounter
} from "../actionMethods/actionMethods";
import BarChart from "./barChart";
import Highcharts from "highcharts";
import StackChartDrillDown from "./StackAreaChartDrilldown";
import { getOnBoardEmp } from "../../costManagement/actionMethods/actionMethods";
import { Checkbox, Select, message   } from 'antd';
// import HelpIcon from '../../assets/images/info.png'
// import helpIconNew from "../../assets/traceplusImages/help-icon.png";
import {ReactComponent as HelpIconNew} from  '../../assets/images/circle-info-solid.svg'
// import dummy from '../../assets/get_cycle_count_dashboard_report.json'
import {ReactComponent as HelpIcon} from '../../assets/images/info-circle.svg'
import DummyFacilityPlan from '../../assets/planfacility.json'
import axios from "axios";
import ExportIcon from '../../assets/images/export.png'

import ExcelJS from 'exceljs';
import TraceLogo from '../../assets/traceplusImages/trace_logo.png'

const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ff9696",
};

const riskBackgroundColor = {
  low: "#e7f6ef",
  medium: "#faf7e5",
  high: "#fef0f4",
};
let chart = [];

const indexTitleArray = [
  {
    title: "Performance",
    isSelected: true,
    id: "performance",
    desc: <><h5>Worker Performance</h5>
            <p>This tab shows performance of the overall workers in the facility by hour, day, week or month</p>
            <p>The colour representation is as follows:<br/>
              Green: Productive areas<br/>
              Yellow: Less productive areas<br/>
              Red: Non-productive areas<br/>
              Grey: Out of range (also non-productive)</p></>,
    disabled: false,
  },
  {
    title: "Break time",
    isSelected: false,
    id: "breaktime",
    desc: "",
    disabled: false,
  },
  {
    title: "Cycle Count",
    isSelected: false,
    id: "cyclecount",
    desc:<><h5>Cycle Count</h5><p>This tab shows the number of workers in each defined location per hour.</p><p>The pink line shows the actual number as captured with the Trace+ system</p><p>The black line is visible only if the worker count plan is uploaded in the admin panel.</p> <p>This can be viewed for an day (based on master date selected), week or month</p><p>This helps you understand the spread of workers at any given time across the various locations in the facility. </p><p>It can help to identify the gaps in planning, key insights regarding worker usage in each location and the movement of workers.</p></>,
    disabled: false,
  },
    {
    title: "Facility Counter",
    isSelected: false,
    id: "facilitycounter",
    desc:<><h5>Facility Counter</h5>
     <p>This tab shows the a graphical comparison of the number of workers checked in v/s the number of workers in the working ops areas.</p> <p>This can be viewed for a day, week or month.</p><p>This helps you understand the hours of a day when more workers are not working</p></>,
    disabled: false,
  },
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxHeight: "90%",
  },
};
const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",

    width: window.innerWidth > 1300 ? 600 : "80%",
    maxHeight: "90%",
    textAlign: "center",
  },
};
  let abc=0;


function DashboardOverview(props) {
  const {Option} = Select;
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date(new Date().setDate(new Date().getDate() - 1));
  let interval = 1440;
  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id =
    userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let workday =props.workday
  let org_id = userDetails ? userDetails.org_id : 6;
  let totalEmployeeTag = localStorage.getItem("totalEmployeeTag")
    ? localStorage.getItem("totalEmployeeTag")
    : 0;
  let totalSiteLocation = localStorage.getItem("totalSiteLocation")
    ? localStorage.getItem("totalSiteLocation")
    : 0;  
  const [helpEnable,SetHelpEnable]= useState(false);
  const [employeeCount, updateEmployeeCount] = useState(0);
  const [orgId, updateOrgId] = useState(1);
  const [orgCount, updateOrgCount] = useState(0);
  const [threatWatchColor, updateThreatWatchColor] = useState("green");
  const [selectedDate, updateSelectedDate] = useState(date);
  const [loader, SetLoader] = useState(true);
  const [page,SetPage] = useState([])
  const [pageCall,SetPageCall] = useState(false);

  const [toastClass, updateToastClass] = useState("errorToast");
  const [employeePopupFlag, updateEmployeePopupFlag] = useState(false);
  const [locationPopupFlag, updateLocationPopupFlag] = useState(false);
  const [chartLoader, setChartLoader] = useState(true);


  const [selectedLangValue, updateSelectedLangValue] = useState("en");
  const [chartDataPerformance, setChartDataPerformance] = useState({
    categories: [],
    series: [],
  });
  const [chartDataPerformanceAll, setChartDataPerformanceAll] = useState({
    categories: [],
    series: [],
  });
  const [chartDataBreakTime, setChartDataBreakTime] = useState({
    categories: [],
    series: [],
  });
  const [chartDataCycleCount, setChartDataCycleCount] = useState({
    categories: [],
    series: [],
    yAxis: [],
  });
   const [chartDataFacility, setChartDataFacility] = useState({
    categories: [],
    series: [],
  });

  const [cycleCountAll, SetCycleCountAll] = useState([]);
  const [employee, SetEmployee] = useState([]);
  const [employeeAll, SetEmployeeDefault] = useState([]);
  const [dateList, SetDateList] = useState([]);
  const [maxhour, SetMaxHour] = useState(0);
  const [nohour, SetNoHour] = useState(0);
  const [cycle_count, SetCycleCount] = useState([]);
  const [active, SetActive] = useState("today");
  const [facilityActive,SetFacilityActive]= useState('category');
  // const [active, SetActive] = useState("demo");
  const [catActive, SetCatActive] = useState("all");
  const [chartDate, SetChartDate] = useState(date);
  const [compareDate, SetCompareDate] = useState([date]);
  const [chartCompareSeries, SetChartCompareSeries] = useState([]);
  let hourCheck = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
  ];
const [headerRow,SetHeaderRow] = useState([]);
const [headerRow2Disp,SetHeaderRow2Disp] = useState([]);
const [categories2Hour,SetCategories2Hour]= useState([])
const indonesiaTimeZone = 'Asia/Jakarta';  
const [functionList,SetFunctionList]= useState([]);
let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  
useEffect(()=>{
  

  
  const startHour = workday.start_hour;
  const endHour = workday.end_hour;

  if(startHour!="" &&endHour!="" ){
      let all = generateTimeArray({"end_hour": endHour,"start_hour": startHour})

      SetHeaderRow(all.headerRow)
      SetHeaderRow2Disp(all.headerRow2Disp)
      SetCategories2Hour(all.categories2Hour)
  }else{
    SetHeaderRow(["07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00","00:00","01:00","02:00","03:00","04:00","05:00","06:00"])
    SetHeaderRow2Disp(["07 AM","08 AM","09 AM","10 AM","11 AM","12 PM","01 PM","02 PM","03 PM","04 PM","05 PM","06 PM","07 PM","08 PM","09 PM","10 PM","11 PM","00 AM","01 AM","02 AM","03 AM","04 AM","05 AM","06 AM"])
    SetCategories2Hour([7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0,1,2,3,4,5,6])
  }
},[workday])

useEffect(()=>{

  SetPage(props.pages.filter(el=>el.page_name =="Dashboard"));
  SetPageCall(true);
},[props.pages])


  
  
  const headerRow2DispMaxTime = [
    moment().set({ hour: 7, minutes: 0, second: 0 }),
    moment().set({ hour: 8, minutes: 0, second: 0 }),
    moment().set({ hour: 9, minutes: 0, second: 0 }),
    moment().set({ hour: 10, minutes: 0, second: 0 }),
    moment().set({ hour: 11, minutes: 0, second: 0 }),
    moment().set({ hour: 12, minutes: 0, second: 0 }),
    moment().set({ hour: 13, minutes: 0, second: 0 }),
    moment().set({ hour: 14, minutes: 0, second: 0 }),
    moment().set({ hour: 15, minutes: 0, second: 0 }),
    moment().set({ hour: 16, minutes: 0, second: 0 }),
    moment().set({ hour: 17, minutes: 0, second: 0 }),
    moment().set({ hour: 18, minutes: 0, second: 0 }),
    moment().set({ hour: 19, minutes: 0, second: 0 }),
    moment().set({ hour: 20, minutes: 0, second: 0 }),
    moment().set({ hour: 21, minutes: 0, second: 0 }),
    moment().set({ hour: 22, minutes: 0, second: 0 }),
    moment().set({ hour: 23, minutes: 0, second: 0 }),
    moment().set({ hour: 24, minutes: 0, second: 0 }),
    moment().set({ hour: 25, minutes: 0, second: 0 }),
    moment().set({ hour: 26, minutes: 0, second: 0 }),
    moment().set({ hour: 27, minutes: 0, second: 0 }),
    moment().set({ hour: 28, minutes: 0, second: 0 }),
    moment().set({ hour: 29, minutes: 0, second: 0 }),
    moment().set({ hour: 30, minutes: 0, second: 0 }),
  ];

  const converSionHeaderRow2Disp = [
    "00 AM",
    "01 AM",
    "02 AM",
    "03 AM",
    "04 AM",
    "05 AM",
    "06 AM",
    "07 AM",
    "08 AM",
    "09 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "01 PM",
    "02 PM",
    "03 PM",
    "04 PM",
    "05 PM",
    "06 PM",
    "07 PM",
    "08 PM",
    "09 PM",
    "10 PM",
    "11 PM",
  ];
/*
  function generateTimeArray(inputObject) {
  const startTime = new Date(`2000-01-01T${inputObject.start_hour}`);
  const endTime = new Date(`2000-01-01T${inputObject.end_hour}`);
  const timeArray = [];
const headerRow2DispAr= []
const categories2HourAr=[]
  let currentTime = startTime;
  

  while (true) {

    timeArray.push(moment(currentTime).format('HH:mm'));
    headerRow2DispAr.push(moment(currentTime).format('H A'));
    categories2HourAr.push(parseInt(moment(currentTime).format('HH')));
    currentTime.setHours(currentTime.getHours() + 1);
  
    if (currentTime.getHours() === endTime.getHours() && currentTime.getMinutes() === endTime.getMinutes()) {
      // Stop when we reach the end time
      break;
    }
  }
  return {headerRow:timeArray,headerRow2Disp:headerRow2DispAr,categories2Hour:categories2HourAr};
}
*/
function generateTimeArray(inputObject) {
 let sDate= new Date(`2000-01-01T${inputObject.start_hour}`);
  let eDate= new Date(`2000-01-01T${inputObject.end_hour}`);
  if(sDate > eDate){
      eDate = new Date(`2000-01-02T${inputObject.end_hour}`);
      
  }
  const startTime = new Date(`2000-01-01T${inputObject.start_hour}`);
  const endTime = eDate;
  const timeArray = [];
  const headerRow2DispAr = [];
  const categories2HourAr = [];
  let currentTime = startTime;

   while (currentTime <= endTime) {
    timeArray.push(moment(currentTime).format('HH:mm'));
    headerRow2DispAr.push(moment(currentTime).format('hh A'));
    categories2HourAr.push(parseInt(moment(currentTime).format('HH')));
    currentTime.setHours(currentTime.getHours() + 1);
  }
  


  return {
    headerRow: timeArray,
    headerRow2Disp: headerRow2DispAr,
    categories2Hour: categories2HourAr
  };
}

  const [dates, setDates] = useState({
    start: moment(moment()).subtract(28, "days").toDate(),
    end: date,
  });

  const [statusCount, updateStatusCount] = useState({
    personal: 0,
    location: 0,
  });
  const [initialLoader, SetInitialLoader] = useState(false);
  const [infinitefix, SetInfiniteFix] = useState(0);
  const [sensitiveType, SetSensitiveType] = useState(0);
  const [rank2Data, SetRank2Data] = useState([]);
  const initialMenu = localStorage.getItem("menuseq")
    ? JSON.parse(localStorage.getItem("menuseq"))
    : sidebarMenuItems.map((item) => {
        return item.title;
      });
  const [role, SetRole] = useState([]);
  const [permission, SetPermission] = useState([]);
  const [permissionAPICall, SetPermissionAPICall] = useState(false);
  const [modalType, setModalType] = useState("");
  const [filterEnable,SetFilterEnable]=useState(false);
  const [indexLevel, setIndexLevel] = useState({
    area: "",
    mobility: "",
    population: "",
    spread: "",
  });
  const [indexTitle, updateIndexTitle] = useState(0);
  const [percentDailyWorkHour, SetPercentDailyWorkHour] = useState(0);
  const [percentDailyWastedHour, SetPercentDailyWastedHour] = useState(0);
  const [totalBillRegularHrs, SetTotalBillRegularHrs] = useState(0);
  const [totalBillDailyHrs, SetTotalBillDailyHrs] = useState(0);
  const [totalShiftRegular, SetTotalShiftRegular] = useState(0);
  const [totalShiftDaily, SetTotalShiftDaily] = useState(0);
  const [dataAll, SetDataAll] = useState([]);

  const [todayStats, SetTodayStats] = useState(0);
  const [WeekStats, SetWeekStats] = useState(0);
  const [weekName, SetWeekName] = useState("");
  const [monthStats, SetMonthStats] = useState(0);
  const [performanceTitle, SetPerformanceTitle] = useState("");
  const chartContainer = React.useRef();
  const [widthChart, setWidthChart] = useState(0);
  const [errorMessage, SetError] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [chartOpenModal, SetChartModalOpen] = useState(false);
  const [chartOpenData, SetChartOpenData] = useState({
    categories: [],
    series: [],
  });
  const [displayDate, SetChartDisplayDate] = useState("");
  const[modalDate,SetModalDate]=useState(false);
  const [chartOpenLoader, SetChartOpenLoader] = useState(false);
  const [loaderSecondWidget,SetLoaderSecondWidget]=useState(true);
  const [partialLoad,SetPartialLoad] = useState(true);
  const [departmentList,SetDepartmentList] = useState([]);
  const [subDepartmentList,SetSubDepartmentList]=useState([]);
  const [departmentID,SetDeparmentID] = useState([]);
  const [subDepartmentID,SetSUbDepartmentID] = useState([]);
  const [chartProcess,SetChartProcess]=useState({});

  // const colorPallete= ["red","green","blue","orange",];

  const categories = [
    "12 AM",
    "01 AM",
    "02 AM",
    "03 AM",
    "04 AM",
    "05 AM",
    "06 AM",
    "07 AM",
    "08 AM",
    "09 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "01 PM",
    "02 PM",
    "03 PM",
    "04 PM",
    "05 PM",
    "06 PM",
    "07 PM",
    "08 PM",
    "09 PM",
    "10 PM",
    "11 PM",
  ];
const hourTImeDisp = [{"display":"05 AM",time:"05"},{"display":"06 AM",time:"06"},{"display":"07 AM",time:"07"},{"display":"08 AM",time:"08"},{"display":"09 AM",time:"09"},{"display":"10 AM",time:"10"},{"display":"11 AM",time:"11"},{"display":"12 PM",time:"12"},{"display":"01 PM",time:"13"},{"display":"02 PM",time:"14"},{"display":"03 PM",time:"15"},{"display":"04 PM",time:"16"},{"display":"05 PM",time:"17"},{"display":"06 PM",time:"18"},{"display":"07 PM",time:"19"},{"display":"08 PM",time:"20"},{"display":"09 PM",time:"21"},{"display":"10 PM",time:"22"},{"display":"11 PM",time:"23"},{"display":"12 AM",time:"00"},{"display":"01 AM",time:"01"},{"display":"02 AM",time:"02"},{"display":"03 AM",time:"03"},{"display":"04 AM",time:"04"}]

  const [checkedIn,SetCheckedIn] = useState({prev:0,current:0});
  const [inFacility,SetInFacility] = useState({prev:0,current:0});
  const [magicRatio,SetMagicRatio]= useState({prev:0,current:0});
  const [topAPICall,SetTopAPICall]= useState(true);
  const [dataRecieved,SetDataRecieved] = useState(false);
  const elRef = useRef();

  const monthInt = [
    "",
    "Jaunary",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = [
    { month: "Jan", days: 31, intMonth: 1 },
    { month: "Feb", days: 28, intMonth: 2 },
    { month: "Mar", days: 31, intMonth: 3 },
    { month: "Apr", days: 30, intMonth: 4 },
    { month: "May", days: 31, intMonth: 5 },
    { month: "Jun", days: 30, intMonth: 6 },
    { month: "Jul", days: 31, intMonth: 7 },
    { month: "Aug", days: 31, intMonth: 8 },
    { month: "Sep", days: 30, intMonth: 9 },
    { month: "Oct", days: 31, intMonth: 10 },
    { month: "Nov", days: 30, intMonth: 11 },
    { month: "Dec", days: 31, intMonth: 12 },
  ];

  function offChartLoader(state){
    setChartLoader(state);   
  }
  

  function performaceData(data,type=false){

    setChartDataPerformanceAll(data);
    SetTopAPICall(false);
    if(active!="today" && indexTitle === 0){
    
        changeDataofPerformance(active,data);
    }
    SetDataRecieved(type);
    
  }

  useEffect(()=>{
    let dateBox= document.querySelectorAll('.sc-chPdSV');
 
    if(modalDate){
      if(dateBox.length> 0){
 
        dateBox[0].classList.add('bSnXtJ');
        dateBox[0].classList.remove('ftQjTc');
        let elm = document.querySelectorAll('.sc-VigVT.dsKVpZ');
        if(elm){
          elm[0].addEventListener('click',function(){
            SetModalDate(false);
          })
        }
        
        
      }
    }else{
      if(dateBox.length> 0){
        dateBox[0].classList.add('ftQjTc');
          dateBox[0].classList.remove('bSnXtJ')
        let elm = document.querySelectorAll('.sc-VigVT.dsKVpZ');
        if(elm){
          elm[0].addEventListener('click',function(){
            SetModalDate(false);
          })
        }
      }
    }
    
  },[modalDate])
 
  function GetRandomDigit(min=0,max=10){
   return  Math.floor(Math.random() * (max - min + 1)) + min;
  }



  useEffect(() => {
    if (!elRef?.current?.clientWidth) {
      return;
    }
    setWidthChart(elRef?.current?.clientWidth);
  }, [elRef?.current?.clientWidth]);

  useEffect(() => {
    let requestBody = {};
    requestBody.session = userSession;
    requestBody.org_id = org_id;

    getCategoryList(requestBody).then((res) => {
      if (res.status == 200) {
        let arr = [];
        setCategoriesList(res.data.filter((item) => item.location.length > 0));
      }
    });
    
  }, [selectedDate, chartDate]);


  useEffect(() => {
    let requestBody = {};
    requestBody.session = userSession;
    requestBody.org_id = org_id;

    getFunctionList(userSession,org_id,sub_org_id).then((res) => {
      if (res.status == 200) {
        let arr = [];
        SetFunctionList(res.data.map((el)=>({...el,name:el.category_name})));
      }
    });
    
    
  }, []);

  useEffect(()=>{
    // fetchEmployee()
    getOnBoardEmp(userSession,org_id,moment(selectedDate).subtract(7,'days').format('YYYY-MM-DD'),getDateFormat(selectedDate),sub_org_id).then(res=>{ 
    //let res= DummyOnboard;
      if(res.status ==200){

        let emp= res.data.regular?res.data.regular:[];
        if(res.data.daily){
          emp.push(...res.data.daily)
        }
        let regMap=[];
        const departmentInfo = [];
  const departmentInfoDaily = [];

  emp.forEach((el) => {
          const dept = el.department_name;
          const subDept = el.sub_department_name;
          const deptID = el.department_id;
          const subDeptID = el.sub_department_id;


        // const department = single.department;
        // const departmentID = single.department;
        // const subDepartment = single.sub_department;

          // Check if the department is already in the array
          const existingDepartment = departmentInfo.find((d) => d.name === dept);

          if (existingDepartment) {
            // Check if the sub_department is not already in the array for this department
            if (!existingDepartment.sub_department.find(e=> e.name == subDept)) {
              existingDepartment.sub_department.push({name:subDept,id:subDeptID});
            }


          } else {
            // Create a new entry for the department
            departmentInfo.push({
              name: dept,
              id:deptID,
              sub_department: [{name:subDept,id:subDeptID}],
            });
          }
      }); 
      SetDepartmentList(departmentInfo);


          //emp.push(...res.data.daily?res.data.daily:[])

      }
    })
    //only today
    
    // const id= setInterval(fetchEmployee,30000);

    return ()=>{
      // clearInterval(id)
    }

  },[])

  function fetchEmployee(){
      // getOnBoardEmp(userSession,org_id,moment().format('YYYY-MM-DD'),moment().format('YYYY-MM-DD'),sub_org_id).then(res=>{ 
      getOnBoardEmp(userSession,org_id,moment().subtract(1,'days').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD'),sub_org_id).then(res=>{ 
      //let res= DummyOnboard;
        if(res.status ==200){

          let emp= res.data.regular?res.data.regular:[];
          if(res.data.daily){
            emp.push(...res.data.daily)
          }
      
        // SetEmployee(emp.filter(el=>el.check_out===null))

        }
      });

  }


  const getImageId = async (workbook, imageUrl) => {
    const response = await fetch(imageUrl);
    const arrayBuffer = await response.arrayBuffer();
    const base64Image = Buffer.from(arrayBuffer).toString('base64');

    return workbook.addImage({
      base64: base64Image,
      extension: 'png', // Change the extension based on your image format
    });
  };

  const generateExcel = async (data,categoriesList) => {
        const workbook = new ExcelJS.Workbook();

    // Determine date range based on the active period
    let start = moment(chartDate).format("YYYY-MM-DD");
    let end = moment(chartDate).format("YYYY-MM-DD");
    if (active === "week") {
      end = moment(chartDate).subtract(7, 'days').format("YYYY-MM-DD");
    } else if (active === "month") {
      end = moment(chartDate).subtract(28, 'days').format("YYYY-MM-DD");
    }

    // Loop through each category

    categoriesList.forEach(category => {
      const sheet = workbook.addWorksheet(category.name);
      const filteredSeries = data.series.filter(item => item.cat === category.name);

      // Add logo
        // const imageId = await getImageId(workbook, );      
      const imageId = workbook.addImage({
      base64: TraceLogo,
      extension: 'png',
    },
  
  );
    sheet.addImage(imageId,{
      tl: { col: 0, row: 1 },
      ext: { width: 153, height: 34 },
    });
      // Add report details
      sheet.mergeCells('A1:B4');
      sheet.addRow([]);
      sheet.addRow(['Name of report', 'Cycle count by process']).getCell(2).font = { bold: true };
      sheet.addRow(['Report Type', active==="today"?"Day":active]).getCell(2).font = { bold: true };
      sheet.addRow(['Category', category.name]).getCell(2).font = { bold: true };
      sheet.addRow(['Date', `${start} to ${end}`]).getCell(2).font = { bold: true };
sheet.addRow([]);sheet.addRow([]);sheet.addRow([]);
      // Add header row
      const headerRow = ['Time'];
      filteredSeries.forEach(series => {
        headerRow.push(series.name);
      });

      sheet.addRow(headerRow).eachCell((cell) => {

      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '20639e' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });
        
sheet.columns.forEach((column) => {
  let maxLength = 0;
  column.eachCell({ includeEmpty: true }, (cell) => {
    const columnLength = cell.value ? cell.value.toString().length : 10;
    if (columnLength > maxLength) {
      maxLength = columnLength;
    }
  });
  column.width = maxLength < 10 ? 10 : maxLength;
});

    for (let i = 0; i < 24; i++) {
        const row = [data.categories[i]];
        filteredSeries.forEach(series => {
          row.push(series.data[i]);
        });
        sheet.addRow(row);
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Process Cycle Count By Category ${start} to ${end}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);

  };
  
const generateExcel2 = async(data, categoriesList) => {
  const workbook = new ExcelJS.Workbook();
  let categories = data.categories;
  let series = data.series;

  let start = moment(chartDate).format("YYYY-MM-DD");
  let end = moment(chartDate).format("YYYY-MM-DD");
  if (active === "week") {
    end = moment(chartDate).subtract(7, 'days').format("YYYY-MM-DD");
  } else if (active === "month") {
    end = moment(chartDate).subtract(28, 'days').format("YYYY-MM-DD");
  }
  let processData = await getProcessCyclecount(userSession, org_id, sub_org_id, start, end).then(res => res.data);
  const uniqueSeriesNames = [...new Set(series.map(item => item.name))].filter(name => name !== 'Actual');

  uniqueSeriesNames.forEach(processName => {
    const worksheet = workbook.addWorksheet(processName);

    const imageId = workbook.addImage({
      base64: TraceLogo,
      extension: 'png',
    });
    worksheet.addImage(imageId, {
      tl: { col: 0, row: 1 },
      ext: { width: 153, height: 34 },
    });

    // Add report details
    worksheet.mergeCells('A1:B4');
    worksheet.addRow([]);
    worksheet.addRow(['Name of report', 'Cycle count by process']).getCell(2).font = { bold: true };
    worksheet.addRow(['Report Type', active === "today" ? "Day" : active]).getCell(2).font = { bold: true };
    worksheet.addRow(['Process Name', processName]).getCell(2).font = { bold: true };
    worksheet.addRow(['Date', `${start} to ${end}`]).getCell(2).font = { bold: true };
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);

    const headerRow = ['Time', ...categoriesList.map(cat => cat.name), "Total", "", "Active CheckIn"];

    const header = worksheet.addRow(headerRow).eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '20639e' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    });

    // Change the header colors for "Total" and "Active CheckIn"
    const headerRowIndex = worksheet.lastRow.number;
    const totalHeaderCell = worksheet.getRow(headerRowIndex).getCell(headerRow.length - 2);
    totalHeaderCell.font = { bold: true, color: { argb: '000' } };
    totalHeaderCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '8cbbe4' } // Light blue
    };

    const activeCheckInHeaderCell = worksheet.getRow(headerRowIndex).getCell(headerRow.length);
    activeCheckInHeaderCell.font = { bold: true, color: { argb: '000' } };
    activeCheckInHeaderCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '8cbe51' } // Light green
    };

    const dataRows = categories.map((time, index) => {
      const row = [time];
      let totalRow = 0;
      let key = hourTImeDisp.find(el => el.display === time)?.time;

      categoriesList.forEach(cat => {
        const seriesData = series.find(s => s.cat === cat.name && s.name === processName);
        if (seriesData && seriesData.data[index]) {
          totalRow = totalRow + seriesData.data[index];
        }
        row.push(seriesData ? seriesData.data[index] : 0);
      });

      row.push(totalRow);
      row.push(""); // This cell will be transparent
      if (key && processData[processName][key]) {
        row.push(processData[processName][key].active);
      } else {
        row.push(0);
      }

      return row;
    });

    dataRows.forEach(row => {
      const dataRow = worksheet.addRow(row);
      const totalCell = dataRow.getCell(row.length - 2);
      const activeCheckInCell = dataRow.getCell(row.length);

      totalCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '8cbbe4' }
      };

      activeCheckInCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '8cbe51' }
      };
    });

    worksheet.columns.forEach(column => {
      column.width = 15;
    });

    // Apply transparent background to the specific empty cell after "Total"
    worksheet.eachRow((row, rowNumber) => {
      const emptyCell = row.getCell(headerRow.length - 1);
      emptyCell.fill = {
        type: 'pattern',
        pattern: 'none'
      };
    });
  });

  workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `processesV2-${start}-${end}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  });
};



  useEffect(() => {
    if (indexTitle == 2) {
      changeDataofCycleCount(active, selectedDate,facilityActive);
    }
  }, [selectedDate, chartDate,facilityActive]);

useEffect(() => {
    if (indexTitle == 3) {
      changeDataFacilityCounter(active, selectedDate);
      // changeDataofCycleCount(active, selectedDate);
    }
  }, [selectedDate, chartDate]);


  function generateHighChart(data, planned, type, selectedDate,facilityActive) {

    let cat = headerRow2DispMaxTime.filter((item) => item <= moment(chartDate));
    let loop=[]
    if(facilityActive==="category"   || facilityActive==="process" || facilityActive==="process2"){
        loop=[...categoriesList];
    }else{
      loop =[...functionList];
    }
    
    for (let i = 0; i < loop.length; i++) {
      let plan = [];
      let act =[];
      if(facilityActive==="process" || facilityActive==="process2"){
        plan = data.series.filter((item) => item.cat == loop[i].name )

      }else{
        plan = data.series.find(
        (item) => item.cat == loop[i].name && item.name == "Planned"
      );
      act = data.series.find(
        (item) => item.cat == loop[i].name && item.name == "Actual"
      );
      }       

      
      
      chart[i] = new Highcharts.Chart({
        chart: {
          type: "spline",
          renderTo:facilityActive=="process" || facilityActive=="process2"?`process${slugify(loop[i].name)}`:`${slugify(loop[i].name)}`,
          defaultSeriesType: "spline",
          spacingBottom: 0,
          height:facilityActive==="process" || facilityActive==="process2"? 220:150,
          events: {},
          lang: {
            noData: "Awaiting for data",
          },
          zoomType: "x",
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: headerRow2Disp,
          labels: {
            useHTML: true,
            formatter: function () {
              
              if (type == "week" || type == "month") {
                return this.value;
              } else {
                if (this.value == "07 AM") {
                  return `<label class="smallLabel">${
                    this.value
                  }</label><span class="smallTitleDate">${
                    type == "today"
                      ? moment(chartDate).format("DD MMM")
                      : moment(chartDate).format("DD MMM")
                  }`;
                } else if (this.value == "12 AM") {
                  return `<label class="smallLabel">${
                    this.value
                  }</label><span class="smallTitleDate">${
                    type == "today"
                      ? moment(chartDate).add(addDay, "days").format("DD MMM")
                      : moment(chartDate).add(addDay, "days").format("DD MMM")
                  }`;
                } else {
                  return `<label class="smallLabel">${this.value}</label>`;
                }
              }
            },
          },
          title: {
            text: "",
          },
          tickPositioner: function () {
            let positions = [];
            let tick = Math.floor(this.dataMin);
            positions = [0, 2, 5, 8, 11, 14, 17, 20, 23];
            return positions;
          },
        },
        yAxis: {
          allowDecimals: false,
          labels: {
            formatter: function () {
              return this.value;
            },
          },
          min: 0,
          tickAmount: 5,
          title: {
            text: "",
          },
          gridLineColor: "#fff",
          // min:pollutant=='temp'?'-10':0,
        },
        tooltip: {
          enabled: true,
          shared: true,
          // formatter: function() {

          //   var strTooltip = "";
          //     strTooltip =  this.x
          //     + '<br/><b>'+this.series.name+"</b>  "+ this.y ;
          //       return strTooltip;

          // }
        },
        credits: {
          enabled: false,
        },
        legend: { enabled: facilityActive==="process" || facilityActive==="process2"? true:false,

        labelFormatter: function () {
            return '<div style="font-size: 10px;float:left;width:100%" class="legendStyleMobileNew">' +
                   '<span style="background-color:' + this.color + '; display: inline-block; width: 10px; height: 10px;border-radius:10"></span>' +
                   '<span >' + this.name + '</span>' +
                   '</div>';
        },
        useHTML: true,
        className:"legendLayoutNew",
        itemWidth: (window.innerWidth - 60)/ 4,
        itemStyle: {
            lineHeight: '12px'
        },
       



         },
        plotOptions: {
          series: {
            animation: {
              duration: 3000,
            },
            borderWidth: 1,

            marker: {
              enabled: true,
              radius: 2,
            },
            connectNulls: true,
          },
        },
        series:facilityActive =="process" || facilityActive =="process2"?plan:[plan, act],
      });
    }
  }

  const getMonths = (fromDate, toDate, performance) => {
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];
    if (performance == "month") {
      let loop = 0;
      for (let year = fromYear; year <= toYear; year++) {
        let monthNum = year === fromYear ? fromMonth : 0;
        const monthLimit = year === toYear ? toMonth : 11;

        for (; monthNum <= monthLimit; monthNum++) {
          let month2 = monthNum + 1;
          let MonthAct = month.find((item) => item.intMonth == month2);
          let start = "";
          let end = "";
          let cond = "";

          if (loop == 0 && year == toYear && monthNum == monthLimit) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 1";
          } else if (loop == 0) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 2";
          } else if (year == toYear && monthNum == monthLimit) {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 3";
          } else {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 4";
          }
          loop++;

          months.push({
            year,
            month: month2,
            start: start,
            end: end,
            cond: cond,
          });
        }
      }

      return months;
    }
    // else {return getMonths(fromDate, toDate,'month');}
   else {

      return generateWeeksBard(fromDate, toDate);
    }
  };
  
function generateWeeksBard(startDate, endDate) {
  const weeks = [];
  let currentDate = moment(startDate);
  const finalDate = moment(endDate);

  // Adjust the first week to end on the next Sunday
  let firstWeekEnd = moment(currentDate).day(7); // Sunday of the first week

  if (firstWeekEnd > finalDate) {
    firstWeekEnd = finalDate;
  }

  weeks.push({
    start: moment(currentDate).format('YYYY-MM-DD'),
    end: moment(firstWeekEnd).format('YYYY-MM-DD'),
    month: moment(currentDate).month() + 1,
    year: moment(currentDate).year(),
    weekName: `Week ${getWeekNumber(moment(currentDate).toDate())}`,
  });

  currentDate = moment(firstWeekEnd).add(1, 'days');

  // Process full weeks from Monday to Sunday
  while (currentDate <= finalDate) {
    let weekStart = moment(currentDate).day(1); // Set to Monday
    let weekEnd = moment(weekStart).day(7); // Set to Sunday

    if (weekEnd > finalDate) {
      weekEnd = finalDate;
    }

    weeks.push({
      start: moment(weekStart).format('YYYY-MM-DD'),
      end: moment(weekEnd).format('YYYY-MM-DD'),
      month: moment(weekStart).month() + 1,
      year: moment(weekStart).year(),
      weekName: `Week ${getWeekNumber(moment(weekStart).toDate())}`,
    });

    currentDate = moment(weekEnd).add(1, 'days');
  }

  return weeks;
}

function getWeekNumber(date) {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - oneJan) / 86400000);
  return Math.ceil((days + oneJan.getDay() + 1) / 7);
}

  function modalChart(date) {
    SetChartModalOpen(true);
    SetChartOpenLoader(true);
    
    SetChartDisplayDate("Hourly " + moment(date).format("Do MMM YYYY"));
    let requestBody = {};
    requestBody.start_date = date;
    requestBody.end_date = date;
    requestBody.slug = "employee";
    let arr = [];
    let cat = [];
    const newArray = [];
 
    getPerformanceTodayData(requestBody, userSession, org_id, sub_org_id).then((res) => {
        if (res.status == 200) {
          SetError("");

          
    const startDate = moment(date).format("YYYY-MM-DD");
    const endDate = moment(date).add(addDay,'days').format("YYYY-MM-DD");

    const formattedResult = formatApiData(res, startDate, endDate);
    
    
    newArray.push(...formattedResult.filter(el=>moment(new Date().toLocaleString('en-US', { timeZone: indonesiaTimeZone })) >= moment(el.date).set({hour:parseInt(el.time)}))) 
 
   
        let uniqueArray = [...new Set(newArray.map(el=>el.date))];

        
          SetChartOpenData({
            categories: newArray.map((el) => moment(el.date).set({ hour: el.time }).format("hh A")),
            series: [
              {name: "Out of Range",data: newArray.map((item) => item.undefined),color: "#dedee3",},
              {name: "Non Productive",data: newArray.map((item) => item.non_productive),color: "#fc0001",},
              {name: "Less Productive",data: newArray.map((item) => item.less_productive),color: "#fdbf00",},
              {name: "Productive",data: newArray.map((item) => item.productive),color: "#90cf4e",},
            ],
          });
          SetChartOpenLoader(false);
        } else {
          SetChartOpenData({ categories: [], series: [] });
          SetChartOpenLoader(false);
        }
      
      });
  }

  function changeDataofPerformance(type,dataDefault = { series: [], categories: [] } ) {
    SetError("");

    let data =
      dataDefault.series.length > 0
        ? { ...dataDefault }
        : { ...chartDataPerformanceAll };
      
    if (type == "today") {
      setChartLoader(true);
      let requestBody = {};
      requestBody.start_date = moment(chartDate).format("YYYY-MM-DD");
      requestBody.end_date = moment(chartDate).format("YYYY-MM-DD");
      requestBody.slug = "employee";
      let arr = [];
      let cat = [];
 
      getPerformanceTodayData(requestBody,userSession,org_id,sub_org_id).then((res) => {    
            if (res.status == 200) {
          SetError("");


const newArray = [];

// Iterate through the data

    const startDate = moment(chartDate).format("YYYY-MM-DD");
    const endDate = moment(chartDate).add(addDay,'days').format("YYYY-MM-DD");

    const formattedResult = formatApiData(res, startDate, endDate);
    
    // newArray.push(...formattedResult.filter(el=>moment() >= moment(el.date).set({hour:parseInt(el.time)}))) 
    
    newArray.push(...formattedResult.filter(el=>new Date().toLocaleString('en-US', { timeZone: indonesiaTimeZone }) >= moment(el.date).set({hour:parseInt(el.time)}))) 
  
          setChartDataPerformance({
            categories: newArray.map((el) => moment(el.date).set({ hour: el.time }).format("hh A")),
            series: [
              {
                name: "Out of Range",
                data: newArray.map((item) => item.undefined),
                color: "#dedee3",
              },
              {
                name: "Non Productive",
                data: newArray.map((item) => item.non_productive),
                color: "#fc0001",
              },
              {
                name: "Less Productive",
                data: newArray.map((item) => item.less_productive),
                color: "#fdbf00",
              },
              {
                name: "Productive",
                data: newArray.map((item) => item.productive),
                color: "#90cf4e",
              },
            ],
          });
          SetChartCompareSeries([
            {
              date: requestBody.start,
              categories: headerRow2Disp,
              series: [
                {
                  name: "Out of Range",
                  data: arr.map((item) => item.undefined),
                  color: "#dedee3",
                },
                {
                  name: "Non Productive",
                  data: arr.map((item) => item.non_productive),
                  color: "#fc0001",
                },
                {
                  name: "Less Productive",
                  data: arr.map((item) => item.less_productive),
                  color: "#fdbf00",
                },
                {
                  name: "Productive",
                  data: arr.map((item) => item.productive),
                  color: "#90cf4e",
                },
              ],
            },
          ]);
          setChartLoader(false);
      
          
        } else {
          // SetError('Some Issue Occured, Please Try after some time')
          setChartDataPerformance({ categories: [], series: [] });
      
          setChartLoader(false);
        }
      });
    }

    if (type == "day" || type == "demo") {
     
      setChartDataPerformance({
        categories: data.categories
          .sort((a, b) => new Date(a) - new Date(b))
          .filter(
            (item) =>
              moment(item) >= moment(chartDate).subtract(28, "days") &&
              moment(item) <= moment(chartDate)
          ),
        series: [
          {
            name: "Out of Range",
            data: data.series[0].data
              .sort((a, b) => new Date(a.date) - new Date(b.date))
              .filter(
                (item) =>
                  moment(item.date) >= moment(chartDate).subtract(28, "days") &&
                  moment(item.date) <= moment(chartDate)
              )
              .map((item) => item.value),
            color: "#dedee3",
          },
          {
            name: "Non Productive",
            data: data.series[1].data
              .sort((a, b) => new Date(a.date) - new Date(b.date))
              .filter(
                (item) =>
                  moment(item.date) >= moment(chartDate).subtract(28, "days") &&
                  moment(item.date) <= moment(chartDate)
              )
              .map((item) => item.value),
            color: "#fc0001",
          },
          {
            name: "Less Productive",
            data: data.series[2].data
              .sort((a, b) => new Date(a.date) - new Date(b.date))
              .filter(
                (item) =>
                  moment(item.date) >= moment(chartDate).subtract(28, "days") &&
                  moment(item.date) <= moment(chartDate)
              )
              .map((item) => item.value),
            color: "#fdbf00",
          },
          {
            name: "Productive",
            data: data.series[3].data
              .sort((a, b) => new Date(a.date) - new Date(b.date))
              .filter(
                (item) =>
                  moment(item.date) >= moment(chartDate).subtract(28, "days") &&
                  moment(item.date) <= moment(chartDate)
              )
              .map((item) => item.value),
            color: "#90cf4e",
          },
        ],
      });
      // SetDataRecieved(true);
      setChartLoader(false);
      // setChartDataPerformance(data);
    } else if (type == "week" || type == "month") {

      let monthDiff = getMonths(
        moment(chartDate).subtract(35, "days").toDate(),
        moment(chartDate).toDate(),
        type
      );
      

      monthDiff = monthDiff.sort(function (a, b) {
            return new Date(a.start) - new Date(b.start);
          }).slice(Math.max(monthDiff.length - 5, 0))
      
      if (type == "month") {
        
        monthDiff = getMonths(
          moment(chartDate).subtract(60, "days").toDate(),
          moment(chartDate).toDate(),
          type
        );
        monthDiff = monthDiff.sort(function (a, b) {
            return new Date(a.start) - new Date(b.start);
          }).slice(Math.max(monthDiff.length - 2, 0))
      }

      let undefinedProductive = [];
      let nonProductive = [];
      let lessProductive = [];
      let Producivite = [];
      let cat = [];
      for (let j = 0; j < monthDiff.length; j++) {
        let undefinedData = data.series[0].data.filter(
          (item) =>
            moment(item.date) >= moment(monthDiff[j].start) &&
            moment(item.date) <= moment(monthDiff[j].end)
        );

        undefinedProductive.push(
          undefinedData.reduce(
            (oldVal, item) => oldVal + parseFloat(item.value),
            0
          ) / undefinedData.filter((item) => item.value > 0).length
        );

        let nonProdData = data.series[1].data.filter(
          (item) =>
            moment(item.date) >= moment(monthDiff[j].start) &&
            moment(item.date) <= moment(monthDiff[j].end)
        );

        nonProductive.push(
          nonProdData.reduce(
            (oldVal, item) => oldVal + parseFloat(item.value),
            0
          ) / nonProdData.filter((item) => item.value > 0).length
        );

        let lessProdData = data.series[2].data.filter(
          (item) =>
            moment(item.date) >= moment(monthDiff[j].start) &&
            moment(item.date) <= moment(monthDiff[j].end)
        );

        lessProductive.push(
          lessProdData.reduce(
            (oldVal, item) => oldVal + parseFloat(item.value),
            0
          ) / lessProdData.filter((item) => item.value > 0).length
        );

        let ProdData = data.series[3].data.filter(
          (item) =>
            moment(item.date) >= moment(monthDiff[j].start) &&
            moment(item.date) <= moment(monthDiff[j].end)
        );

        Producivite.push(
          ProdData.reduce((oldVal, item) => oldVal + parseFloat(item.value), 0) /
            ProdData.filter((item) => item.value > 0).length
        );
        
        if (type == "week") {
          cat.push(monthDiff[j].weekName);
        } else {
          cat.push(monthInt[monthDiff[j].month]);
        }
      }

      setChartDataPerformance({
        categories: cat,
        series: [
          { name: "Out of Range", data: undefinedProductive, color: "#dedee3" },
          { name: "Non Productive", data: nonProductive, color: "#fc0001" },
          { name: "Less Productive", data: lessProductive, color: "#fdbf00" },
          { name: "Productive", data: Producivite, color: "#90cf4e" },
        ],
      });
      //setChartDataPerformance(data);
    } else {
    }

    SetActive(type);
  }
  useEffect(() => {

    if (props.match.path == "/") {
      let menuItem = sidebarMenuItems.find(
        (item) => item.title == initialMenu[0]
      );
      props.history.push(menuItem.paths);
    }
  }, []);

  useEffect(() => {
    // let perm= props.role.health_management?JSON.parse(props.role.health_management):null;
    let perm = props.pages.filter(el=>el.page_name=="Dashboard");
      SetPermission(perm);
      SetPermissionAPICall(true);
   
  }, [props.pages]);

  useEffect(() => {
    if (permission && permission.length > 0) {
      if (props.match.path == "/") {
        let menuItem = sidebarMenuItems.find(
          (item) => item.title == initialMenu[0]
        );
        props.history.push(menuItem.paths);
      }
    }
  }, [permission]);

  // function AddZeroFormatter(value) {
  //   let sp = value.toString().split(".");
  //   let inital = parseFloat(sp[0]) ? parseFloat(sp[0]) : 0;
  //   let end = parseFloat(sp[1]) ? parseFloat(sp[1]) : 0;

  //   if (parseFloat(sp[1]) < 10) {
  //     end = "0" + parseFloat(sp[1]);
  //   }
  //   return parseFloat(inital + "." + end);
  // }
  function AddZeroFormatter(value){
  let sp =value.toString().split('.');
  let inital=parseFloat(sp[0])?parseFloat(sp[0]):0;
  let end= parseFloat(sp[1])?parseFloat(sp[1]):0;
  
  if(parseFloat(sp[1]) < 10 && sp[1].length == 2){
    end ='0'+ parseFloat(sp[1]);
  }else{
    end = parseFloat(sp[1])+'0';
  }
  return parseFloat(inital+"."+end);
  // return parseFloat(value.toFixed(2));
  
}



  useEffect(() => {
    if (indexTitle == 1) {
      changeBreakTime(active);
    }
  }, [selectedDate, chartDate]);

  function changeBreakTime(type) {
    SetError("");
    let arr = [];
    let dateAr = [];
    let requestBody = {};

    setChartLoader(true);
    requestBody.start_date = getDateFormat(selectedDate);
    requestBody.end_date = getDateFormat(selectedDate);

    if (type == "today") {
      requestBody.start_date = getDateFormat(selectedDate);
      requestBody.end_date = getDateFormat(selectedDate);
    } else if (type == "week") {
      requestBody.start_date = getDateFormat(
        moment(chartDate).subtract(7, "days")
      );
    } else if (type == "1 month") {
      requestBody.start_date = getDateFormat(
        moment(chartDate).subtract(28, "days")
      );
    } else if (type == "2 month") {
      requestBody.start_date = getDateFormat(
        moment(chartDate).subtract(60, "days")
      );
    }

    requestBody.treshold = 30;

    requestBody.slug = "employee";

    getBreakTimeReport(requestBody, userSession, org_id, sub_org_id).then(
      (res) => {
        SetLoader(false);
        let maxHourVal = 0;

        if (res.status == 200) {
          let element = res.data;

          Object.keys(element).forEach((key) => {
            dateAr.push(key);

            Object.keys(element[key]).forEach((key2) => {
              let singleDateArray = {};
              //   let empdetail= emp.find(item=>item.tag_serial == key2);

              let empSpecific = element[key][key2];
              // object for hour
              let hourVal = [];
              singleDateArray.date = key;
              singleDateArray.tag_serial = key2;

              Object.keys(empSpecific).forEach((key3) => {
                if (key3 !== "shift_end_time" && key3 !== "shift_start_time") {
                  let hour = 1;
                  hourVal.push(
                    ...Object.keys(empSpecific[key3]).map(
                      (key4) => empSpecific[key3][key4]
                    )
                  );
                } else {
                  var local = moment
                    .utc(empSpecific["shift_start_time"])
                    .format("HH:mm");
                  var local2 = moment
                    .utc(empSpecific["shift_end_time"])
                    .format("HH:mm");

                  singleDateArray.shift = local + " - " + local2;
                }
                if (hourVal.length > maxHourVal) {
                  maxHourVal = hourVal.length;
                }
              });

              for (let i = 0; i < 24; i++) {
                singleDateArray["hour_" + (i + 1)] = hourVal[i]
                  ? hourVal[i]
                  : "No";
              }
              arr.push(singleDateArray);
            });
          });

          requestBody.slug = "daily_worker";
          getBreakTimeReport(requestBody, userSession, org_id, sub_org_id).then(
            (res2) => {
              let element = res.data;

              Object.keys(element).forEach((key) => {
                // dateAr.push(key);

                Object.keys(element[key]).forEach((key2) => {
                  let singleDateArray = {};
                  let empSpecific = element[key][key2];
                  // object for hour
                  let hourVal = [];
                  singleDateArray.date = key;
                  singleDateArray.tag_serial = key2;

                  Object.keys(empSpecific).forEach((key3) => {
                    if (
                      key3 !== "shift_end_time" &&
                      key3 !== "shift_start_time"
                    ) {
                      let hour = 1;
                      hourVal.push(
                        ...Object.keys(empSpecific[key3]).map(
                          (key4) => empSpecific[key3][key4]
                        )
                      );
                    } else {
                      var local = moment
                        .utc(empSpecific["shift_start_time"])
                        .format("HH:mm");
                      var local2 = moment
                        .utc(empSpecific["shift_end_time"])
                        .format("HH:mm");

                      singleDateArray.shift = local + " - " + local2;
                    }
                    if (hourVal.length > maxHourVal) {
                      maxHourVal = hourVal.length;
                    }
                  });

                  for (let i = 0; i < 24; i++) {
                    singleDateArray["hour_" + (i + 1)] = hourVal[i]
                      ? hourVal[i]
                      : "No";
                  }

                  arr.push(singleDateArray);
                });
              });
            }
          );

          SetMaxHour(maxHourVal);
          SetDateList(dateAr);
          let empList = [];

          setChartLoader(false);

          let categories = [];
          let color = ["#ef5e8c", "#32325d", "#ecf2f9"];
          let series = [];

          for (let i = 1; i <= maxHourVal; i++) {
            categories.push("Hour " + i);

            series.push({
              y:
                (arr.filter((item) => item["hour_" + i].includes("Yes"))
                  .length *
                  100) /
                arr.length,
              name: "Break Time",
              color: "#dedee3",
              hour: i,
            });
          }
          let sort = series.sort(function (a, b) {
            return b.y - a.y;
          });

          if (series.length > 0) {
            series[0].color = "#90cf4e";
          }
          if (series.length > 2) {
            series[1].color = "#a7d872";
          }
          if (series.length > 3) {
            series[2].color = "#ace084";
          }
          series.sort(function (a, b) {
            return a.hour - b.hour;
          });

          setChartDataBreakTime({ series: series, categories: categories });
          SetError("");
        } else {
          // SetError("Some Issue Occured, Please Try after some time")
          setChartDataBreakTime({ categories: [], series: [] });
          setChartLoader(false);
                   
        }
      }
    );

    SetActive(type);
  }

  function slugify(string) {
    return string
      .toString()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/g, "_");
  }

  async function changeDataofCycleCount(type, selectedDate,facilityActive) {
    SetError("");
    setChartLoader(true);
    SetActive(type);
    let res={};






    if (type == "day" || type == "today") {
      let requestBody = {
        start_date: moment(chartDate).format("YYYY-MM-DD"),
        end_date: moment(chartDate).format("YYYY-MM-DD"),
      };

      if (type.toLowerCase().trim() == "today") {
        // cycleCountRefresh(moment().format('YYYY-MM-DD'))
        requestBody = {
          start_date: moment(chartDate).format("YYYY-MM-DD"),
          end_date: moment(chartDate).format("YYYY-MM-DD"),
        };
      } else {
      }

      let planned = [];
      let actual = [];
      let cat = [];
      let array = [];
      let y = [];
      let data =[]


      if(facilityActive==='function'){
        setChartLoader(true);

        res= await getFunctionCycleCountPlan(userSession,org_id,sub_org_id,requestBody.start_date,requestBody.end_date).then((res)=>{ return res});
        
         cycleCountPlanFunction(res,type);
      }
      else if(facilityActive == "process" || facilityActive == "process2"){
        let version =1;
        if(facilityActive === "process2"){
          version = 2;
        }

        res= await getProcessCycleCountPlan(userSession,org_id,sub_org_id,requestBody.start_date,requestBody.end_date,version).then((res)=>{ return res});
        let process = await getProcessList(userSession,org_id,sub_org_id,requestBody.start_date,requestBody.end_date).then((res)=>{ return res.data});
        let catResp=  await getCycleCountData(requestBody, userSession, org_id, sub_org_id).then((res)=>{ return res});
       
        cycleCountProcess(res,type,process,catResp,version)
      }
      else{
        res = await getCycleCountData(requestBody, userSession, org_id, sub_org_id).then((res)=>{ return res});
        data = [...categoriesList];
       

        // let res= dummy
          
          SetCatActive("all");
          let inc = 0;
          Object.keys(res.data).forEach((key) => {
            //key == date
            let date = key;
            if (inc == 0) {
              // SetChartDate((prev) => ({ ...prev, ['start']: key }));
            } else {
              //SetChartDate((prev) => ({ ...prev, ['end']: key }));
            }
            inc++;

            Object.keys(res.data[key]).forEach((key2) => {
              //key == hour
              let ar = {};
              ar.date = key;
              ar.time = key2;

              for (let i = 0; i < categoriesList.length; i++) {

                //categorlist check  if any category exist
                if (res.data[key][key2][categoriesList[i].name]) {
                  ar[`${slugify(categoriesList[i].name)}_actual`] = res.data[
                    key
                  ][key2][categoriesList[i].name].cycle_count
                    ? res.data[key][key2][categoriesList[i].name].cycle_count
                    : 0;
                  ar[`${slugify(categoriesList[i].name)}_planned`] = res.data[
                    key
                  ][key2][categoriesList[i].name].planned_count
                    ? res.data[key][key2][categoriesList[i].name].planned_count
                    : 0;
                } else {
                  ar[`${slugify(categoriesList[i].name)}_actual`] = 0;
                  ar[`${slugify(categoriesList[i].name)}_planned`] = 0;
                }
              }
              array.push(ar);
            });
          });

          let series = [];
          cat = headerRow2Disp;

          for (let i = 0; i < categoriesList.length; i++) {
            let dtData = [];
            let dtData2 = [];

            for (let j = 0; j < headerRow2Disp.length; j++) {
              let index = converSionHeaderRow2Disp.indexOf(headerRow2Disp[j]);
 
              let dt = array.find((item) => parseFloat(item.time) == parseFloat(categories2Hour[j]));
              if (dt) {
                dtData.push(dt[`${slugify(categoriesList[i].name)}_actual`]);
                dtData2.push(dt[`${slugify(categoriesList[i].name)}_planned`]);
              } else {
                dtData.push(0);
                dtData2.push(0);
              }
            }
            series.push({
              type: "spline",
              name: "Actual",
              cat: categoriesList[i].name,
              data: dtData,
              color: "#ef5e8c",
              lineWidth: 5,
            });
            series.push({
              type: "spline",
              name: "Planned",
              cat: categoriesList[i].name,
              data: dtData2,
              color: "grey",
            });
          }

          // setChartDataCycleCount({categories:cat,series:series,yAxis:y})
          setTimeout(function () {
            generateHighChart(
              { categories: cat, series: series, yAxis: y },
              cycle_count,
              type,
              selectedDate,
              facilityActive
            );
          }, 3000);

          setChartLoader(false);
        }
        // });
    } else {
      let requestBody = {
        start_date: moment(chartDate).subtract(7, "days").format("YYYY-MM-DD"),
        end_date: moment(chartDate).format("YYYY-MM-DD"),
      };
      
      if (type.toLowerCase().trim() == "month") {
        requestBody = {
          start_date: moment(chartDate)
            .subtract(28, "days")
            .format("YYYY-MM-DD"),
          end_date: moment(chartDate).format("YYYY-MM-DD"),
        };
      }

      let planned = [];
      let actual = [];
      let cat = [];
      let array = [];
      let y = [];

      if(facilityActive==='function'){
        setChartLoader(true);
        res= await getFunctionCycleCountPlan(userSession,org_id,sub_org_id,requestBody.start_date,requestBody.end_date).then((res)=>{ return res});        
         cycleCountPlanFunction(res,type);


      }
      else if(facilityActive == "process" || facilityActive == "process2"){
        let version =1;
        if(facilityActive === "process2"){
          version = 2;
        }

        res= await getProcessCycleCountPlan(userSession,org_id,sub_org_id,requestBody.start_date,requestBody.end_date).then((res)=>{ return res});
        let process = await getProcessList(userSession,org_id,sub_org_id,requestBody.start_date,requestBody.end_date).then((res)=>{ return res.data});
        let catResp =await getCycleCountData(requestBody, userSession, org_id, sub_org_id).then(res=>res)
        cycleCountProcess(res,type,process,catResp)
      }
      else{

      getCycleCountData(requestBody, userSession, org_id, sub_org_id).then((res) => {
      //  let res= dummy
          let data = [...categoriesList];
          SetCatActive("all");

          Object.keys(res.data).forEach((key) => {
            //key == date
            let date = key;

            Object.keys(res.data[key]).forEach((key2) => {
              //key == hour
              let ar = {};
              ar.date = moment(key).set({
                hour: key2,
                minute: 0,
                second: 0,
                millisecond: 0,
              });

              ar.time = key2;

              for (let i = 0; i < categoriesList.length; i++) {
                //categorlist check  if any category exist
                if (res.data[key][key2][categoriesList[i].name]) {
                  ar[`${slugify(categoriesList[i].name)}_actual`] = res.data[
                    key
                  ][key2][categoriesList[i].name].cycle_count
                    ? res.data[key][key2][categoriesList[i].name].cycle_count
                    : 0;
                  ar[`${slugify(categoriesList[i].name)}_planned`] = res.data[
                    key
                  ][key2][categoriesList[i].name].planned_count
                    ? res.data[key][key2][categoriesList[i].name].planned_count
                    : 0;
                } else {
                  ar[`${slugify(categoriesList[i].name)}_actual`] = 0;
                  ar[`${slugify(categoriesList[i].name)}_planned`] = 0;
                }
              }
              array.push(ar);
            });
          });

          let series = [];
          array = array.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });

          let seriesData = [];
          let AcSeries = [];
          for (let i = 0; i < categoriesList.length; i++) {
            let dtData = [];
            let dtData2 = [];
            //loop series for actual
            for (let j = 0; j < headerRow2Disp.length; j++) {
              let index = converSionHeaderRow2Disp.indexOf(headerRow2Disp[j]);

              let dtActual = array.filter(
                (item) =>
                  parseFloat(item.time) == parseFloat(categories2Hour[j]) &&
                  item[`${slugify(categoriesList[i].name)}_actual`] > 0
              );
              
              if (dtActual.length > 0) {
                dtData.push(
                  Math.ceil(
                    dtActual.reduce(
                      (prev, current) =>
                        prev +
                        current[`${slugify(categoriesList[i].name)}_actual`],
                      0
                    ) / dtActual.length
                  )
                );
              } else {
                dtData.push(0);
              }

              let dtPlanned = array.filter(
                (item) =>
                  parseFloat(item.time) == index &&
                  item[`${slugify(categoriesList[i].name)}_planned`] > 0
              );
              if (dtPlanned.length > 0) {
                dtData2.push(
                  Math.ceil(
                    dtPlanned.reduce(
                      (prev, current) =>
                        prev +
                        current[`${slugify(categoriesList[i].name)}_planned`],
                      0
                    ) / dtPlanned.length
                  )
                );
              } else {
                dtData2.push(0);
              }
            }
            series.push({
              type: "spline",
              cat: categoriesList[i].name,
              name: "Actual",
              data: dtData,
              color: "#ef5e8c",
              lineWidth: 5,
            });
            series.push({
              type: "spline",
              cat: categoriesList[i].name,
              name: "Planned",
              data: dtData2,
              color: "grey",
            });
          }
          cat = headerRow2Disp;

          // setChartDataCycleCount({categories:cat,series:series,yAxis:y})
          setTimeout(function () {
            generateHighChart(
              { categories: cat, series: series, yAxis: y },
              cycle_count,
              type,
              selectedDate,
              facilityActive
            );
          }, 3000);

          setChartLoader(false);

        });
      }
    }
  }

async function  cycleCountPlanFunction(res,type){
    // let res = DummyFacilityPlan
    let data = [...functionList];
    let cat=[];
    let array=[];
    let planned = [];
    let actual = [];
    let y = [];

        // let res= dummy
          
          SetCatActive("all");
          let inc = 0;
          if(res.status==200){

        
          Object.keys(res.data[0]).forEach((key) => {
            //key == date
            let date = key;
            if (inc == 0) {
              // SetChartDate((prev) => ({ ...prev, ['start']: key }));
            } else {
              //SetChartDate((prev) => ({ ...prev, ['end']: key }));
            }
            inc++;

            Object.keys(res.data[0][key]).forEach((key2) => {
              //key == hour
              let ar = {};
              ar.date = key;
              ar.time = key2;

              for (let i = 0; i < functionList.length; i++) {

                //categorlist check  if any category exist

                if (res.data[0][key][key2][functionList[i].category_name]) {
                 
                  ar[`${slugify(functionList[i].category_name)}_actual`] = res.data[0][key][key2][functionList[i].category_name].active ? res.data[0][key][key2][functionList[i].category_name].active: 0;
                  ar[`${slugify(functionList[i].category_name)}_planned`] = res.data[0][
                    key
                  ][key2][functionList[i].category_name].plan
                    ? res.data[0][key][key2][functionList[i].category_name].plan
                    : 0;
                } else {
                  ar[`${slugify(functionList[i].category_name)}_actual`] = 0;
                  ar[`${slugify(functionList[i].category_name)}_planned`] = 0;
                }
              }
              array.push(ar);
            });
          });

          let series = [];
          cat = headerRow2Disp;

          for (let i = 0; i < functionList.length; i++) {
            let dtData = [];
            let dtData2 = [];

            for (let j = 0; j < headerRow2Disp.length; j++) {
              let index = converSionHeaderRow2Disp.indexOf(headerRow2Disp[j]);
 
              let dt = array.find((item) => parseFloat(item.time) == parseFloat(categories2Hour[j]));
              if (dt) {
                dtData.push(dt[`${slugify(functionList[i].category_name)}_actual`]);
                dtData2.push(dt[`${slugify(functionList[i].category_name)}_planned`]);
              } else {
                dtData.push(0);
                dtData2.push(0);
              }
            }
            series.push({
              type: "spline",
              name: "Actual",
              cat: functionList[i].category_name,
              data: dtData,
              color: "#ef5e8c",
              lineWidth: 5,
            });
            series.push({
              type: "spline",
              name: "Planned",
              cat: functionList[i].category_name,
              data: dtData2,
              color: "grey",
            });
          }

          // setChartDataCycleCount({categories:cat,series:series,yAxis:y})
          setTimeout(function () {
            generateHighChart(
              { categories: cat, series: series, yAxis: y },
              active,
              type,
              selectedDate,
              facilityActive
            );
          }, 3000);
           setTimeout(function () { 

          setChartLoader(false);  
           },1000);
          }else{
            setChartLoader(false);  
          }
}

async function  cycleCountProcess(res,type,process,catResp,version){
    // let res = DummyFacilityPlan
    let data = [...categoriesList];
    let cat=[];
    let array=[];
    let planned = [];
    let actual = [];
    let y = [];
    

        // let res= dummy

          SetCatActive("all");

if(type=="today"){
          let inc = 0;
          Object.keys(res.data).forEach((key) => {
            //key == date
            let date = key;
            if (inc == 0) {
              // SetChartDate((prev) => ({ ...prev, ['start']: key }));
            } else {
              //SetChartDate((prev) => ({ ...prev, ['end']: key }));
            }
            
            inc++;

            Object.keys(res.data[key]).forEach((key2) => {
              //key == hour
              let ar = {};
              ar.date = key;
              ar.time = key2;

              for (let i = 0; i < categoriesList.length; i++) {

                //categorlist check  if any category exist
                if (res.data[key][key2][categoriesList[i].name]) {

                  //here is logical
                  for(let j=0 ;j<process.length;j++){
                    ar[`${slugify(categoriesList[i].name)}_${process[j].process_name}`] = res.data[key][key2][categoriesList[i].name][process[j].process_name]
                    ? res.data[key][key2][categoriesList[i].name][process[j].process_name]
                    : 0;
                  }
                  if(version == 2){
                    
                    ar[`${slugify(categoriesList[i].name)}_actual`] = res.data[key][key2][categoriesList[i].name].actual;
                  }
                  
                }else {
                  for(let j=0 ;j<process.length;j++){
                    ar[`${slugify(categoriesList[i].name)}_${process[j].process_name}`] = 0;
                  }
                  if(version == 2){
                    ar[`${slugify(categoriesList[i].name)}_actual`] = 0;
                  }
                }
                if(version===1){
                
                  if (catResp.data[key][key2][categoriesList[i].name] ) {
                    ar[`${slugify(categoriesList[i].name)}_actual`] = catResp.data[key][key2][categoriesList[i].name].cycle_count? catResp.data[key][key2][categoriesList[i].name].cycle_count: 0;
                  } else {
                    ar[`${slugify(categoriesList[i].name)}_actual`] = 0;
                  }

                }
                
              }

              array.push(ar);
            });
          });

          let series = [];
          cat = headerRow2Disp;


          for (let i = 0; i < categoriesList.length; i++) {
            
            
            for(let k=0;k<process.length;k++){
              let dtData = [];
            

              
            for (let j = 0; j < headerRow2Disp.length; j++) {
              let index = converSionHeaderRow2Disp.indexOf(headerRow2Disp[j]); 


              let dt = array.find((item) => parseFloat(item.time) == parseFloat(categories2Hour[j]));
              if (dt) {
                  dtData.push(dt[`${slugify(categoriesList[i].name)}_${process[k].process_name}`]);
                }else {
                dtData.push(0);
              }
              }
            let single ={
              type: "spline",
              name: process[k].process_name,
              cat: categoriesList[i].name,
              data: dtData,
              lineWidth:3
            };
            series.push(single);
          }

            let dtData2 = [];
            for (let j = 0; j < headerRow2Disp.length; j++) {
              let index = converSionHeaderRow2Disp.indexOf(headerRow2Disp[j]);
 
              let dt = array.find((item) => parseFloat(item.time) == parseFloat(categories2Hour[j]));
              if (dt) {
                dtData2.push(dt[`${slugify(categoriesList[i].name)}_actual`]);
               
              } else {
                dtData2.push(0);
              }
          }
          let single ={
              type: "spline",
              name: "Actual",
              cat: categoriesList[i].name,
              data: dtData2,
              color: "#ef5e8c",
              lineWidth: 4,
            };
            series.push(single);
          }
          
          SetChartProcess({ categories: cat, series: series, yAxis: y })
          setTimeout(function () {
            generateHighChart(
              { categories: cat, series: series, yAxis: y },
              cycle_count,
              type,
              selectedDate,
              facilityActive
            );
          }, 3000);

          setChartLoader(false);

          
        }
        else{


                    let data = [...categoriesList];
          SetCatActive("all");

          Object.keys(res.data).forEach((key) => {
            //key == date
            let date = key;

            Object.keys(res.data[key]).forEach((key2) => {
              //key == hour
              let ar = {};
              ar.date = moment(key).set({
                hour: key2,
                minute: 0,
                second: 0,
                millisecond: 0,
              });

              ar.time = key2;

              for (let i = 0; i < categoriesList.length; i++) {


                if (res.data[key][key2][categoriesList[i].name]) {
                  
                  for(let j=0 ;j<process.length;j++){
                    ar[`${slugify(categoriesList[i].name)}_${process[j].process_name}`] = res.data[key][key2][categoriesList[i].name][process[j].process_name]
                    ? res.data[key][key2][categoriesList[i].name][process[j].process_name]
                    : 0;
                  }

                } else {
                  for(let j=0 ;j<process.length;j++){
                    ar[`${slugify(categoriesList[i].name)}_${process[j].process_name}`] = 0;
                  }
                }

                if (catResp.data[key][key2][categoriesList[i].name]) {
                  ar[`${slugify(categoriesList[i].name)}_actual`] = catResp.data[key][key2][categoriesList[i].name].cycle_count? catResp.data[key][key2][categoriesList[i].name].cycle_count: 0;
                } else {
                  ar[`${slugify(categoriesList[i].name)}_actual`] = 0;
                }

              }
              array.push(ar);
            });
          });

          let series = [];
          array = array.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });

          let seriesData = [];
          let AcSeries = [];
          for (let i = 0; i < categoriesList.length; i++) {

            let dtData2 =[]
            for(let k=0;k<process.length;k++){
              let dtData = [];

            for (let j = 0; j < headerRow2Disp.length; j++) {
              let index = converSionHeaderRow2Disp.indexOf(headerRow2Disp[j]);

              let dtActual = array.filter(
                (item) =>
                  parseFloat(item.time) == parseFloat(categories2Hour[j]) &&
                  item[`${slugify(categoriesList[i].name)}_${process[k].process_name}`] > 0
              );

             
              
              if (dtActual.length > 0) {
                dtData.push(
                  Math.ceil(
                    dtActual.reduce(
                      (prev, current) =>
                        prev +
                        current[`${slugify(categoriesList[i].name)}_${process[k].process_name}`],
                      0
                    ) / dtActual.length
                  )
                );
              } else {
                dtData.push(0);
              }

              
            }
            let single={
              type: "spline",
              cat: categoriesList[i].name,
              name: process[k].process_name,
              data: dtData,
              lineWidth:3
            }
            series.push(single);
            
          }
          cat = headerRow2Disp;
          for (let j = 0; j < headerRow2Disp.length; j++) {
              let index = converSionHeaderRow2Disp.indexOf(headerRow2Disp[j]);

              let dtActual = array.filter((item) =>parseFloat(item.time) == parseFloat(categories2Hour[j]) &&item[`${slugify(categoriesList[i].name)}_actual`] > 0);
              
              if (dtActual.length > 0) {
                dtData2.push(Math.ceil(dtActual.reduce((prev, current) =>prev +current[`${slugify(categoriesList[i].name)}_actual`],0) / dtActual.length));
              } else {
                dtData2.push(0);
              }
            }
          let single ={
              type: "spline",
              name: "Actual",
              cat: categoriesList[i].name,
              data: dtData2,
              color: "#ef5e8c",
              lineWidth: 4,
            };
            series.push(single);

          }


         
          // setChartDataCycleCount({categories:cat,series:series,yAxis:y})

          SetChartProcess({ categories: cat, series: series, yAxis: y })
          setTimeout(function () {
            generateHighChart(
              { categories: headerRow2Disp, series: series, yAxis: y },
              cycle_count,
              type,
              selectedDate,
              facilityActive
            );
          }, 3000);

          setChartLoader(false); 
        }
     


}
// const API_KEY = 'sk-proj-dAnt2IDbAestNCvG56vJT3BlbkFJbAtNn2IbMnVBbUKHv4Fe';

// const api = axios.create({
//   baseURL: 'https://api.openai.com/v1',
//   headers: {
//     'Content-Type': 'application/json',
//     'Authorization': `Bearer ${API_KEY}`
//   }
// });

//  const getChatResponse = async (message =  '',i) => {
//   try {
//     const response = await api.post('/chat/completions', {
//       model: 'gpt-3.5-turbo',
//       messages: [{ role: 'user', content: message }],
//       max_tokens: 600
//     });
//     
//     return response.data.choices[0].message.content;
//   } catch (error) {
//     console.error('Error fetching chat response:', error);
//     return 'An error occurred. Please try again.';
//   }
// };
// let respppp ={
//   "data": {
//     "week 18": {
//       "absent": 114, 
//       "absent_percentage": 16.96, 
//       "check_in_late": 133, 
//       "check_in_late_percentage": 23.84, 
//       "check_in_ontime": 212, 
//       "check_in_ontime_percentage": 37.99, 
//       "employees_non_checkout_count": 115, 
//       "employees_non_checkout_count_percentage": 20.03, 
//       "shift_changes": 213, 
//       "shift_changes_percentage": 38.17, 
//       "total_actual_count": 574, 
//       "total_count": 688, 
//       "total_over_time": "152:40:12", 
//       "total_overtime_cost": 7216350, 
//       "total_planned_count": 672, 
//       "total_under_time": "191:32:7", 
//       "unexpected_check_in": 16, 
//       "unexpected_check_in_percentage": 2.79
//     }, 
//     "week 19": {
//       "absent": 228, 
//       "absent_percentage": 22.09, 
//       "check_in_late": 200, 
//       "check_in_late_percentage": 24.88, 
//       "check_in_ontime": 356, 
//       "check_in_ontime_percentage": 44.28, 
//       "employees_non_checkout_count": 175, 
//       "employees_non_checkout_count_percentage": 21.14, 
//       "shift_changes": 248, 
//       "shift_changes_percentage": 30.85, 
//       "total_actual_count": 828, 
//       "total_count": 1056, 
//       "total_over_time": "222:25:27", 
//       "total_overtime_cost": 10115700, 
//       "total_planned_count": 1032, 
//       "total_under_time": "251:50:52", 
//       "unexpected_check_in": 24, 
//       "unexpected_check_in_percentage": 2.9
//     }, 
//     "week 20": {
//       "absent": 250, 
//       "absent_percentage": 23.02, 
//       "check_in_late": 221, 
//       "check_in_late_percentage": 26.44, 
//       "check_in_ontime": 336, 
//       "check_in_ontime_percentage": 40.19, 
//       "employees_non_checkout_count": 184, 
//       "employees_non_checkout_count_percentage": 21.4, 
//       "shift_changes": 279, 
//       "shift_changes_percentage": 33.37, 
//       "total_actual_count": 860, 
//       "total_count": 1110, 
//       "total_over_time": "234:57:36", 
//       "total_overtime_cost": 10347268, 
//       "total_planned_count": 1086, 
//       "total_under_time": "227:58:3", 
//       "unexpected_check_in": 24, 
//       "unexpected_check_in_percentage": 2.79
//     }, 
//     "week 21": {
//       "absent": 272, 
//       "absent_percentage": 25.98, 
//       "check_in_late": 170, 
//       "check_in_late_percentage": 21.94, 
//       "check_in_ontime": 319, 
//       "check_in_ontime_percentage": 41.16, 
//       "employees_non_checkout_count": 198, 
//       "employees_non_checkout_count_percentage": 24.66, 
//       "shift_changes": 286, 
//       "shift_changes_percentage": 36.9, 
//       "total_actual_count": 803, 
//       "total_count": 1075, 
//       "total_over_time": "248:24:42", 
//       "total_overtime_cost": 12380808, 
//       "total_planned_count": 1047, 
//       "total_under_time": "187:17:21", 
//       "unexpected_check_in": 28, 
//       "unexpected_check_in_percentage": 3.49
//     }, 
//     "week 22": {
//       "absent": 197, 
//       "absent_percentage": 23.76, 
//       "check_in_late": 153, 
//       "check_in_late_percentage": 24.21, 
//       "check_in_ontime": 263, 
//       "check_in_ontime_percentage": 41.61, 
//       "employees_non_checkout_count": 169, 
//       "employees_non_checkout_count_percentage": 26.12, 
//       "shift_changes": 216, 
//       "shift_changes_percentage": 34.18, 
//       "total_actual_count": 647, 
//       "total_count": 844, 
//       "total_over_time": "254:48:51", 
//       "total_overtime_cost": 17351104, 
//       "total_planned_count": 829, 
//       "total_under_time": "84:57:42", 
//       "unexpected_check_in": 15, 
//       "unexpected_check_in_percentage": 2.32
//     }
//   }, 
//   "message": "Success", 
//   "status": 200
// }




useEffect(()=>{

  // getChatResponse('Hi gpt, this is performance data of May 1 to May 31 \n data below'+JSON.stringify(respppp.performance_averages)+"M Stand For Night Shift, S STand For Afternoon, P Stand for Morning shift please share as html list don't include heading like Based on the provided data or somekind of thing, Now i need What is your  observations on data?")
//Report UseCase High Performances (table 1)
  // getChatResponse('Hi gpt, this is performance data of organisation from May 1 to May 31  data below'+JSON.stringify(respppp.data.performance_averages)+"defination of M:Night Shift, S:Afternoon shift, P:Morning shift. please share result as html list only takeways point. So, What are takeaways from data?",'1')
//Report UseCase Low Performances (table 2)
  // getChatResponse('Hi gpt, this is data of % or count of Low performing workers in the organisation from May 1 to May 31  data below'+JSON.stringify(respppp.data.low_performance)+"defination of M:Night Shift, S:Afternoon shift, P:Morning shift. please share result as html list only takeways point. So, What are takeaways from data?","2")


  //UseCase Attendance table 3
  //getChatResponse('Hi gpt, this is data of attendace behaviour of workers in the organisation from May 1 to May 31  data below'+JSON.stringify(respppp.data)+"please share result as html list only takeways point. So, What are takeaway trends?","3") 

//Report UseCase Process Performances (table 6)
// getChatResponse('Hi gpt, this is the Process performance data in the organisation from May 1 to May 31  data below'+JSON.stringify(respppp.data.performance_averages )+ "please share result as html list only takeways point. So, What are takeaways from data?",'6')

//Report UseCase Low Perfromance in time (table 5) (tree)
// getChatResponse('Hi gpt, this is data in tree format on which time the organisation is having poor performance from May 1 to May 31  data below'+JSON.stringify(respppp.data.low_performance)+"defination of M:Night Shift, S:Afternoon shift, P:Morning shift. please share result as html list only takeways point. So, What is the recurring poor performance time and day of the month? ",'5')

},[])

  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);

  const getStatusCount = (date) => {
    let requestBody = {};
    requestBody.date = getDateFormat(date);
    if (permission && permission.length > 0) {
      getEmployeeList(requestBody, userSession, org_id, sub_org_id).then(
        (res) => {
          if (res.status === 200) {
            updateStatusCount((prev) => ({
              ...prev,
              personal: res.count || 0,
            }));
            let totalEmployeeTagCount = res.count ? res.count : 0;
            localStorage.setItem("totalEmployeeTag", totalEmployeeTagCount);
          }
        }
      );

      getSiteLocations(requestBody, userSession, org_id, sub_org_id).then(
        (res) => {
          if (res.status === 200) {
            updateStatusCount((prev) => ({
              ...prev,
              location:
                res.data && res.data.location_count
                  ? res.data.location_count
                  : 0,
            }));

            let totalSiteLocationCount = res.data.location_count
              ? res.data.location_count
              : 0;
            localStorage.setItem("totalSiteLocation", totalSiteLocationCount);
          }
        }
      );
    }
  };

  const getBarColor = (val) => {
    if (val < 33) {
      return riskLevelColor.low;
    } else if (val < 66) {
      return riskLevelColor.medium;
    } else {
      return riskLevelColor.high;
    }
  };

  function handleIndexTabClick(index) {
    let arr = [...indexTitleArray];

    for (let indexVal = 0; indexVal < arr.length; indexVal++) {
      const element = arr[indexVal];

      if (indexVal == index) {
        arr[indexVal].isSelected = true;
      } else {
        arr[indexVal].isSelected = false;
      }
    }
    updateIndexTitle(index);
  }

  const handleMouseEnter = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "block";
    }
  };

  const handleMouseLeave = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "none";
    }
  };
  function getLevel(id) {
    return indexLevel[id] ? indexLevel[id].toLowerCase() : "";
  }
  const getColorBasedOnCondition = (value, index,checkedInArray,facilityInarray) => {
  const firstSeriesValue = checkedInArray[index];
 
  return value > firstSeriesValue ? 'green' : 'red';
};

function facilityPlanFormatter(res2){
let facility_plan = {};
  Object.keys(res2.data.facility_plan).forEach((key)=>{
    

        Object.keys(res2.data.facility_plan[key]).forEach((key2)=>{
          facility_plan[key2]={};
           
          res2.data.facility_plan[key][key2].forEach(item => {
                    const keyObj = Object.keys(item)[0]; // Extract the key
                    const value = item[keyObj]; // Extract the value

                    facility_plan[key2][keyObj] = value;
            });
          
        })
  });
  return facility_plan
}


 async function changeDataFacilityCounter(type,date){


    SetActive(type)
    let start_date= moment(chartDate).format('YYYY-MM-DD');
    let end_date =  moment(chartDate).format('YYYY-MM-DD');
    let infacilityWithTime =[]
    let checkedInWithTime =[];
      let cat = headerRow2Disp;
      let checkedInArray=[];
      let faciliyInArray=[];   
      let facilityPlanArray =[]
          let series =[];

    if(type=="week"){
     start_date= moment(chartDate).subtract(7,'days').format('YYYY-MM-DD');
     end_date =  moment(chartDate).format('YYYY-MM-DD');

    }else if(type=="month"){
     start_date= moment(chartDate).subtract(30,'days').format('YYYY-MM-DD');
     end_date =  moment(chartDate).format('YYYY-MM-DD');

    }
    
setChartLoader(true)
if(departmentID!="" && subDepartmentID!=""){
  submitHandlerFacility(departmentID,subDepartmentID,type)
}else if(departmentID!=""){
   submitHandlerFacility(departmentID,subDepartmentID,type)
}else{
  
  let res2 = await getFacilityCounterAttendance(userSession,org_id,sub_org_id,start_date)

  let facility_plan= facilityPlanFormatter(res2);  

  //i need to merge all key array object into one date.
  



    getFacilityCounter(userSession,org_id,sub_org_id,start_date,end_date).then(res=>{
      
      let conversionData = []
      if(res.status==200){
    
      Object.keys(res.data).forEach((key) => {
        let date= moment(key).format('YYYY-MM-DD')
        let time = moment(key).format('HH');
        let singleDt={
          date:moment(key).format('YYYY-MM-DD'),
          hour:moment(key).format('HH'),
          datefull:key,
          facility_count:res.data[key].facility_count,
          total_count:res.data[key].total_count,
          deviation_percent:res.data[key].deviation_percent,
          facility_plan: facility_plan[date] && facility_plan[date][time]?facility_plan[date][time]: 0
        }
        conversionData.push(singleDt);
      });
    

      for (let i = 0; i < headerRow2Disp.length; i++) {
        let index = converSionHeaderRow2Disp.indexOf(headerRow2Disp[i]);
        let dt = conversionData.filter(item=>parseFloat(item.hour) == parseFloat(categories2Hour[i]));
        
        if(dt.length> 0){
          faciliyInArray.push({name:"In-facility", y:Math.round(dt.reduce((prev,current)=>prev + current.facility_count,0)/dt.length),magicRatio:Math.round(dt.reduce((prev,current)=>prev + current.deviation_percent,0)/dt.length)});
          checkedInArray.push({name:"Checked-In",y:Math.round( dt.reduce((prev,current)=>prev + current.total_count,0)/dt.length),magicRatio:Math.round(dt.reduce((prev,current)=>prev + current.deviation_percent,0)/dt.length)});
          facilityPlanArray.push({name:"Facility Plan",y:dt.reduce((prev,current)=>prev + current.facility_plan,0)})
         
        }else{
            faciliyInArray.push({name:"In-facility",y:0,magicRatio:0});
            checkedInArray.push({name:"Checked-In",y:0,magicRatio:0});
            facilityPlanArray.push({name:"Facility Plan",y:0})
        }
      }

    series.push({name:"Checked-In",data:checkedInArray,color:'grey'})
    series.push({name:"In-Facility",data:faciliyInArray,lineWidth: 5,color: "#ef5e8c",})
    series.push({name:"Facility Plan",data:facilityPlanArray,lineWidth: 5,color:"#62eba4",})
   
    setChartDataFacility({series:series,categories:cat});
    setChartLoader(false)
       
        }else{
          setChartDataFacility({series:[],categories:[]});
          setChartLoader(false);
       
        }
    });
  }
    

    // if(type=='today'){
    //   cat = headerRow2Disp;
    //   for(let i= 0;i<24;i++) {
    //     let checkedINPr = GetRandomDigit(100,500)
    //     checkedInArray.push(checkedINPr);

    //     faciliyInArray.push(GetRandomDigit(50,checkedINPr));
    //   }
   


  }

  function showIndexTab(titleArray) {
    let arr = [];

    for (let index = 0; index < titleArray.length; index++) {
      const element = titleArray[index];
      let level = getLevel(element.id);

      arr.push(
        <div
          key={"indexTitle emsl" + element.title} id={element.id}
          className={
            "populationRiskMainDiv utilityPadding mb-3 spreadMobilityAreaIndexMainDiv " +
            (element.disabled ? "disabled" : "")
          }
          onClick={() => {
            if (!element.disabled) {
              handleIndexTabClick(index);
            }
            if (index == 0) {
              //changeDataofPerformance("today",comp);
              SetActive('today');
              loopHourlyChart(compareDate)

            } else if (index == 1) {
              changeBreakTime("today");
            } else if(index == 2) {
              changeDataofCycleCount("today", chartDate,facilityActive);
            }else{
              changeDataFacilityCounter('today',chartDate);
            }
          }}
          style={
            index === indexTitle
              ? {
                  color: riskLevelColor[`${level || "low"}`],
                  borderColor: riskLevelColor[`${level || "low"}`],
                  backgroundColor: riskBackgroundColor[`${level || "low"}`],
                }
              : {}
          }
        >
          <Row>
            <Col lg={8} className="p-r-0">
              <div className="indexBox">
                <div className="indexText">
                  {getTranslatedText(element.title)}
                  {element.desc ? (
                    <React.Fragment>

                                                                  <div className='helpSmallInfo iconSmallHelp' 
                                                onMouseEnter={() => handleMouseEnter(`desc_${element.id}`)}
                                                onMouseLeave={() => handleMouseLeave(`desc_${element.id}`)}
                                            >
                                                    <HelpIcon/>
                                            </div>
                      <div className="tooltipNewStyle" id={`desc_${element.id}`} style={{width:"300px",maxWidth:300,bottom:"30px"}}>
                        {element.desc}
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            </Col>
            <Col lg={1}>
              <div className="riskLevelMainDiv ">
                <div className="riskLevelTitleDiv">
                  {indexLevel[element.id] == "na"
                    ? "--"
                    : indexLevel[element.id]}
                </div>
              </div>
            </Col>
            <Col lg={2}>
              <div className="pinkArrowIconDiv">
                <img
                  src={
                    element.isSelected ? selectedPinkArrowIcon : pinkArrowIcon
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    }

    return arr;
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function handleDateSelect(date) {
    SetTopAPICall(true);
    if (permission && permission.length > 0) {
      
      updateSelectedDate(date);
      SetChartDate(date);
      SetCompareDate([date]);
      // updateEndChartDateValue(date)
    }
  }

  function handleSelectStartDate(date) {
    if (permission && permission.length > 0) {
      //updateStartDateValue(date)
    }
  }

  function handleSelectEndDate(date) {
    //date = new Date(date).setDate(date.getDate() + 1)
    if (permission && permission.length > 0) {
      // updateEndDateValue(date)

      let startDate = moment(dates.start);
      let endDate = moment(date);
      let isBefore = startDate.isBefore(endDate);

      if (isBefore) {
      } else {
        updateToastClass("errorToast");
        toast("End Date Should be Greater Than Start Date.", {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }
    }
  }

  function handleSelectChartStartDate(date) {
    if (permission && permission.length > 0) {
      // updateStartChartDateValue(date)
    }
  }

  function handleEmployeeClick() {
    props.history.push("/manpower-management/employee-list");
  }

  function changeLanguage(lang) {
    getLanguageTranslation(lang, userSession).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }

  function handleUpdateEmployeePopup(type) {
    updateEmployeePopupFlag(true);
    setModalType("threat");
  }

  function handleUpdateLocationPopup() {
    updateLocationPopupFlag(true);
    setModalType("threat");
  }

  function handleCloseModal() {
    updateEmployeePopupFlag(false);
    updateLocationPopupFlag(false);
    setModalType("");
  }
  function handleCloseModalChart() {
    SetChartModalOpen(false);
  }

  function capitalizeFirstLetter(string) {
    return string.toLowerCase();
  }

  const isWeekday = (date) => {

        let offWeek = props.weekDays.filter(item=>!item.working).map(el=>el.day);
        let holiday=props.holiday?props.holiday:[]
        const day = moment(date).isoWeekday();
        if(offWeek.indexOf(day) > -1 || holiday.find(item=>moment(item.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD') ) ){
            return     
        }else{
        return day ;
        }        
  };


  useEffect(()=>{
    
    if(indexTitle == 0 && active =="today" && headerRow2Disp.length > 0 && headerRow.length > 0){
      abc++;
      loopHourlyChart(compareDate);

    }
    
  },[selectedDate,headerRow2Disp,headerRow,categories2Hour])

  function  formatApiData  (apiData, startDate, endDate){
  let formattedData = [];

  // Iterate over dates
  let current_date = new Date(startDate);
  
  let minStart = [];
  let maxEnd =[];
  
  // if(apiData.data[startDate]){
  //     Object.keys(apiData.data[startDate]).forEach(function(key, index) {
  //       minStart.push(startDate+" "+key+":00:00")
  //     });
  //     minStart= minStart.sort((a,b)=>new Date(a) - new Date(b))[0]
  // }else{
      minStart= workday && workday.start_hour?startDate+" "+workday.start_hour:startDate+" 07:00:00"
  // }

    if(apiData.data[endDate]){
      Object.keys(apiData.data[endDate]).forEach(function(key, index) {
          maxEnd.push(endDate+" "+key+":00:00")
      });
      maxEnd = maxEnd.sort((a,b)=>new Date(b) - new Date(a))[0]
    }else{
      maxEnd = workday && workday.end_hour?endDate+" "+workday.end_hour:endDate+" 06:00:00"
    }

    console.log("minStart",minStart,maxEnd)


  while (current_date <= new Date(endDate)) {
    const current_date_str = current_date.toISOString().split('T')[0];

    // Check if the date exists in the API data
    if (apiData.data[current_date_str]) {
      // Iterate over hours (0 to 23)
      for (let hour = 0; hour < 24; hour++) {
        const hour_str = hour.toString().padStart(2, '0');
        const time_key = hour <= 23 ? hour_str : '00';

        // Check if the hour exists in the API data for the current date
        
        if (apiData.data[current_date_str][time_key] || apiData.data[current_date_str][hour]) {
          const data_entry = apiData.data[current_date_str][time_key] || apiData.data[current_date_str][hour];
          const formatted_entry = {
            date: current_date_str,
            time: hour_str,
            productive: data_entry.productive || 0,
            less_productive: data_entry.less_productive || 0,
            non_productive: data_entry.non_productive || 0,
            undefined: data_entry.undefined || 0,
          };
          formattedData.push(formatted_entry);
        } else {
          // If the hour is missing, add an entry with zeros
          formattedData.push({
            date: current_date_str,
            time: hour_str,
            productive: 0,
            less_productive: 0,
            non_productive: 0,
            undefined: 0,
          });
        }
      }
    } else {
      // If the date is missing, add entries for all hours with zeros
      for (let hour = 0; hour < 24; hour++) {
        const hour_str = hour.toString().padStart(2, '0');
        formattedData.push({
          date: current_date_str,
          time: hour_str,
          productive: 0,
          less_productive: 0,
          non_productive: 0,
          undefined: 0,
        });
      }
    }

    current_date.setDate(current_date.getDate() + 1);
  }

  let st = moment(minStart).format('HH:mm:ss');
  let et= moment(maxEnd).format('HH:mm:ss')

  return formattedData.filter(el=>moment(el.date).set({hour:parseInt(el.time)}) >= moment(startDate+" "+st) && moment(el.date).set({hour:parseInt(el.time)}) <= moment(endDate+" "+et) ) ;
  
  // return formattedData;
};

  async function loopHourlyChart(date) {
    let dates=[]
    for(let  i=0;i<date.length;i++){
      if(isWeekday(date[i])){
        dates.push(date[i]);
      }
    }
    if(dates.length ==0){
      dates.push(selectedDate)
    }

    SetModalDate(false);
    SetCompareDate(dates);
    let inc = 0;
    setChartLoader(true);
    let allArray = [];
    SetChartCompareSeries([]);

    for (let i = 0; i < dates.length; i++) {
    
      let requestBody = {};
      requestBody.start_date = moment(dates[i]).format("YYYY-MM-DD");
      requestBody.end_date = moment(dates[i]).format("YYYY-MM-DD");
      requestBody.slug = "employee";
      let arr = [];
      let cat = [];
      const newArray = [];
     await getPerformanceTodayData(
        requestBody,
        userSession,
        org_id,
        sub_org_id
      ).then((res) => {
        if (res.status == 200) {
          SetError("");

// Iterate through the data
    const startDate = moment(dates[i]).format("YYYY-MM-DD");
    let endDate = moment(dates[i]).add(addDay,'days').format("YYYY-MM-DD");
    if(workday.start_hour==='00:00:00'){
      endDate = startDate;
    }
    console.log("startDate before api",startDate,endDate)


    const formattedResult = formatApiData(res, startDate, endDate);
    // newArray.push(...formattedResult.filter(el=>moment() >= moment(el.date).set({hour:parseInt(el.time)}))) 
    newArray.push(...formattedResult.filter(el=> moment(new Date().toLocaleString('en-US', { timeZone: indonesiaTimeZone })) >= moment(el.date).set({hour:parseInt(el.time)}))); 
    
        // for(let i=0;i<categories2Hour.length;i++){
        
        //   let element = newArray.find(el=>el.time == categories2Hour[i])
        //   if(element){
        //     arr.push(element)

        //   }else{
        //     // if(!currentDay){
        //     // arr.push({
        //     //   date: dates[i],
        //     //   time: categories2Hour[i],
        //     //   productive: 0,
        //     //   less_productive: 0,
        //     //   non_productive: 0,
        //     //   undefined: 0,
        //     // })
        //     // }
        //   }
        // }

          allArray.push({
            date: dates[i],
            categories: newArray.map(el=>moment(el.date).set({hour:el.time}).format("hh A")),
            series: [
              {
                name: "Out of Range",
                data: newArray.map((item) => item.undefined),
                color: "#dedee3",
              },
              {
                name: "Non Productive",
                data: newArray.map((item) => item.non_productive),
                color: "#fc0001",
              },
              {
                name: "Less Productive",
                data: newArray.map((item) => item.less_productive),
                color: "#fdbf00",
              },
              {
                name: "Productive",
                data: newArray.map((item) => item.productive),
                color: "#90cf4e",
              },
            ],
          });
         
          if (i == dates.length - 1) {
            allArray.sort((a,b)=>{
              return new Date(a.date) -  new Date(b.date);
            })

            SetChartCompareSeries(allArray);
            setChartLoader(false);

          }

          // inc++;
        } else {
          // SetError('Some Issue Occured, Please Try after some time')
          // inc++;

          //setChartLoader(false);
        }
      
      });
    }
  }



function SetChartDataPerformaceFunction(data){

}

function offLoader(){


}
//facility counter
 function departmentSelected(deptID,subDpetID,active){
    //SetActive(type)
              SetFilterEnable(true);
    subDpetID = subDpetID?subDpetID:[];

    if(deptID.indexOf('all')  > -1 && ((departmentList.length) ==(deptID.length - 1))){
      //SetDeparmentID([]);
      deptID= []
    }else if(deptID.indexOf('all')  > -1){
     // SetDeparmentID(departmentList.map(i=>i.id))
      deptID= departmentList.map(i=>i.id)
    }{
      SetDeparmentID(deptID)
      //deptID= departmentList.map(i=>i.id)
    }
    SetDeparmentID(deptID);
    SetSUbDepartmentID([]);
    let subList = departmentList.filter(item=>item.id==deptID);
    if(subList.length> 0){
      SetSubDepartmentList(subList[0].sub_department);
    }
     

    // });

}

//facility counter

async function submitHandlerFacility(deptID,subDpetID,active){
      let infacilityWithTime =[]
    let checkedInWithTime =[];
      let cat = headerRow2Disp;
      let checkedInArray=[];
      let faciliyInArray=[];
      let facilityPlanArray =[]    
          let series =[];
let  start_date= moment(chartDate).format('YYYY-MM-DD');
    let  end_date =  moment(chartDate).format('YYYY-MM-DD');
    if(active=="week"){
     start_date= moment(chartDate).subtract(7,'days').format('YYYY-MM-DD');
     end_date =  moment(chartDate).format('YYYY-MM-DD');

    }else if(active=="month"){
     start_date= moment(chartDate).subtract(30,'days').format('YYYY-MM-DD');
     end_date =  moment(chartDate).format('YYYY-MM-DD');

    }
    
setChartLoader(true)
  let res={};

  if(deptID.length > 0) {

    res= await getFacilityCounterReport(userSession,org_id,sub_org_id,start_date,end_date,deptID,subDpetID);
  }else{
    res= await getFacilityCounter(userSession,org_id,sub_org_id,start_date,end_date);
  } 

  let res2 = await getFacilityCounterAttendance(userSession,org_id,sub_org_id,start_date)
  let facility_plan= facilityPlanFormatter(res2);  
  
      
      let conversionData = []
      if(res.status==200){
      Object.keys(res.data).forEach((key) => {
        let date= moment(key).format('YYYY-MM-DD')
        let time = moment(key).format('HH');

        let singleDt={
          date:moment(key).format('YYYY-MM-DD'),
          hour:moment(key).format('HH'),
          datefull:key,
          facility_count:res.data[key].facility_count,
          total_count:res.data[key].total_count,
          deviation_percent:res.data[key].deviation_percent,
          facility_plan: facility_plan[date] && facility_plan[date][time]?facility_plan[date][time]: 0
        }
        conversionData.push(singleDt);
      });
      


      for (let i = 0; i < headerRow2Disp.length; i++) {
        let index = converSionHeaderRow2Disp.indexOf(headerRow2Disp[i]);
        let dt = conversionData.filter(item=>parseFloat(item.hour) == index);
        
        if(dt.length> 0){
          faciliyInArray.push({name:"In-facility", y:Math.round(dt.reduce((prev,current)=>prev + current.facility_count,0)/dt.length),magicRatio:Math.round(dt.reduce((prev,current)=>prev + current.deviation_percent,0)/dt.length)});
          checkedInArray.push({name:"Checked-In",y:Math.round( dt.reduce((prev,current)=>prev + current.total_count,0)/dt.length),magicRatio:Math.round(dt.reduce((prev,current)=>prev + current.deviation_percent,0)/dt.length)});
          facilityPlanArray.push({name:"Facility Plan",y:dt.reduce((prev,current)=>prev + current.facility_plan,0)})
         
        }else{
            faciliyInArray.push({name:"In-facility",y:0,magicRatio:0});
            checkedInArray.push({name:"Checked-In",y:0,magicRatio:0});
            facilityPlanArray.push({name:"Facility Plan",y:0})

        }
      }

    series.push({name:"Checked-In",data:checkedInArray,color:'grey'})
    series.push({name:"In-Facility",data:faciliyInArray,lineWidth: 5,color: "#ef5e8c",})
    series.push({name:"Facility Plan",data:facilityPlanArray,lineWidth: 5,color:"#62eba4"})
   
    setChartDataFacility({series:series,categories:cat});
    setChartLoader(false)
          
    SetFilterEnable(false);
        }else{
              SetFilterEnable(false);
          setChartDataFacility({series:[],categories:[]});
          setChartLoader(false);
          
        }
}

function subDepartmentSelected(subDpetID,deptID,active){

subDpetID = subDpetID?subDpetID:[];
    SetFilterEnable(true);
    if(subDpetID.indexOf('all') > -1 && ((subDepartmentList.length) ==(subDpetID.length - 1))){
      SetSUbDepartmentID([]);
      subDpetID= []
    }else if(subDpetID.indexOf('all') > -1){
      SetSUbDepartmentID(subDepartmentList.map(i=>i.id))
      subDpetID= subDepartmentList.map(i=>i.id)
    }else{
      SetSUbDepartmentID(subDpetID)
      
    }
}





  return (
    <>
      {
      permissionAPICall  ? (
        permission.length > 0  ?  (
          <div className="dashboardComponentMainDiv">
            <Container>
              <Row>
                <Col lg={3} className="m-t-sm">
                  <CommonHeading title={getTranslatedText("Dashboard")} />
                </Col>
                <Col lg={9} className="text-right">
                            {/* <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                            
                        </div> */}
                  <div className="commonHeadingDateMainDivParent">
                    <CommonDatePicker
                      selectedDate={selectedDate}
                      handleSelectDate={handleDateSelect}
                      weekDays={props.weekDays}
                      holiday={props.holiday}
workday={props.workday}                      
                    />
                  </div>
                  
                </Col>
              </Row>

                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <TopAreaPerformance
                      selectedDate={selectedDate}
                      dates={dates}
                      compareDate={compareDate}
                      topAPICall={topAPICall}
                      employee={employee}
                      permission={permission}
                   //   WeekStats={WeekStats}
                     // todayStats={todayStats}
                      //monthStats={monthStats}
                      loader={loader}
                      offChartLoader={offChartLoader}
                      // weekName={weekName}
                      nohour={nohour}
                      performaceData={performaceData}
                      // checkedIn={checkedIn}
                      // magicRatio={magicRatio}
                      // inFacility={inFacility}
                      // loaderSecondWidget ={loaderSecondWidget}
                      changeDataofPerformance={changeDataofPerformance}
                      indexTitle={indexTitle}
                      active={active}
                      offLoader={offLoader}
                      getMonths={getMonths}
                      weekDays={props.weekDays}
                      holiday={props.holiday}
workday={props.workday} 
                      chartDate={chartDate}
                      workday ={props.workday}
                    />
                  </Col>
                </Row>
              <div className="dashboardGraphAndIndexMainDiv">
                <Row>
                  <Col lg={3} className="p-r-0 fullSmallDesktop">
                    {showIndexTab(indexTitleArray)}
                  </Col>

                  <Col lg={9} className="fullSmallDesktop">
                    <div className="m-l-sm">
                      <Row className="m-t-xs" style={{ marginBottom: "20px" }}>
                        {indexTitle===2?<Col lg={7} className="fullSmallDesktop">
                      
                            <div className="btnINline" style={{float:"left"}}>
                                  <button className={facilityActive=="category"?"btn btn-pink btn-primary active":"btn btn-pink btn-primary"} onClick={()=>SetFacilityActive('category')}>Category</button>
                                  <button className={facilityActive=="function"?"btn btn-pink btn-primary active":"btn btn-pink btn-primary"} onClick={()=>SetFacilityActive('function')}>Function</button>
                                  <button className={facilityActive=="process"?"btn btn-pink btn-primary active":"btn btn-pink btn-primary"} onClick={()=>SetFacilityActive('process')}>Process V1</button>
                                  <button className={facilityActive=="process2"?"btn btn-pink btn-primary active":"btn btn-pink btn-primary"} onClick={()=>SetFacilityActive('process2')}>Process V2</button>
                            </div>

                            {facilityActive=="process" || facilityActive=="process2"?<div className="btnINline" style={{marginLeft:20}}>
                              <button className={"btn btn-pink btn-primary"} disabled={chartProcess.series && chartProcess.series.length == 0} onClick={()=>generateExcel( chartProcess, categoriesList)}>
                                  {/* <img src={ExportIcon} style={{maxWidth:16,marginRight:5,marginRight:10}} /> */}
                                   Export V1</button>
                              <button className={"btn btn-pink btn-primary"} disabled={chartProcess.series && chartProcess.series.length == 0} onClick={()=>generateExcel2( chartProcess, categoriesList)}>
                                {/* <img src={ExportIcon} style={{maxWidth:16,marginRight:5}} /> */}
                                 Export V2</button>
                            </div>:""}

                          
                        </Col>:""}
                        
                        <Col lg={indexTitle===2?5:12} className="fullSmallDesktop" style={{ textAlign: "right" }}>
                          <div className="datePickerSmall">
                            <div className="contactRankText">
                              {getTranslatedText("Date")}
                            </div>
                            <div
                              className="startDateEndDateMainDiv startDateEndDateMainDivSmallDashboard"
                              style={{ paddingLeft: "0px" }}
                            >
                              {active == "today" && indexTitle == 0  ? (
                                <div className="dateoverlay">
                                  <span className="label" onClick={()=>SetModalDate(!modalDate)}>{compareDate.map(item=>{return <span className="dateList" key={"compare"+item.toString()}>{moment(item).format('DD MMM')}</span>} )}</span>
                                  <div className={props.weekDays.filter(item=>!item.working).length > 0?props.weekDays.filter(item=>!item.working).map(el=> "holiday-"+el.day) :'no-holiday'}>
                                <MultipleDatePicker
                                  maxDate={selectedDate}
                                  minDate={new Date('2023-01-01')}

                                  onSubmit={(dates) => {SetTopAPICall(false);loopHourlyChart(dates)}}
                                
                                /></div></div>
                              ) : (<div className="textVIsible">
                                <DatePicker
                                  selected={chartDate}
                                  onChange={(date) => {SetTopAPICall(false);setChartLoader(true);SetChartDate(date);SetDataRecieved(false)}}
                                  dateFormat={"MMM dd"}
                                  isClearable={false}
                                  placeholderText={'Select Date'}
                                  maxDate={selectedDate}
                                  minDate={new Date('2023-01-01')}
                                  filterDate={isWeekday}
                                
                                /></div>
                              )}
                            </div>
                          </div>

                    {indexTitle == 3?<>
                          <div className="smallSelect">
                            <div className="contactRankText">
                              {getTranslatedText("Department")}
                              <React.Fragment>
                                            <div className='helpSmallInfo iconSmallHelp' 
                                                onMouseEnter={() => handleMouseEnter(`tooltIpFunction`)}
                                                onMouseLeave={() => handleMouseLeave(`tooltIpFunction`)}
                                            >
                                                    <HelpIcon/>
                                            </div>
                                            {/* <img
                                                alt=''
                                                src={helpIcon}
                                                onMouseEnter={() => handleMouseEnter(`tooltIpFunction`)}
                                                onMouseLeave={() => handleMouseLeave(`tooltIpFunction`)}
                                            /> */}
                                            <div className='tooltipNewStyle' id={`tooltIpFunction`} style={{width:"220px",maxWidth:"220px"}} >
                                              <h5>Function filter</h5>
                                              <p> With this filter you can view the data for a selected function(s)</p>

                                            </div>
                                        </React.Fragment>

                              
                            </div>
        <Select
              placeholder="Select Department"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              onChange={(val)=>{departmentSelected(val,subDepartmentID,active)}}
              //defaultValue={department_id}
              value={departmentID}
              key="departmentFilterv2"
              style={{width:"130px",textAlign:"left"}}
              dropdownClassName={'smallerDrop smallerDrop2'}
              mode={"multiple"}

          >
            <Option value='all' key={"departmentAll"}>All</Option>
            {departmentList.map(item=>{
              return <Option value={item.id} key={"department"+item.id}>{item.name}</Option>
            })}
            
                          
          </Select>
          </div>           
                             <div className="smallSelect">
                                                          <div className="contactRankText">
                              {getTranslatedText("Sub Department")}
                                                            <React.Fragment>
                                            <div className='helpSmallInfo iconSmallHelp' 
                                                onMouseEnter={() => handleMouseEnter(`tooltIpFunctionSub`)}
                                                onMouseLeave={() => handleMouseLeave(`tooltIpFunctionSub`)}
                                            >
                                                    <HelpIcon/>
                                            </div>
                                            
                                            <div className='tooltipNewStyle' id={`tooltIpFunctionSub`} style={{width:"220px",maxWidth:"220px"}} >
                                              <h5>Sub Function filter</h5>
                                              <p> With this filter you can view the data for a selected function(s)/sub-functions(s)</p>

                                            </div>
                                        </React.Fragment>
                            </div>
          <Select
              placeholder="Select Sub Department"
              optionFilterProp="children"
              //onChange={(val)=>{if(val.indexOf('all') > -1 && val.length==3){SetType([])} else if(val.indexOf('all') > -1 && val.length!=2){SetType(['regular_worker','daily_worker']) }else{SetType(val)} }}
              onChange={(val)=>{ subDepartmentSelected(val,departmentID,active)}}
              //defaultValue={department_id}

              value={subDepartmentID}
              key="departmentFilterv3"
style={{width:"130px",textAlign:"left"}}
              dropdownClassName={'smallerDrop smallerDrop2'}
              disabled={departmentID.length==0 || departmentID.length > 1}
              mode={"multiple"}
          >
            <Option value='all' key={"sub_departmentAll"}>All</Option>
            {subDepartmentList.map(item=>{
              return <Option value={item.id} key={"Subdepartment"+item.id}>{item.name}</Option>
            })}            
          </Select>
          </div>
            <div className="smallSelect smallBtn"><button disabled={!filterEnable} onClick={()=>submitHandlerFacility(departmentID,subDepartmentID,active)}>Filter</button></div>
          </>:""}   
                          {errorMessage.length > 0 ? (
                            <div
                              className="inline"
                              style={{ display: "inline", marginRight: "15px" }}
                            >
                              <div
                                className="errorMessage errorMessageHighlight"
                                style={{ display: "inline" }}
                              >
                                {errorMessage}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="btnINline" style={{ float: "right" }}>
                            {indexTitle == 0 ? (
                              <>
                                <button
                                  disabled={chartLoader}
                                  className={
                                    active == "today"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    //changeDataofPerformance("today");
                                    SetActive("today");
                                    loopHourlyChart(compareDate)
                                  }}
                                >
                                  Hourly Compare
                                </button>
                                {/* <button disabled={chartLoader} className={active=="day"?'btn btn-pink btn-primary active':'btn btn-pink btn-primary'} onClick={()=>{changeDataofPerformance('day')}} >Daily</button> */}
                                <button
                                  disabled={chartLoader || !dataRecieved }
                                  className={
                                    active == "demo"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    changeDataofPerformance("demo");
                                  }}
                                >
                                  Daily
                                </button>
                                <button
                                  disabled={chartLoader || !dataRecieved}
                                  className={
                                    active == "week"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    changeDataofPerformance("week");
                                  }}
                                >
                                  Weekly
                                </button>
                                <button
                                  disabled={chartLoader || !dataRecieved}
                                  className={
                                    active == "month"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    changeDataofPerformance("month");
                                  }}
                                >
                                  Monthly
                                </button>
                              </>
                            ) : indexTitle == 1 ? (
                              <>
                                <button
                                  disabled={chartLoader}
                                  className={
                                    active == "today"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    changeBreakTime("today");
                                  }}
                                >
                                  On {moment(chartDate).format("DD MMM")}
                                </button>
                                <button
                                  disabled={chartLoader}
                                  className={
                                    active == "week"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    changeBreakTime("week");
                                  }}
                                >
                                  1 Week
                                </button>
                                <button
                                  disabled={chartLoader}
                                  className={
                                    active == "1 month"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    changeBreakTime("1 month");
                                  }}
                                >
                                  1 Month
                                </button>
                                <button
                                  disabled={chartLoader}
                                  className={
                                    active == "2 month"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    changeBreakTime("2 month");
                                  }}
                                >
                                  2 Month
                                </button>
                              </>
                            ) : indexTitle == 2 ?
                            (
                              <>
                                <button
                                  disabled={chartLoader}
                                  className={
                                    active == "today"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    changeDataofCycleCount("today", chartDate,facilityActive);
                                  }}
                                >
                                  {" "}
                                  On {moment(chartDate).format("DD MMM")}
                                </button>
                                {/* <button disabled={chartLoader} className={active=="day"?'btn btn-pink btn-primary active':'btn btn-pink btn-primary'} onClick={()=>{changeDataofCycleCount('day',chartDate)}} >On {moment(chartDate).format('DD MMM')}</button> */}
                                <button
                                  disabled={chartLoader}
                                  className={
                                    active == "week"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    changeDataofCycleCount("week", chartDate,facilityActive);
                                  }}
                                >
                                  Week
                                </button>
                                <button
                                  disabled={chartLoader}
                                  className={
                                    active == "month"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    changeDataofCycleCount("month", chartDate,facilityActive);
                                  }}
                                >
                                  Month
                                </button>
                              </>
                            ):(
                              <>
                      

                                <button
                                  disabled={chartLoader}
                                  className={
                                    active == "today"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    
                                    changeDataFacilityCounter("today", chartDate);
                                  }}
                                >
                                  {" "}
                                  On {moment(chartDate).format("DD MMM")}
                                </button>
                                {/* <button disabled={chartLoader} className={active=="day"?'btn btn-pink btn-primary active':'btn btn-pink btn-primary'} onClick={()=>{changeDataofCycleCount('day',chartDate)}} >On {moment(chartDate).format('DD MMM')}</button> */}
                                <button
                                  disabled={chartLoader}
                                  className={
                                    active == "week"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    changeDataFacilityCounter("week", chartDate);
                                  }}
                                >
                                  Week
                                </button>
                                <button
                                  disabled={chartLoader}
                                  className={
                                    active == "month"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    changeDataFacilityCounter("month", chartDate);
                                  }}
                                >
                                  Month
                                </button>
                              </>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="m-t-xs" style={{ marginBottom: "20px" }}>
                        {chartLoader ? (
                          <Col className="text-center" lg={12}>
                            <img src={spinnerLoader} />
                          </Col>
                        ) : (

                          <div style={{ width: "100%" }} ref={elRef}>
                            
                            {
                            indexTitle == 0 ? (
                              <div className="percentageHidden">
                              
                                {active != "demo" ? (
                                  <>
                                    {active === "today" ? (
                                      <>
                                      <div
                                      className="myscroll"
                                      style={{
                                        height: compareDate.length>2?600:320,
                                        overflowY: "scroll",
                                      }}
                                    >

                                        {
                                          chartCompareSeries.map(
                                            (item, index) => {
                                             
                                              return (
                                                <div
                                                  className="chartContainerStyle"
                                                  style={{
                                                    display: "inline-block",
                                                    width: chartCompareSeries.length==1?"100%":"49%",
                                                    marginLeft:
                                                      index % 2 != 0 ? "1%" : 0,
                                                  }}
                                                  key={"compareChart"+index}
                                                >
                                                  <h4>
                                                    {moment(item.date).format(
                                                      "DD MMM"
                                                    )}
                                                  </h4>

                                                 <StackChart
                                                    categories={item.categories}
                                                    series={item.series}
                                                    seriesName={
                                                      "Employee Productivity"
                                                    }
                                                    chartData={[]}
                                                    type={"employee"}
                                                    height={chartCompareSeries.length==1?260:215}
                                                    //   width="100%"
                                                    // width={widthChart}
                                                    typePerormance={active}
                                                    tickPositioner={4}
                                                    key={"stackChartByTypeCompareSeries "+index}
                                                    key1={"stackChartByType"}
                                                  />
                                                </div>
                                              );
                                            }
                                          )}
                                          </div>
                                      </>
                                    ) :!dataRecieved?                          <Col className="text-center" lg={12}>
                            <img src={spinnerLoader} />
                          </Col>:(
                                      <StackChart
                                        categories={
                                          chartDataPerformance.categories
                                        }
                                        series={chartDataPerformance.series}
                                        seriesName={"Employee Productivity"}
                                        chartData={[]}
                                        type={"employee"}
                                        height={315}
                                        //   width="100%"
                                        width={widthChart}
                                        typePerormance={active}
                                        key={"stackChartByType Sinhle"}
                                        key1={"stackChartByType"}
                                      />
                                    )}
                                  </>
                                ) : !dataRecieved?                          <Col className="text-center" lg={12}>
                            <img src={spinnerLoader} />
                          </Col>:(
                                  
                                  <>
                                    <div className={helpEnable?"helpIconAnimate active":"helpIconAnimate"} onClick={()=>SetHelpEnable(!helpEnable)}>
                                      
                                      
                                      
                                    <HelpIconNew />
                                      
                                      
                                      {helpEnable?<div className="helpToolTip">Hide Value</div>:<div className="helpToolTip">Display Value</div>}</div>
                                    <StackChartDrillDown
                                      categories={
                                        chartDataPerformance.categories
                                      }
                                      series={chartDataPerformance.series}
                                      seriesName={"Employee Productivity"}
                                      chartData={[]}
                                      type={"employee"}
                                      height={315}
                                      //   width="100%"
                                      width={widthChart}
                                      helpEnable={helpEnable}
                                      typePerormance={active}
                                      modalChart={modalChart}
                                      key={"drillDownDemo"}
                                      key1={"drillDownDemo"}
                                    />
                                  </>
                                )}
                              </div>
                            ) : 
                            indexTitle == 1 ? (
                              <>
                                {/* {errorMessage.length>0?<div className=' col-lg-12'><div className='errorMessage errorMessageHighlight'>{errorMessage}</div></div>:""} */}
                                <ChartReport
                                  chartData={chartDataBreakTime}
                                  close={() => {}}
                                  legendEnable={true}
                                  // key={index}
                                  breaktime={true}
                                  height={350}
                                  key={"chartReport"+indexTitleArray[indexTitle].title}
                                />
                              </>
                            ) :
                            indexTitle == 2? (
                              <>
                                {/* Cycle Cout UI */}
                                <Row>
                                  {facilityActive==="function"?                                  <Col lg={12}>
                                    <div
                                      className="myscroll"
                                      style={{
                                        height: 600,
                                        overflowY: "scroll",
                                      }}
                                    >
                                      {functionList.map((item, index) => {
                                        return (
                                          <div
                                            className="chartContainerStyle"
                                            style={{
                                              display: "inline-block",
                                              width: "49%",
                                              marginLeft:
                                                index % 2 != 0 ? "1%" : 0,
                                            }}
                                            key={"functionList"+index}
                                          >
                                            <h4>{item.category_name}</h4>
                                            <div
                                              id={slugify(item.category_name)}
                                              key={"barchart" + index}
                                              key1={"barchart" + index}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </Col>:
                                  facilityActive==="category"?
                                  <Col lg={12}>
                                    <div
                                      className="myscroll"
                                      style={{
                                        height: 600,
                                        overflowY: "scroll",
                                      }}
                                    >
                                      {categoriesList.map((item, index) => {
                                        return (
                                          <div
                                            className="chartContainerStyle"
                                            style={{
                                              display: "inline-block",
                                              width: "49%",
                                              marginLeft:
                                                index % 2 != 0 ? "1%" : 0,
                                            }}
                                            key={"categoryName"+index}
                                          >
                                            <h4>{item.name}</h4>
                                            <div
                                              id={slugify(item.name)}
                                              key={"barchart" + index}
                                              key1={"barchart" + index}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </Col>:
                                  <Col lg={12}>
                                    <div
                                      className="myscroll"
                                      style={{
                                        height: 600,
                                        overflowY: "scroll",
                                      }}
                                    >
                                      {categoriesList.map((item, index) => {
                                        return (
                                          <div
                                            className="chartContainerStyle"
                                            style={{
                                              display: "inline-block",
                                              width: "49%",
                                              marginLeft:
                                                index % 2 != 0 ? "1%" : 0,
                                            }}
                                          >
                                            <h4>{item.name}</h4>
                                            <div
                                              id={"process"+slugify(item.name)}
                                              key={"barchart" + index}
                                              key1={"barchart" + index}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </Col>
                                  }
                                </Row>
                              </>
                            ):facilityActive==="category"? (
                              <>
                                {/* Cycle Cout UI */}
                                <Row>
                                  <Col lg={12}>
                                 <BarChart
                                  chartData={chartDataFacility}
                                  legendEnable={true}
                                  // key={index}
                                  chartDate={chartDate}
                                  breaktime={true}
                                  height={350}
                                  type={active}
                                  key={"chartReport"+indexTitleArray[indexTitle].title}
                                  showLegend = {true}
                                />                                  </Col>
                                </Row>
                              </>
                            ):(
                              <>
                                {/* Cycle Cout UI */}
                                <Row>
                                  <Col lg={12}>
                                 <BarChart
                                  chartData={chartDataFacility}
                                  legendEnable={true}
                                  // key={index}
                                  chartDate={chartDate}
                                  breaktime={true}
                                  height={350}
                                  type={active}
                                  key={"chartReport"+indexTitleArray[indexTitle].title}
                                  showLegend = {true}
                                />                                  </Col>
                                </Row>
                              </>
                            )
                            
                            }
                          </div>
                        )}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>

            <ReactModal
              isOpen={employeePopupFlag}
              style={customStyles}
              onRequestClose={handleCloseModal}
              shouldCloseOnOverlayClick={true}
            >
              <div className={"modal-mapowermanagement "}>
                <AgGridEmployeeList
                  hideHeading={true}
                  title={"Employee"}
                  hideGlobalSearch={true}
                  date={selectedDate}
                  startDate={dates.start}
                  endDate={dates.end}
                  type={modalType}
                  threatEmployee={"Employee"}
                  rank2Data={rank2Data}
                  AgkeyTable={"Employee"}
                  //height={(60*window.innerHeight)/100}
                  height={(75 * window.innerHeight) / 100}
                  columnexist={8}
                  isModalData={true}
                />
              </div>
            </ReactModal>

            <ReactModal
              isOpen={chartOpenModal}
              style={customStyles2}
              onRequestClose={handleCloseModalChart}
              // className={"chartModalStyle"}
              // shouldCloseOnOverlayClick={true}
            >
              <div
                className={
                  chartOpenLoader
                    ? "modal-mapowermanagement modal-chart loader"
                    : "modal-mapowermanagement modal-chart loader"
                }
              >
                {chartOpenLoader ? (
                  <div
                    className="wt100"
                    style={{ width: "100%", height: "285px" }}
                  >
                    <img
                      src={spinnerLoader}
                      style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        margin: "auto",
                        height: "200px",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className="percentageHidden "
                    style={{
                      marginLeft: "-15px",
                      marginRight: "-15px",
                      marginTop: "-15px",
                      marginBottom: "-5px",
                    }}
                  >
                    <h2
                      style={{
                        textAlign: "left",
                        padding: "0px 5px",
                        marginBottom: "10px",
                        fontSize: "18px",
                        paddingBottom: "5px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      {displayDate}
                      <span
                        className="closeMark"
                        onClick={() => SetChartModalOpen(false)}
                      >
                        x
                      </span>
                    </h2>

                    <StackChart
                      categories={chartOpenData.categories}
                      series={chartOpenData.series}
                      seriesName={"Employee Productivity"}
                      chartData={[]}
                      type={"employee"}
                      height={225}
                      width={570}
                      typePerormance={"today"}
                      modalChart={true}
                      key={"staChartDa" + indexTitleArray[0].id}
                      key1={"staChartDa" + indexTitleArray[0].id}
                    />
                  </div>
                )}
              </div>
            </ReactModal>

            {/* <ReactModal
              isOpen={locationPopupFlag}
              style={customStyles}
              onRequestClose={handleCloseModal}
              shouldCloseOnOverlayClick={true}
            >
              <div className="modal-sitemanagement">
                <SiteMangementList
                  hideHeading={true}
                  hideGlobalSearch={true}
                  title={modalType ? "At Risk Locations" : "Location Tags"}
                  startDate={dates.start}
                  endDate={dates.end}
                  rank={1}
                  type={modalType}
                  AgkeyTable={modalType ? "At Risk Locations" : "Location Tags"}
                  height={(75 * window.innerHeight) / 100}
                  columnexist={8}
                  isModalData={true}
                  role={props.role}
                />
              </div>
            </ReactModal> */}

            <ToastContainer toastClassName={toastClass} />
          </div>
        ) : (
          <Col lg={12}>
            {/* <div className='errorPermission'></div> */}
            <div className="center404">
              {" "}
              <Image404 />
              <h4 style={{ marginTop: "-30px", color: "#2e3552" }}>
                Access Restricted to this page, Please contact administrator
              </h4>
            </div>
          </Col>
        )
      ) : (
        <Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
          <img src={spinnerLoader} />
        </Col>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(DashboardOverview)
);