import React, { useState, useEffect, useRef } from "react";
import { CommonHeading } from "../../common/commonHeading";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactModal from "react-modal";

import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
// import DatePicker from "react-multi-date-picker";
import MultipleDatePicker from "react-multiple-datepicker";
import "react-toastify/dist/ReactToastify.css";

import "../../assets/styles/common.scss";
import "../styles/dashboard.scss";
import TopAreaPerformance from "./topAreaPerformance";

import helpIcon from "../../assets/traceplusImages/help-icon.png";
import pinkArrowIcon from "../../assets/traceplusImages/pink_outline_right_arrow_icon.svg";
import selectedPinkArrowIcon from "../../assets/traceplusImages/pink_right_arrow_icon.svg";

import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../actionMethods/actionMethods";

import EmployeeList from "../../manPowerManagement/components/employeeList";

import SiteMangementList from "../../siteManagement/components/index";

import moment, { min } from "moment";
import "antd/dist/antd.css";
import DashboardLanguage from "../../components/dashboardLanguage";
import spinnerLoader from "../../assets/images/loader.svg";
import CommonDatePicker from "../../common/commonDatePicker";
import { prepareDateObj } from "./helper";
// import { titles } from "./constant";
import { getTranslatedText } from "../../common/utilities";
import {
  getDepartmentList,
  getEmployeeList,
} from "../../manPowerManagement/actionMethods/actionMethods";
import { getSiteLocations } from "../../siteManagement/actionMethods/actionMethods";
import { getDashboardReportOverview } from "../../report/actionMethods/actionMethods";
import { PresetStatusColorTypes } from "antd/lib/_util/colors";
import AgGridEmployeeList from "../../manPowerManagement/components/agGridEmployeeList";
import { atRiskEmployee } from "../../manPowerManagement/actionMethods/actionMethods";

import { sidebarMenuIftems } from "../../common/menuItems";
import DeviceStatus from "../../components/DeviceStatus";
import { getRoleDetail } from "../../common/actionMethods";
import { ReactComponent as Image404 } from "../../assets/images/11132-ai.svg";
import { getPproductivityOverviewAll } from "../../productivity/actionMethods/actionMethods";
import StackChart from "./StackAreaChart";
import { getBreakTimeReport } from "../../report/actionMethods/actionMethods";

import { sidebarMenuItems } from "../../common/menuItems";
import {
  getCategoryList,
  getCycleCountPlan,
} from "../../realtimeTracker/actionMethods/actionMethods";
import Scrollbars from "react-custom-scrollbars";
import {
  getPerformanceTodayData,
  getCycleCountData,
  getFacilityWidget,
  getFacilityCounter
} from "../actionMethods/actionMethods";
import Highcharts from "highcharts";
import { Collapse } from 'antd';
import ChartReport from "../../report/componenets/ChartReport";
// import StackChartDrillDown from "./StackAreaChartDrilldown";

const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ff9696",
};

const riskBackgroundColor = {
  low: "#e7f6ef",
  medium: "#faf7e5",
  high: "#fef0f4",
};
let chart = [];

const indexTitleArray = [
  {
    title: "Cost Detail",
    isSelected: true,
    id: "costdetail",
    desc: "",
    disabled: false,
  },
  {
    title: "Evolution in time",
    isSelected: false,
    id: "evolution",
    desc: "",
    disabled: false,
  }
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxHeight: "90%",
  },
};
const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",

    width: window.innerWidth > 1300 ? 600 : "80%",
    maxHeight: "90%",
    textAlign: "center",
  },
};

function CostManagement(props) {
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date(new Date().setDate(new Date().getDate() - 1));
  let interval = 1440;
  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  let sub_org_id =
    userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let totalEmployeeTag = localStorage.getItem("totalEmployeeTag")
    ? localStorage.getItem("totalEmployeeTag")
    : 0;
  let totalSiteLocation = localStorage.getItem("totalSiteLocation")
    ? localStorage.getItem("totalSiteLocation")
    : 0;
  const { Panel } = Collapse;
  
  const [orgId, updateOrgId] = useState(1);
  const [orgCount, updateOrgCount] = useState(0);
  const [threatWatchColor, updateThreatWatchColor] = useState("green");
  const [selectedDate, updateSelectedDate] = useState(date);
  const [loader, SetLoader] = useState(true);
  const [agency,SetAgency] = useState([]);
  const [toastClass, updateToastClass] = useState("errorToast");
  let startShift={
      hour:props.workday&&  props.workday.start_hour? parseInt(props.workday.start_hour.split(":")[0]):7,
      minute:props.workday &&  props.workday.start_hour? parseInt(props.workday.start_hour.split(":")[1]):0,
      second:0
    };
  let endShift= {
        hour:props.workday&&  props.workday.end_hour? parseInt(props.workday.end_hour.split(":")[0]):6,
        minute:props.workday &&  props.workday.end_hour? parseInt(props.workday.end_hour.split(":")[1]):59,
        second:59
    };
  
  let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  
  console.log("props.workday",props.workday,addDay,startShift,endShift);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
  const [chartData,SetChartData] =useState({series:[],data:[]})
  // const [active, SetActive] = useState("team");
  const [active, SetActive] = useState("team");
  const [weekName,SetWeekName]= useState("");
  const [chartLoader,SetChartLoader] = useState(true); 
  
  let hourCheck = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
  ];

  const headerRow = [
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
  ];

  const headerRow2Disp = [
    "07 AM",
    "08 AM",
    "09 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "01 PM",
    "02 PM",
    "03 PM",
    "04 PM",
    "05 PM",
    "06 PM",
    "07 PM",
    "08 PM",
    "09 PM",
    "10 PM",
    "11 PM",
    "00 AM",
    "01 AM",
    "02 AM",
    "03 AM",
    "04 AM",
    "05 AM",
    "06 AM",
  ];
  const headerRow2DispMaxTime = [
    moment().set({ hour: 7, minutes: 0, second: 0 }),
    moment().set({ hour: 8, minutes: 0, second: 0 }),
    moment().set({ hour: 9, minutes: 0, second: 0 }),
    moment().set({ hour: 10, minutes: 0, second: 0 }),
    moment().set({ hour: 11, minutes: 0, second: 0 }),
    moment().set({ hour: 12, minutes: 0, second: 0 }),
    moment().set({ hour: 13, minutes: 0, second: 0 }),
    moment().set({ hour: 14, minutes: 0, second: 0 }),
    moment().set({ hour: 15, minutes: 0, second: 0 }),
    moment().set({ hour: 16, minutes: 0, second: 0 }),
    moment().set({ hour: 17, minutes: 0, second: 0 }),
    moment().set({ hour: 18, minutes: 0, second: 0 }),
    moment().set({ hour: 19, minutes: 0, second: 0 }),
    moment().set({ hour: 20, minutes: 0, second: 0 }),
    moment().set({ hour: 21, minutes: 0, second: 0 }),
    moment().set({ hour: 22, minutes: 0, second: 0 }),
    moment().set({ hour: 23, minutes: 0, second: 0 }),
    moment().set({ hour: 24, minutes: 0, second: 0 }),
    moment().set({ hour: 25, minutes: 0, second: 0 }),
    moment().set({ hour: 26, minutes: 0, second: 0 }),
    moment().set({ hour: 27, minutes: 0, second: 0 }),
    moment().set({ hour: 28, minutes: 0, second: 0 }),
    moment().set({ hour: 29, minutes: 0, second: 0 }),
    moment().set({ hour: 30, minutes: 0, second: 0 }),
  ];

  const converSionHeaderRow2Disp = [
    "00 AM",
    "01 AM",
    "02 AM",
    "03 AM",
    "04 AM",
    "05 AM",
    "06 AM",
    "07 AM",
    "08 AM",
    "09 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "01 PM",
    "02 PM",
    "03 PM",
    "04 PM",
    "05 PM",
    "06 PM",
    "07 PM",
    "08 PM",
    "09 PM",
    "10 PM",
    "11 PM",
  ];

  const [dates, setDates] = useState({
    start: moment(moment()).subtract(28, "days").toDate(),
    end: date,
  });

  function changeDataEvolution(type=selection,active=active){
       // SetChartLoader(true);
    let data =[...allEmployeee];
    
    let startDate = moment(selectedDate).toDate();
    let endDate= moment(selectedDate).toDate();
    let cat =[];
    let seriesData=[];
    let series=[]


    if(type=="day"){

      startDate =moment(selectedDate).subtract(28,'days').toDate();
    }
    if(type=='week'){
      startDate =moment(selectedDate).subtract(28,'days').toDate();
    }else  if(type=="month"){
      startDate =moment(selectedDate).subtract(60,'days').toDate();
    }

    if(type=='day'){
      
      //all 
      if(active=='all'){
        for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
          let startDateSft = moment(startDate).set(startShift);
          let endDateSft = moment(startDate).add(addDay,'days').set(endShift);
          //if(moment(startDate).isoWeekday()!= 7){
            let cost = allEmployeee.filter(item=>moment(item.check_in) >=  moment(startDateSft) && moment(item.check_in) <=  moment(endDateSft) ).reduce((prev,current,index) =>{
                    return  prev + parseInt(current.cost);
                },0);
            seriesData.push(cost);
            cat.push(moment(startDate).format('DD MMM'))
          // }else{
          //   cat.push(moment(startDate).format('DD MMM'))
          //   seriesData.push(0);
          // }
        }        
       
        series.push({name:"Cost",data:seriesData,color:"#75c465"})
      }
  else if(active == 'agencies'){
    
        let ag = [...agency];
        let seriesData= [...ag].map(item=> ({name:item.name,data:[]}) );


        for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
          let startDateSft = moment(startDate).set(startShift);
          let endDateSft = moment(startDate).add(addDay,'days').set(endShift);
         
          
              for(let j=0;j<seriesData.length;j++){
                //if(moment(startDate).isoWeekday()!= 7){
                seriesData[j].data.push(
                  allEmployeee.filter(item=>moment(item.check_in) >=  moment(startDateSft) && moment(item.check_in) <=  moment(endDateSft) && item.agency == seriesData[j].name ).reduce((prev,current,index) =>{
                    return  prev + parseInt(current.cost);
                },0)

                );
                if(j==0){
                  cat.push(moment(startDate).format('DD MMM'))
                }
                
              // }else{
              //   seriesData[j].data.push(0);
              //    if(j==0){
              //     cat.push(moment(startDate).format('DD MMM'))
              //   }
              // }
          } 
        }        

        
        series.push(...seriesData)
      }
    else if(active == 'rw_dw'){
    
        let ag = [...agency];
        let seriesData= [{name:"Regular Worker",data:[],color:"#75c465"}, {name:"Daily Worker",data:[],color:"orange"}];
        for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
          
          let startDateSft = moment(startDate).set(startShift);
          let endDateSft = moment(startDate).add(addDay,'days').set(endShift);
            
            //if(moment(startDate).isoWeekday()!= 7){
                seriesData[0].data.push(
                  allEmployeee.filter(item=>moment(item.check_in) >=  moment(startDateSft) && moment(item.check_in) <=  moment(endDateSft) && item.emp_type == 'regular' ).reduce((prev,current,index) =>{
                    return  prev + parseInt(current.cost);
                },0));
                seriesData[1].data.push(
                  allEmployeee.filter(item=>moment(item.check_in) >=  moment(startDateSft) && moment(item.check_in) <=  moment(endDateSft) && item.emp_type == 'daily' ).reduce((prev,current,index) =>{
                    return  prev + parseInt(current.cost);
                },0));
                cat.push(moment(startDate).format('DD MMM'))
              // }else{
              //   seriesData[0].data.push(0);
              //   seriesData[1].data.push(0);
              //   cat.push(moment(startDate).format('DD MMM'));
              // }
        
        }        

        
        series.push(...seriesData)
      }
      else if(active == 'function'){
          
    
        let ag = [...agency];
        let seriesData= [...department].map(item=> ({name:item.name,data:[]}) );


        for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
        let startDateSft = moment(startDate).set(startShift);
          let endDateSft = moment(startDate).add(addDay,'days').set(endShift);
          
              for(let j=0;j<seriesData.length;j++){
                //if(moment(startDate).isoWeekday()!= 7){
                seriesData[j].data.push(
                  allEmployeee.filter(item=>moment(item.check_in) >=  moment(startDateSft) && moment(item.check_in) <=  moment(endDateSft) && item.department_name == seriesData[j].name ).reduce((prev,current,index) =>{
                    return  prev + parseInt(current.cost);
                },0)
                );
                if(j==0){
                    cat.push(moment(startDate).format('DD MMM'))
                }
                
              // }else{
              //   seriesData[j].data.push(0);
              //    if(j==0){
              //     cat.push(moment(startDate).format('DD MMM'))
              //   }
              // }
          } 
        }        

        
        series.push(...seriesData)
      }
      else if(active == 'sub_function'){
          
    
        let ag = [...agency];
        let seriesData= [];
        for(let i=0;i<department.length;i++){
          if(department[i].sub_department){
            seriesData.push(...department[i].sub_department.map(item=> ({name:item.name,data:[]}) ))
          }
        }
       

        for (let i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
         let startDateSft = moment(startDate).set(startShift);
          let endDateSft = moment(startDate).add(addDay,'days').set(endShift);
          
              for(let j=0;j<seriesData.length;j++){
               // if(moment(startDate).isoWeekday()!= 7){
                seriesData[j].data.push(
                  allEmployeee.filter(item=> moment(item.check_in) >=  moment(startDateSft) && moment(item.check_in) <=  moment(endDateSft) && item.sub_department_name == seriesData[j].name ).reduce((prev,current,index) =>{
                    return  prev + parseInt(current.cost);
                },0)
                );
                if(j==0){
                    cat.push(moment(startDate).format('DD MMM'))
                }
                
              // }else{
              //   seriesData[j].data.push(0);
              //    if(j==0){
              //     cat.push(moment(startDate).format('DD MMM'))
              //   }
              // }
          } 
        }        

        
        series.push(...seriesData)
      }
    
    }
    
    SetChartData({series:series,categories:cat})
    setTimeout(function(){
       SetChartLoader(false);
    },2000);

  



  }

  const initialMenu = localStorage.getItem("menuseq")
    ? JSON.parse(localStorage.getItem("menuseq"))
    : sidebarMenuItems.map((item) => {
        return item.title;
      });
  const [role, SetRole] = useState([]);
  const [permission, SetPermission] = useState([]);
  const [permissionAPICall, SetPermissionAPICall] = useState(false);
  const [indexLevel, setIndexLevel] = useState({
    area: "",
    mobility: "",
    population: "",
    spread: "",
  });
  const [indexTitle, updateIndexTitle] = useState(0);

  const [dataAll, SetDataAll] = useState([]);
  const chartContainer = React.useRef();
  const [widthChart, setWidthChart] = useState(0);
  const [errorMessage, SetError] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [onBoardEmployee,SetOnBoardEMployee]= useState([]);
  const [employeeCost,SetEMployeeCost] =useState([]);
  const [selection,SetSelection]= useState('day');
  const [allEmployeee,SetAllEmployee] =useState([])
  const [department,SetDepartment] = useState([])
  
  const categories = [
    "12 AM",
    "01 AM",
    "02 AM",
    "03 AM",
    "04 AM",
    "05 AM",
    "06 AM",
    "07 AM",
    "08 AM",
    "09 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "01 PM",
    "02 PM",
    "03 PM",
    "04 PM",
    "05 PM",
    "06 PM",
    "07 PM",
    "08 PM",
    "09 PM",
    "10 PM",
    "11 PM",
  ];

  
  useEffect(() => {        
        // if (props.match.path == '/cost-management' && parseInt(userDetails.org_id)!== 31 ) {
        //     if(parseInt(userDetails.org_id)== 31){

        //         let menuItem = sidebarMenuItems.find(item=>item.title==initialMenu[0])
        //         props.history.push(menuItem.paths)

        //     }else if(parseInt(userDetails.org_id)!= 31 && initialMenu[0]!='Cost Management' ){
        //         let menuItem = sidebarMenuItems.find(item=>item.title==initialMenu[0])
        //         props.history.push(menuItem.paths)

        //     }else if(initialMenu[0] == 'Cost Management'){
                
        //         let menuItem = sidebarMenuItems.find(item=>item.title==initialMenu[1])

        //         props.history.push(menuItem.paths)

        //     }
            
        // }
    },[]);

  

  const elRef = useRef();

  const monthInt = [
    "",
    "Jaunary",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = [
    { month: "Jan", days: 31, intMonth: 1 },
    { month: "Feb", days: 28, intMonth: 2 },
    { month: "Mar", days: 31, intMonth: 3 },
    { month: "Apr", days: 30, intMonth: 4 },
    { month: "May", days: 31, intMonth: 5 },
    { month: "Jun", days: 30, intMonth: 6 },
    { month: "Jul", days: 31, intMonth: 7 },
    { month: "Aug", days: 31, intMonth: 8 },
    { month: "Sep", days: 30, intMonth: 9 },
    { month: "Oct", days: 31, intMonth: 10 },
    { month: "Nov", days: 30, intMonth: 11 },
    { month: "Dec", days: 31, intMonth: 12 },
  ];

  
  function performaceData(data){  
    // setChartDataPerformanceAll(data);
  }
useEffect(() => {
    // let perm= props.role.health_management?JSON.parse(props.role.health_management):null;
    let perm = props.pages.filter(el=>el.page_name=="Cost Management");
   
      SetPermission(perm);
      SetPermissionAPICall(true);
   
  }, [props.pages]);



 
  function GetRandomDigit(min=0,max=10){
   return  Math.floor(Math.random() * (max - min + 1)) + min;
  }


  function handleDate(date,label){
    setDates((prev) => ({ ...prev, [`${label}`]: date }));
  }


  useEffect(() => {
    if (!elRef?.current?.clientWidth) {
      return;
    }
    setWidthChart(elRef?.current?.clientWidth);
  }, [elRef?.current?.clientWidth]);

  const getMonths = (fromDate, toDate, performance) => {
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];
    if (performance == "month") {
      let loop = 0;
      for (let year = fromYear; year <= toYear; year++) {
        let monthNum = year === fromYear ? fromMonth : 0;
        const monthLimit = year === toYear ? toMonth : 11;

        for (; monthNum <= monthLimit; monthNum++) {
          let month2 = monthNum + 1;
          let MonthAct = month.find((item) => item.intMonth == month2);
          let start = "";
          let end = "";
          let cond = "";

          if (loop == 0 && year == toYear && monthNum == monthLimit) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 1";
          } else if (loop == 0) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 2";
          } else if (year == toYear && monthNum == monthLimit) {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 3";
          } else {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 4";
          }
          loop++;

          months.push({
            year,
            month: month2,
            start: start,
            end: end,
            cond: cond,
          });
        }
      }

      return months;
    }
    // else {return getMonths(fromDate, toDate,'month');}
    else {

      return generateWeeksBard(fromDate, toDate);
    }
  };
    function formatNumberWithAbbreviation(number) {
      const billion = 1e9;
      const million = 1e6;
      const thousand = 1e3;

      if (number >= billion) {
        return (number / billion).toFixed(2) + 'B';
      } else if (number >= million) {
        return (number / million).toFixed(2) + 'M';
      } else if (number >= thousand) {
        return (number / thousand).toFixed(2) + 'k';
      } else {
        return number.toString();
      }
}

function generateWeeksBard(startDate, endDate) {
  const weeks = [];
  let currentDate = moment(startDate);
  const finalDate = moment(endDate);

  // Adjust the first week to end on the next Sunday
  let firstWeekEnd = moment(currentDate).day(7); // Sunday of the first week

  if (firstWeekEnd > finalDate) {
    firstWeekEnd = finalDate;
  }

  weeks.push({
    start: moment(currentDate).format('YYYY-MM-DD'),
    end: moment(firstWeekEnd).format('YYYY-MM-DD'),
    month: moment(currentDate).month() + 1,
    year: moment(currentDate).year(),
    weekName: `Week ${getWeekNumber(moment(currentDate).toDate())}`,
  });

  currentDate = moment(firstWeekEnd).add(1, 'days');

  // Process full weeks from Monday to Sunday
  while (currentDate <= finalDate) {
    let weekStart = moment(currentDate).day(1); // Set to Monday
    let weekEnd = moment(weekStart).day(7); // Set to Sunday

    if (weekEnd > finalDate) {
      weekEnd = finalDate;
    }

    weeks.push({
      start: moment(weekStart).format('YYYY-MM-DD'),
      end: moment(weekEnd).format('YYYY-MM-DD'),
      month: moment(weekStart).month() + 1,
      year: moment(weekStart).year(),
      weekName: `Week ${getWeekNumber(moment(weekStart).toDate())}`,
    });

    currentDate = moment(weekEnd).add(1, 'days');
  }

  return weeks;
}

function getWeekNumber(date) {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - oneJan) / 86400000);
  return Math.ceil((days + oneJan.getDay() + 1) / 7);
}


  const DepartmentComponent = ( department ) => {
    let dept=department.filter(item=>item.cost > 0);
  return (
    <Collapse accordion className="customAccord">
      {dept.map((dept, index) => (
        <Panel header={<div className="costBodayDept" style={{width:"100%"}}>
          <Row>
            <Col lg={4}>{dept.name}</Col>
            <Col lg={4}>{formatNumberWithAbbreviation(dept.cost)}</Col>
            <Col lg={4}>{`${dept.regular} RW / ${dept.daily} DW`}</Col>
          </Row>
        </div>}>
          <Collapse>
            {dept.sub_department.map((subDept, subIndex) => (
              <div className="costBodaySubDept" style={{width:"100%"}}>
          <Row>
            <Col lg={4}>{subDept.name}</Col>
            <Col lg={4}>{formatNumberWithAbbreviation(subDept.cost)}</Col>
            <Col lg={4}>{`${subDept.regular} RW / ${subDept.daily} DW`}</Col>
          </Row>
        </div>
            ))}
          </Collapse>
        </Panel>
      ))}
    </Collapse>
  );
};


  function AddZeroFormatter(value){
  let sp =value.toString().split('.');
  let inital=parseFloat(sp[0])?parseFloat(sp[0]):0;
  let end= parseFloat(sp[1])?parseFloat(sp[1]):0;
  
  if(parseFloat(sp[1]) < 10 && sp[1].length == 2){
    end ='0'+ parseFloat(sp[1]);
  }else{
    end = parseFloat(sp[1])+'0';
  }
  return parseFloat(inital+"."+end);
  // return parseFloat(value.toFixed(2));
  
}


  function slugify(string) {
    return string
      .toString()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/g, "_");
  }

  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);

  function handleIndexTabClick(index) {
    let arr = [...indexTitleArray];

    for (let indexVal = 0; indexVal < arr.length; indexVal++) {
      const element = arr[indexVal];

      if (indexVal == index) {
        arr[indexVal].isSelected = true;
      } else {
        arr[indexVal].isSelected = false;
      }
    }
    updateIndexTitle(index);
  }

  const handleMouseEnter = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "block";
    }
  };

  const handleMouseLeave = (id) => {
    let doc = document.getElementById(id);

    if (doc) {
      doc.style.display = "none";
    }
  };


  function showIndexTab(titleArray) {
    let arr = [];

    for (let index = 0; index < titleArray.length; index++) {
      const element = titleArray[index];
      //let level = getLevel(element.id);

      arr.push(
        <div
          key={"indexTitle emsl" + element.title}
          className={
            "populationRiskMainDiv utilityPadding mb-3 spreadMobilityAreaIndexMainDiv " +
            (element.disabled ? "disabled" : "")
          }
          onClick={() => {
            if (!element.disabled) {
              handleIndexTabClick(index);
            }
            if (index == 0) {
              //changeDataofPerformance("today",comp);
              SetActive('team');
              SetSelection('day');
             
            } else if (index == 1) {
              SetChartLoader(true);
                      SetSelection('day');
        SetActive('all');
              setTimeout(function(){ 
             changeDataEvolution('day','all')
              },1000)
             SetSelection('day');
                //SetActive('all');
            } 
          }}
          style={
            index === indexTitle
              ? {
                  color: riskLevelColor["low"],
                  borderColor: riskLevelColor["low"],
                  backgroundColor: riskBackgroundColor["low"],
                }
              : {}
          }
        >
          <Row>
            <Col lg={6} className="p-r-0">
              <div className="indexBox">
                <div className="indexText">
                  {getTranslatedText(element.title)}
                  {element.desc ? (
                    <React.Fragment>
                      <img
                        alt=""
                        src={helpIcon}
                        onMouseEnter={() => handleMouseEnter(`desc_${index}`)}
                        onMouseLeave={() => handleMouseLeave(`desc_${index}`)}
                      />
                      <div className="indexDesc" id={`desc_${index}`}>
                        {element.desc}
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="riskLevelMainDiv ">
                <div className="riskLevelTitleDiv">
                  {indexLevel[element.id] == "na"
                    ? "--"
                    : indexLevel[element.id]}
                </div>
              </div>
            </Col>
            <Col lg={2}>
              <div className="pinkArrowIconDiv">
                <img
                  src={
                    element.isSelected ? selectedPinkArrowIcon : pinkArrowIcon
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    }

    return arr;
  }


  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  function handleDateSelect(date) {
    if (permission && permission.length > 0) {
      SetLoader(true);
      updateSelectedDate(date);
            // updateEndChartDateValue(date)
    }
  }

  function handleSelectStartDate(date) {
    if (permission && permission.length > 0) {
      //updateStartDateValue(date)
    }
  }

  function handleSelectEndDate(date) {
    //date = new Date(date).setDate(date.getDate() + 1)
    if (permission && permission.length > 0) {
      // updateEndDateValue(date)

      let startDate = moment(dates.start);
      let endDate = moment(date);
      let isBefore = startDate.isBefore(endDate);

      if (isBefore) {
      } else {
        updateToastClass("errorToast");
        toast("End Date Should be Greater Than Start Date.", {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          hideProgressBar: true,
        });
      }
    }
  }



  function changeLanguage(lang) {
    getLanguageTranslation(lang, userSession).then((res) => {
      if (res && res.status >= 200 && res.status <= 200) {
        localStorage.setItem("languageData", JSON.stringify(res.data));
        localStorage.setItem("selectedLanguage", lang);
        props.setSelectedLanguage(lang);
      }
    });
  }




  function capitalizeFirstLetter(string) {
    return string.toLowerCase();
  }

  const isWeekday = (date) => {
        let offWeek = props.weekDays.filter(item=>!item.working).map(el=>el.day);
        let holiday=props.holiday?props.holiday:[]
        const day = moment(date).isoWeekday();
      
        if(offWeek.indexOf(day) > -1 || holiday.find(item=>moment(item.date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD') ) ){
            return     
        }else{
        return day ;
        }        
  };

  function getDataFromTopAreaPerformance(allemp,dept,selection){
    // SetOnBoardEMployee(data)
    // SetEMployeeCost(cost);
    dept= [...dept]
    let endDate = moment(selectedDate).add(addDay,'days').set(endShift)
    let startDate =moment(selectedDate).set(startShift)
    if(selection=='day'){
      startDate = moment(selectedDate).set(startShift);
    }else if(selection=='week'){
      startDate = moment(selectedDate).subtract(7,'days').set(startShift);
    }else if(selection == 'month'){
      startDate = moment(selectedDate).subtract(28,'days').set(startShift);                
    }

    let monthDiff = getMonths( moment(selectedDate).subtract(7, "days").toDate(),moment(selectedDate).toDate(),"week" );
    monthDiff = monthDiff[monthDiff.length - 1];
    SetWeekName(monthDiff && monthDiff.weekName ? monthDiff.weekName : "Week");
   

    SetAllEmployee(allemp);
    let newDept=[];
    for(let i=0;i<dept.length;i++){
      let single={};
      let deptAr = allemp.filter((item) => moment(item.check_in) >= moment(startDate) && 
      moment(item.check_in) < moment(startDate).add(addDay,'days').set(endShift)
      && item.department_name == dept[i].department);
      let singleDept = deptAr.reduce((prev,current,index) =>{
                  return  prev + parseInt(current.cost);
      },0);
      single.regular = deptAr.filter(el=>el.emp_type == 'regular').length;
      single.daily = deptAr.filter(el=>el.emp_type == 'daily').length; 
      
      single.name=dept[i].department;
      single.cost =singleDept;
      let subdept = []
        for(let j=0;j<dept[i].sub_departments.length;j++){
         let item = dept[i].sub_departments[j];
        let name = item;
       let SubdeptAr =allemp.filter((el) => 
       moment(el.check_in) >=moment(startDate)
       && moment(el.check_in) < moment(startDate).add(addDay,'days').set(endShift)
       && el.sub_department_name == item )
      let cost = SubdeptAr.reduce((prev,current,index) =>{
            return  prev + parseInt(current.cost);
        },0);
      let reg= SubdeptAr.filter(el=>el.emp_type == 'regular').length;
      let dy= SubdeptAr.filter(el=>el.emp_type == 'daily').length; 
          subdept.push({name:name,cost:cost,regular:reg,daily:dy})
             

        }

      
      single.sub_department= subdept;
      newDept.push(single);

    }

    let agList = findUniqueAgencies(allemp);

    let ag =[]
    
    for(let i=0;i<agList.length;i++){
      
      let single={};
      let deptAr = allemp.filter((item) => moment(item.check_in) >= moment(startDate) 
      && moment(item.check_in) < moment(startDate).add(addDay,'days').set(endShift)
      && item.agency == agList[i]);
      let singleDept = deptAr.reduce((prev,current,index) =>{
                  return  prev + parseInt(current.cost);
      },0);
      single.regular = deptAr.filter(el=>el.emp_type == 'regular').length;
      single.daily = deptAr.filter(el=>el.emp_type == 'daily').length; 
      
      single.name=agList[i];
      single.cost =singleDept;
      ag.push(single);
    }

    SetAgency(ag);
    SetDepartment(newDept);
      SetLoader(false);
    
  
  }

  useEffect(()=>{
    if(allEmployeee.length> 0 && department.length > 0 && agency.length >0){
      
      if(indexTitle == 1){
        SetChartLoader(true);
        SetSelection('day');
        SetActive('all');
        setTimeout(function(){ 

        changeDataEvolution('day','all');
        },1000);
      }else{
        SetLoader(false);
      }
    }
      
  },[allEmployeee,department,agency])
function findUniqueAgencies(data) {
  const uniqueAgencies = new Set();

  data.forEach(item => {
    const agency = item.agency; // Update this with the actual field name in your data

    if (agency) {
      uniqueAgencies.add(agency);
    }
  });

  return Array.from(uniqueAgencies);
}



function parseDatabyChangeDays(selection){
  SetSelection(selection);
  let dept = [...department];  
  let allemp=[...allEmployeee];
  
  let endDate = moment(selectedDate).add(addDay,'days').set(endShift);
    let startDate =moment(selectedDate).set(startShift);
    if(selection=='day'){

      startDate = moment(selectedDate).set(startShift);
      
    }else if(selection=='week'){
      let weekDay = getMonths(moment(selectedDate).subtract(7,'days').toDate(),moment(selectedDate).toDate(),'week');
      weekDay = weekDay[weekDay.length - 1];
      startDate = moment(weekDay.start?weekDay.start:moment(selectedDate).subtract(7,'days')).set(startShift);
    }else if(selection == 'month'){
      let weekDay = getMonths(moment(selectedDate).subtract(31,'days').toDate(),moment(selectedDate).toDate(),'month');
      weekDay = weekDay[weekDay.length - 1];
      startDate = moment(weekDay.start?weekDay.start:moment( moment(selectedDate).format("YYYY-MM-")+"01 00:00:00")).set(startShift);
    }
   
    SetAllEmployee(allemp);
    let newDept=[];

    for(let i=0;i<dept.length;i++){
      let single={};
      let deptAr = allemp.filter((item) => moment(item.check_in) >= moment(startDate) &&
      moment(item.check_in) < moment(endDate) &&  
      item.department_name == dept[i].name);
      let singleDept = deptAr.reduce((prev,current,index) =>{
                  return  prev + parseInt(current.cost);
      },0);
      single.regular = deptAr.filter(el=>el.emp_type == 'regular').length;
      single.daily = deptAr.filter(el=>el.emp_type == 'daily').length; 
      
      single.name=dept[i].name;
      single.cost =singleDept;
      let subdept = []
      
      for(let j=0;j< dept[i].sub_department.length;j++){
          let item = dept[i].sub_department[j];
          let name = item.name;
          let SubdeptAr =allemp.filter((el) => moment(el.check_in) >=moment(startDate) && el.sub_department_name == item.name )
          let cost = SubdeptAr.reduce((prev,current,index) =>{
                return  prev + parseInt(current.cost);
            },0);
           
      let reg= SubdeptAr.filter(el=>el.emp_type == 'regular').length;
      let dy= SubdeptAr.filter(el=>el.emp_type == 'daily').length; 
          subdept.push({name:name,cost:cost,regular:reg,daily:dy})
      }
      
          
      single.sub_department= subdept;
      newDept.push(single);

    }

    let agList = findUniqueAgencies(allemp);
    
    let ag =[]
    for(let i=0;i<agList.length;i++){
      
      let single={};
      let deptAr = allemp.filter((item) => moment(item.check_in) >= moment(startDate)
      &&      moment(item.check_in) < moment(endDate) 
      && item.agency == agList[i]);
      let singleDept = deptAr.reduce((prev,current,index) =>{
                  return  prev + parseInt(current.cost);
      },0);
      single.regular = deptAr.filter(el=>el.emp_type == 'regular').length;
      single.daily = deptAr.filter(el=>el.emp_type == 'daily').length; 
      
      single.name=agList[i];
      single.cost =singleDept;
      ag.push(single);
    }
   
    SetAgency(ag);

    SetDepartment(newDept);


    
  
}



  return (
    <>
      {permissionAPICall ? (
        permission.length > 0 ? (
          <div className="dashboardComponentMainDiv">
            <Container>
              <Row>
                <Col lg={3} className="m-t-sm">
                  <CommonHeading title={getTranslatedText("Dashboard")} />
                </Col>
                <Col lg={9} className="text-right">
                            {/* <div className="dashboardLanguageMainDiv m-r-md">
                            <DashboardLanguage
                                selectedLangValue={selectedLangValue}
                                changeLanguage={changeLanguage}
                            />
                            
                        </div> */}
                  <div className="commonHeadingDateMainDivParent">
                    <CommonDatePicker
                      selectedDate={selectedDate}
                      handleSelectDate={handleDateSelect}
                      sundaySelect={true}
                                      weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
                    />
                  </div>
                  
                </Col>
              </Row>

                <Row>
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <TopAreaPerformance
                      selectedDate={selectedDate}
                      dates={dates}

                   //   WeekStats={WeekStats}
                     // todayStats={todayStats}
                      //monthStats={monthStats}
                      loader={loader}
                      // weekName={weekName}
                      getDataFromTopAreaPerformance={getDataFromTopAreaPerformance}
                      // checkedIn={checkedIn}
                      // magicRatio={magicRatio}
                      // inFacility={inFacility}
                      // loaderSecondWidget ={loaderSecondWidget}
                      workday={props.workday}
                      startShift={startShift}
                      endShift={endShift}
                      indexTitle={indexTitle}
                      active={active}
                      selection={selection}
                      getMonths={getMonths}
                    />
                  </Col>
                </Row>
              <div className="dashboardGraphAndIndexMainDiv">
                <Row>
                  <Col lg={3} className="p-r-0">
                    {showIndexTab(indexTitleArray)}
                  </Col>

                  <Col lg={9}>
                    <div className="m-l-sm">
                      <Row className="m-t-xs" style={{ marginBottom: "20px" }}>
                        <Col lg={12} className="" style={{ textAlign: "right" }}>
                          <div className="btnINline" style={{ float: "left" }}>
                            {indexTitle == 0 ? (
                              <>

                                <button
                                  
                                  className={
                                    active == "team"
                                      ? "btn btn-pink btn-primary active"
                                      : "btn btn-pink btn-primary"
                                  }
                                  onClick={() => {
                                    
                                    SetActive("team");

                                  }}
                                >
                                  Teams
                                </button>
                                {/* <button  className={active=="day"?'btn btn-pink btn-primary active':'btn btn-pink btn-primary'} onClick={()=>{changeDataofPerformance('day')}} >Daily</button> */}
                                <button className={active == "agency"? "btn btn-pink btn-primary active": "btn btn-pink btn-primary"} onClick={() => { SetActive("agency");}}>Staffing Agencies</button>
                              </>
                            ) : <>
                                <button disabled={loader} className={active == "all"? "btn btn-pink btn-primary active": "btn btn-pink btn-primary"} onClick={() => { SetChartLoader(true);SetActive('all');setTimeout(function(){ changeDataEvolution(selection,'all') },1000) }}>All</button>
                                <button disabled={loader} className={active == "agencies"? "btn btn-pink btn-primary active": "btn btn-pink btn-primary"} onClick={() => {SetChartLoader(true);SetActive('agencies'); setTimeout(function(){ changeDataEvolution(selection,'agencies');},1000) }}>Agencies</button>
                                <button disabled={loader} className={active == "rw_dw"? "btn btn-pink btn-primary active": "btn btn-pink btn-primary"} onClick={() => { SetChartLoader(true);SetActive('rw_dw'); setTimeout(function(){ changeDataEvolution(selection,'rw_dw');},1000)}}>RW / DW</button>
                                <button disabled={loader} className={active == "function"? "btn btn-pink btn-primary active": "btn btn-pink btn-primary"} onClick={() => {SetChartLoader(true);SetActive('function'); setTimeout(function(){ changeDataEvolution(selection,'function');},1000) }}>Function</button>
                                <button disabled={loader} className={active == "sub_function"? "btn btn-pink btn-primary active": "btn btn-pink btn-primary"} onClick={() => {SetChartLoader(true);SetActive('sub_function'); setTimeout(function(){ changeDataEvolution(selection,'sub_function');},1000) }}>Sub Function</button>
                            </>}
                          </div>

                          {indexTitle == 0 ?
                                                    <div className="datePickerSmall">
                                                              <div className="btnINline" style={{ float: "left" }}>
                                <button disabled={loader } className={selection == "day"? "btn btn-pink btn-primary active": "btn btn-pink btn-primary"} onClick={()=>{parseDatabyChangeDays('day')}} >Day</button>
                                <button disabled={loader } className={selection == "week"? "btn btn-pink btn-primary active": "btn btn-pink btn-primary"} onClick={()=>parseDatabyChangeDays('week')} >Week</button>
                                <button disabled={loader} className={selection == "month"? "btn btn-pink btn-primary active": "btn btn-pink btn-primary"} onClick={()=>parseDatabyChangeDays('month')} >Month</button>
</div>
</div>:<div className="datePickerSmall">
                                                              <div className="btnINline" style={{ float: "left" }}>
                                <button className={selection == "day"? "btn btn-pink btn-primary active": "btn btn-pink btn-primary"} onClick={()=>{SetChartLoader(true); SetSelection('day'); setTimeout(function(){ changeDataEvolution('day',active)},1000)}} >Day</button>
                                <button disabled={loader || indexTitle == 1 } className={selection == "week"? "btn btn-pink btn-primary active": "btn btn-pink btn-primary"} onClick={()=>{SetChartLoader(true); SetSelection('week');setTimeout(function(){ changeDataEvolution('week',active)},1000)}} >Week</button>
                                <button disabled={loader || indexTitle == 1 } className={selection == "month"? "btn btn-pink btn-primary active": "btn btn-pink btn-primary"} onClick={()=>{SetChartLoader(true);SetSelection('month'); setTimeout(function(){ changeDataEvolution('month',active)},1000)}} >Month</button>
</div>
</div>} 
                                  


                        </Col>
                                                <Col lg={2}></Col>
                        <Col lg={2} className="p-l-0"></Col>

                      </Row>
                      <Row className="m-t-xs" style={{ marginBottom: "20px" }}>
                        

                          <div style={{ width: "100%",padding:"0px 15px" }} ref={elRef}>
                          {loader?  
                          <div className="loaderLoadMidlle" style={{float:"left",width:"100%",textAlign:"center"}}>
                                <img src={spinnerLoader}/>
                            </div>
                            :
                            indexTitle == 0 && active=="team" ? (
                          
                            <>
                            <div className="headerAreaCost">
                              <Row>
                                <Col lg={4}>Overall :- {selection=='day'?moment(selectedDate).format("DD MMM"):selection=="week"?weekName:moment(selectedDate).format("MMMM")} </Col>
                                <Col lg={4}>Overall Cost {formatNumberWithAbbreviation(department.reduce((prev,current,index) =>{
                  return  prev + parseInt(current.cost);
      },0)) }</Col>
                                <Col lg={4}>Total {department.reduce((prev,current,index) =>{
                  return  prev + parseInt(current.sub_department.reduce((prev2,current2)=>{return prev2 + current2.regular},0) );
      },0)} RW / {department.reduce((prev,current,index) =>{
                  return  prev + parseInt(current.sub_department.reduce((prev2,current2)=>{return prev2 + current2.daily},0) );
      },0)} DW</Col>
                              </Row>
                            </div>
                            <Row>
                              <Col></Col>
                            </Row>
                            {department? DepartmentComponent(department):""}
                            </>
                            
                            ) :indexTitle == 0 && active=="agency" ? (
                          
                            <>
                            <div className="headerAreaCost">
                              <Row>
                                <Col lg={4}>Overall :- {selection=='day'?moment(selectedDate).format("DD MMM"):selection=="week"?weekName:moment(selectedDate).format("MMMM")}</Col>
                                <Col lg={4}>Overall Cost {formatNumberWithAbbreviation(department.reduce((prev,current,index) =>{
                  return  prev + parseInt(current.cost);
      },0)) }</Col>
                                <Col lg={4}>Total {department.reduce((prev,current,index) =>{
                  return  prev + parseInt(current.regular)
      },0)} RW / {department.reduce((prev,current,index) =>{
                  return    prev + parseInt(current.daily)
      },0)} DW</Col>
                              </Row>
                            </div>
                            <Row>
                              <Col></Col>
                            </Row>
                            {allEmployeee?agency.filter(item=>item.cost>0).map(dept=>{
                              return (
                                    <div className="costBodaySubDept costBodaySubDeptAgency" style={{width:"100%"}}>
                                <Row>
                                  <Col lg={4}>{dept.name}</Col>
                                  <Col lg={4}>{formatNumberWithAbbreviation(dept.cost)}</Col>
                                  <Col lg={4}>{`${dept.regular} RW / ${dept.daily} DW`}</Col>
                                </Row>
                              </div>)
                            }) :""}
                            </>
                            
                            ):
                            indexTitle == 1 ? (
                              
                              chartLoader?  
                          <div className="loaderLoadMidlle" style={{float:"left",width:"100%",textAlign:"center"}}>
                                <img src={spinnerLoader}/>
                            </div>:<>
                              <div style={{minHeight:"300px"}}><ChartReport height={300} sortby={'amount'} hideUnd={true} chartData={chartData}  key={"reportV2"} legendEnable={false} /></div>
                              </>
                            ) :""
                           
                            }
                          </div>
                        
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>

          
            <ToastContainer toastClassName={toastClass} />
          </div>
        ) : (
          <Col lg={12}>
            {/* <div className='errorPermission'></div> */}
            <div className="center404">
              {" "}
              <Image404 />
              <h4 style={{ marginTop: "-30px", color: "#2e3552" }}>
                Access Restricted to this page, Please contact administrator
              </h4>
            </div>
          </Col>
        )
      ) : (
        <Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
          <img src={spinnerLoader} />
        </Col>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  language: state.dashboard.selectedLangaugeValue,
});

export default connect(mapStateToProps, { setSelectedLanguage })(
  withRouter(CostManagement)
);
