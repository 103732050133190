import React, { useState, useCallback,useReducer,useEffect,useRef,useLayoutEffect } from 'react'
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { CommonHeading } from "../../common/commonHeading";
import Viewer from '../hooks/Viewer'
//import { cameraPlacements } from  '../hooks/_data'
import spinnerLoader from "../../assets/images/loader.svg";
import moment from "moment";
// import moment from 'moment-timezone';

import { getTranslatedText } from  "../../common/utilities";
import {
  getLanguageTranslation,
  setSelectedLanguage,
} from "../../dashboard/actionMethods/actionMethods";

import { Switch } from 'antd';

import {
  append,
  compose,
  isEmpty,
  map,
  reject,
  intersperse,
  evolve,
  max,
  mapObjIndexed,values, filter
} from 'ramda'

import DashboardLanguage from "../../components/dashboardLanguage";
import CommonDatePicker from "../../common/commonDatePicker";

import { Slider} from 'antd';
import { Select } from 'antd';
import {ReactComponent as Play} from '../../assets/images/circle-play.svg'
import {ReactComponent as Pause} from '../../assets/images/circle-pause.svg'
import LocationIcon from  '../../assets/images/location.png'

import ProductiveIcon from  "../../assets/images/locationProductive.png"
import nonProductiveIcon from "../../assets/images/locationNonProductive.png";
import lessProductiveIcon from "../../assets/images/locationLessProductive.png";

import {getLocationCordinate} from '../../realtimeTracker/actionMethods/actionMethods';

import { getPproductivitylistDetail } from "../../productivity/actionMethods/actionMethods";
import {getEmployeeTracker} from '../actionMethods/actionMethod';

// import TextField from '@mui/material/TextField';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { getPproductivityDetailOverview,
  getIndividualProductivityList } from '../../productivity/actionMethods/actionMethods';

  import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'
import '../style/style.scss'

import '../../realtimeTracker/style/style.scss'
import Scrollbars from 'react-custom-scrollbars';
import 'antd/dist/antd.css';

import CMGBulky from '../../assets/cmg_bulky.json'
import CMGManual from '../../assets/cmg_manual.json'


let dataUniqueLocation=[];
let previousTag={};
let biDirectional=[];

let layer=[];
let dataPush=[];
const Simplr = (props) => {
  const { Option } = Select;
  
  const INITIAL_MODE = '3d'  
  const [mode, setMode] = useState(INITIAL_MODE)
  const [activeTab, SetActiveTab]= useState('');
  const [pointData,SetPointData]=useState([]);
  const [spaceID,SetSpaceID]=useState('');
  const [locationCordinate,SetLocationCordinate]= useState([]);
  const [slug,SetSlug] =useState('');
  const [error,SetError]=useState('');
  const noElevationIn2D = useCallback(value => (mode === '3d' ? value : 0), [
    mode
  ])
  
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
//const slideTooltip=useRef()
  const slideTooltip = useRef(null);
  

  const [width, setWidth] = useState(0);
 
  const autoElevation = map(
    evolve({ position: { elevation: noElevationIn2D } })
  )
  
  const [space, setSpace] = useState()
  
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);
  const [department,SetDeparment] =useState([]);
  const [department_id,SetDepartmentID]=useState('');
  
  const [sub_department,SetSubDeparment] =useState([]);
  const [sub_department_id,SetSubDepartmentID]=useState('');
    
  const [employeeID,SetEmployeeID]=useState(props.match.params && props.match.params.tag?props.match.params.tag:'');
  const [tag_serial,SetTagSerial] = useState('')
  const [employeeAll,SetEmployeeAll]=useState([]);
  const [employeeDefault,SetEmployeeDefault]=useState([]);
  const [loaderEmp,SetLoaderEmp]=useState(false)
  const [start_time,SetStartTime]=useState(props.match.params && props.match.params.hour?[props.match.params.hour]:[]);
  const [end_time,SetEndTime]=useState('');
  const [isloading,Setloading] =useState(true);
  
  const [autoPlay,SetAutoPlay] =useState(false);
  const [productiveLocation,SetProductiveLocation] =useState([])
  const [nonproductiveLocation,SetNonProductiveLocation] =useState([])
  const [lessproductiveLocation,SetLessProductiveLocation] =useState([])

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
  // let GeoWidth= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?2:4;
  // let GeoElevation= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?3:5;
  let GeoWidth= userDetails && parseInt(userDetails.org_id) == 31 || userDetails && parseInt(userDetails.org_id) == 32?2:(userDetails && parseInt(userDetails.org_id) == 33) || (userDetails && parseInt(userDetails.org_id) == 34)?2.5:4;
  let GeoElevation= userDetails && (parseInt(userDetails.org_id) == 31|| parseInt(userDetails.org_id) == 32)?3:(userDetails && parseInt(userDetails.org_id) == 33) || (userDetails && parseInt(userDetails.org_id) == 34)?3:5;

  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 0;
  let lastReqId = null
  const [slideMax,SetSlideMax]=useState(0);
  const [shiftStarttime,SetShiftStartTime] =useState('');
  const[ ShiftEndtime,SetShiftEndTime]=useState('');
  const [shiftBasedDropdown,SetShiftBasedDropdown] =useState([]);  
  const [activeHourDropdown,SetActiveHourDropdown] = useState([]);
  
  const [filterTimeData,SetFilterTimeData] =useState([]);
  const [sucessMessage,SetSucessMessage] =useState(false);

  const [dailyEmpDefault,SetDailyEmpDefault] =useState([]);
  const [dailyEmpDepartment,SetDailyEmpDepartment] =useState([]);
  const [regularEmpDepartment,SetRegularEmpDepartment] =useState([]);
  const [enable,SetEnable] =useState(false);
  const [startBigger,SetStartBigger]=useState(false);  
  const onReady = useCallback(space => setSpace(space), [])
  const elementRef =useRef(null);
  const [heightMap,SetHightMap]= useState(0);
  const [dualLocationList,SetDualLocationList]=useState([])
  const [forward,SetForward]=useState(true);
  const [oldPosition,SetOldPosition]=useState(0);
  const [lagFreeFlag,SetLagFreeFlag]=useState(false);
  const [selectedStartTime,SetSelectedStartTime]=useState([])
  const timeZone = 'Asia/Jakarta';  
  let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  

  useEffect(() => {
    
    if(elementRef && elementRef.current){
     
      SetHightMap(elementRef.current.getBoundingClientRect().height);
      setWidth(elementRef.current.offsetWidth);
    }
    
    
  }, [elementRef,spaceID,isloading]);

useEffect(()=>{
if(props.match.params && props.match.params.tag ){
  let useDaily= dailyEmpDefault.find(item=>item.tag_serial==props.match.params.tag);  
  if(useDaily){
    SetSlug('daily_worker')
    SetDepartmentID(useDaily.department)
  }else{
    let useRegular = employeeAll.find(item=>item.tag_serial==props.match.params.tag);  
    SetSlug('employee');
    if(useRegular){
      SetDepartmentID(useRegular.department)
    }
  }


}
},[props.match.params,employeeAll,dailyEmpDefault])

  const [icons, dispatchIcon] = useReducer((icons, action) => {
    switch (action.type) {
      case 'add':
        return [...icons, action.icon]
      case 'update':
        return icons.map(pt =>
          pt.id === action.id ? { ...pt, ...action.updates } : pt
        )
      case 'remove':
        return reject(r => r.id === action.id)(icons)
      default:

    }
  },[])

  const time = [
    {time:"12:00 AM",value:"00:00:00",intVal:0 },
    {time:"01:00 AM",value:"01:00:00",intVal:1},
    {time:"02:00 AM",value:"02:00:00",intVal:2},
    {time:"03:00 AM",value:"03:00:00",intVal:3},
    {time:"04:00 AM",value:"04:00:00",intVal:4},
    {time:"05:00 AM",value:"05:00:00",intVal:5},
    {time:"06:00 AM",value:"06:00:00",intVal:6},
    {time:"07:00 AM",value:"07:00:00",intVal:7},
    {time:"08:00 AM",value:"08:00:00",intVal:8},
    {time:"09:00 AM",value:"09:00:00",intVal:9},
    {time:"10:00 AM",value:"10:00:00",intVal:10},
    {time:"11:00 AM",value:"11:00:00",intVal:11},
    {time:"12:00 PM",value:"12:00:00",intVal:12},
    {time:"01:00 PM",value:"13:00:00",intVal:13},
    {time:"02:00 PM",value:"14:00:00",intVal:14},
    {time:"03:00 PM",value:"15:00:00",intVal:15},
    {time:"04:00 PM",value:"16:00:00",intVal:16},
    {time:"05:00 PM",value:"17:00:00",intVal:17},
    {time:"06:00 PM",value:"18:00:00",intVal:18},
    {time:"07:00 PM",value:"19:00:00",intVal:19},
    {time:"08:00 PM",value:"20:00:00",intVal:20},
    {time:"09:00 PM",value:"21:00:00",intVal:21},
    {time:"10:00 PM",value:"22:00:00",intVal:22},
    {time:"11:00 PM",value:"23:00:00",intVal:23},   
  ]

  const [polylines, dispatchPolyline] = useReducer((polylines, action) => {
  
    switch (action.type) {

      case 'addCoordinate':

        return polylines.map(r =>
          r.id === action.id
            ? { ...r, coordinates: [...r.coordinates, action.coordinate] }
            : r
          
        )
      case 'updateCoordinates':
        return polylines.map(r =>
          r.id === action.id ? { ...r, coordinates: action.coordinates } : r
        )
      case 'removePolyline':
        return reject(r => r.id === action.id)(polylines)
      default:

    }
  }, [ {
    "id": "9e2f2088-01f9-511b-9740-207f8a28870f",
    "name": "W3",
    "coordinates": []
  }])

  useEffect(() => {
    if (!space) {
      return
    }
    setTimeout(function(){
      SetLagFreeFlag(true);
    },3000)
  }, [space]);

  useEffect(() => {
   
    let perm= props.pages.filter(el=>el.sub_page_name && el.sub_page_name =="Employee Tracker");
   
    if(perm){
        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{
    }
},[props.pages]);

function isInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}
useEffect(()=>{
  
  if(permission.length > 0){
    let geoLocation =[]
    getLocationCordinate(userSession,org_id,sub_org_id).then(res=>{
      if(res.status==200){
        if(parseInt(org_id) == 30 && parseInt(sub_org_id)==1){
            SetSpaceID("8caa8c30-c866-436e-a03c-962c1879841e")
        }else if(parseInt(org_id) == 30  && parseInt(sub_org_id)==2){
            SetSpaceID("334db83f-5994-48e5-b08f-b05587c3d143")
        }else{
            SetSpaceID(res.data.space_id)  
        }
               if(parseInt(org_id) == 30 && parseInt(sub_org_id)==1){
                   
                    for(let i=0;i<CMGBulky.length;i++){
                      let dataFind= res.data.location.find(el=>el.name.toLowerCase().trim() ==CMGBulky[i]['Location  Name'].toLowerCase().trim() )
                   
                      if(dataFind){
                        geoLocation.push(dataFind);
                      }

                    }
                  }else if(parseInt(org_id) == 30  && parseInt(sub_org_id)==2){
                   
                      for(let i=0;i<CMGManual.length;i++){

                      let dataFind= res.data.location.find(el=> el.name.toLowerCase().trim() ==CMGManual[i]['Location  Name'].toLowerCase().trim() )
                      if(dataFind){
                        geoLocation.push(dataFind);
                      }
                    }

                  }else{
                    
                    geoLocation = res.data.location
                  }


                  
                  SetLocationCordinate(geoLocation);
        Setloading(false);

      }

    })
  }
},[permission])
useEffect(()=>{
 
  SetDepartmentID('');
  SetDeparment([]);
  SetSubDeparment([]);
  SetSubDepartmentID([]);
  if(props.match.params.hour == start_time && props.match.params.tag==employeeID){

  }else{
    SetEmployeeID('')
  }
  
  SetDailyEmpDefault([]);
  SetEmployeeAll([])
  SetDailyEmpDepartment([]);
  SetSlug('')
  if(permission.length > 0){
    

    let arr=[];

    let requestBody={};
    requestBody.startdate=getDateFormat(selectedDate)
    requestBody.enddate=getDateFormat(selectedDate)
    requestBody.slug='employee'
    let dept=[]
    let catIndex=0;
    let empList= [];

    
    getPproductivitylistDetail(requestBody,userDetails.session,org_id,sub_org_id).then(res=>{
     
      if (res.status==200){
      


        Object.keys(res.data).forEach((key) => {
          
          let element=res.data[key];
          let child= [];
          let childName='';      
          let parentLength=0;
          let subdept=[]
          Object.keys(element).forEach((key2) => {
        
            if(isInt(key2)){
          
              
            }else{
              
              childName=key2  
              subdept.push(key2);
              for (let c = 0;c<element[key2].length;c++){
                parentLength++;
                element[key2][c].department=key;
                element[key2][c].sub_department=key2;
                empList.push(element[key2][c]);
              }
              child.push({name:key2,data:element[key2] })
            }

          });

          if(childName!==''&& Number.isInteger(childName) ===false ){
            arr.push({name:key,child:child})   
            dept.push({name:key,sub_department:subdept}) 
          }else{

            dept.push({name:key,sub_department:subdept}) 
            for (let i=0;i<element.length;i++){
              element[i].department=key;
              element[i].sub_department='';
              empList.push(element[i]);
            }
            arr.push({name:key,data:element})            
          }
         });


      }


      // SetShowData(arr);
      // SetShowDataDefault(arr);
      if(empList.find(item=>item.tag_serial == employeeID )){
        SetSlug('regular_worker');
      }
      SetEmployeeAll(empList)
      SetEmployeeDefault(empList)
      //SetDeparment(dept)
      SetRegularEmpDepartment(dept)
  
      
    })
  
    let requestBody2={};
    requestBody2.startdate=getDateFormat(selectedDate)
    requestBody2.enddate=getDateFormat(selectedDate)
    requestBody2.slug='daily_worker'
    let deptDaily=[]
    let catIndexDaily=0;
    let empListDaily= [];
    getPproductivitylistDetail(requestBody2,userDetails.session,org_id,sub_org_id).then(res=>{
 
      if (res.status==200){
      


        Object.keys(res.data).forEach((key) => {
          
          let element=res.data[key];
          let child= [];
          let childName='';      
          let parentLength=0;
          let subdept=[]
          Object.keys(element).forEach((key2) => {
        
            if(isInt(key2)){
          
              
            }else{
              
              childName=key2  
              subdept.push(key2);
              for (let c = 0;c<element[key2].length;c++){
                parentLength++;
                element[key2][c].department=key;
                element[key2][c].sub_department=key2;
                empListDaily.push(element[key2][c]);
              }
              child.push({name:key2,data:element[key2] })
            }

          });

          if(childName!==''&& Number.isInteger(childName) ===false ){
            arr.push({name:key,child:child})   
            deptDaily.push({name:key,sub_department:subdept}) 
          }else{

            deptDaily.push({name:key,sub_department:subdept}) 
            for (let i=0;i<element.length;i++){
              element[i].department=key;
              element[i].sub_department='';
              empListDaily.push(element[i]);
            }
            arr.push({name:key,data:element})            
          }
         });


      }
      if(empListDaily.find(item=>item.tag_serial == employeeID )){
        SetSlug('daily_worker');
      }

      SetDailyEmpDefault(empListDaily)
      SetDailyEmpDepartment(deptDaily)
  
      
    })



  }

},[permission,selectedDate])  

useEffect(()=>{


  if(props.match.params.hour == start_time[0] && props.match.params.tag==employeeID){

  }else{
    SetEmployeeID('')
    SetDepartmentID('');
    SetSubDepartmentID('');

  }
  
  let dept = slug=='employee'?regularEmpDepartment:dailyEmpDepartment
  // SetDepartmentID('');
  SetDeparment(dept);
  

},[slug,regularEmpDepartment,dailyEmpDepartment])


useEffect(()=>{
  if(permission.length > 0){
    let sub=[];  
    let subDept=[];
    SetSubDeparment([]);
    //SetEmployeeID('')
    if(props.match.params.hour == start_time &&  props.match.params.tag==employeeID){
      let subName = slug=='employee'?employeeDefault.filter(item=>item.tag_serial== employeeID)?.sub_department:dailyEmpDefault.filter(item=>item.tag_serial== employeeID)?.sub_department;
      
      SetSubDepartmentID(subName?subName:'')

    }else{
      SetEmployeeID('')
      SetSubDepartmentID('')
   
      
    }
      let sub2= slug=='employee'?department.filter(item=>item.name.toString().toLowerCase().includes(department_id.toLowerCase()) ):dailyEmpDepartment.filter(item=>item.name.toString().toLowerCase().includes(department_id.toLowerCase()) )
      for (let j=0;j<sub2.length;j++){
      subDept = subDept.concat(sub2[j].sub_department);
    }  
  if(subDept.length > 0){
  SetSubDeparment(subDept);  
  }   

  let empList2 = slug=='employee'?employeeDefault.filter(item=>item.department== department_id):dailyEmpDefault.filter(item=>item.department== department_id)
  SetEmployeeAll(empList2);
  }
},[permission,department_id])

useEffect(()=>{
  if(permission.length > 0){
    let sub=[];  
    let subDept=[];
    //SetEmployeeID('')
    if(props.match.params.hour == start_time & props.match.params.tag==employeeID){

    }else{
      SetEmployeeID('')
    }
    let empList2 = slug=='employee'?employeeDefault.filter(item=>item.department== department_id && item.sub_department.toString().toLowerCase().includes(sub_department_id=='all'?'':sub_department_id.toLowerCase()) ):dailyEmpDefault.filter(item=>item.department== department_id && item.sub_department.toString().toLowerCase().includes(sub_department_id=='all'?'':sub_department_id.toLowerCase()) )
   
    SetEmployeeAll(empList2,department_id); 
  }
},[permission,sub_department_id])



useEffect(()=>{


  if(permission.length > 0 && locationCordinate.length > 0 && (dailyEmpDefault.length> 0 || employeeDefault.length> 0) && employeeID!='' ){


    let requestBody={} ;
    requestBody.startdate= getDateFormat(selectedDate)
    requestBody.enddate= getDateFormat(selectedDate)
    requestBody.worker_id= employeeID

    
    if(employeeDefault.filter(item=>item.worker_id == employeeID ).length>0){
      requestBody.slug= 'employee'
      
    }else if(dailyEmpDefault.filter(item=>item.worker_id == employeeID )){
      requestBody.slug= 'daily_worker'
    }
    SetShiftBasedDropdown([])
    setCount(0)
    SetShiftEndTime('')
    // SetAutoPlay(false);
    SetShiftStartTime('')
   // SetStartTime('')
   if(props.match.params.hour == start_time && props.match.params.tag == employeeID){

   }else{
    SetStartTime([])
   }
    SetFilterTimeData([])
    
    SetDefaultPoly([])
    SetSucessMessage(false)
    SetStartBigger(false);
    let stbigger =false;
    if(space){ 
    dispatchPolyline({
      type: 'updateCoordinates',
      id: "9e2f2088-01f9-511b-9740-207f8a28870f",
      coordinates:[]
    })
  }
    let prod =[];
    let nonProd=[];
    let lessProd = [];

    for(let i= 0;i<productiveLocation.length;i++){

      updateDataLayer(productiveLocation[i],LocationIcon)
    }
    for(let i= 0;i<nonproductiveLocation.length;i++){
     
      updateDataLayer(nonproductiveLocation[i],LocationIcon)
    }
    for(let i= 0;i<lessproductiveLocation.length;i++){
      updateDataLayer(lessproductiveLocation[i],LocationIcon)
    }
    let shiftHour=[];
  getPproductivityDetailOverview(requestBody,userSession,org_id,sub_org_id).then(res=>{
    if(res.shift){
   
      let shift = res.shift && typeof res.shift == "object"?res.shift:res.shift?JSON.parse(res.shift):[]
      let startSft= res.shift?shift.start_time.split(':'):null
      let endSft= res.shift?shift.end_time.split(':'):null
      let st = parseInt(startSft && startSft[0]?parseInt(startSft[1]) > 0 && parseInt(startSft[0])!=23 ?parseInt(startSft[0]):parseInt(startSft[0]):0);
      let et = parseInt(endSft && endSft[0]?parseInt(Math.ceil(endSft[1])) > 0 && parseInt(endSft[0])!=23 ?parseInt(endSft[0]) + 1:parseInt(endSft[0]):0);
      
      let oft=[]
      
      let smaller=st>et?et:st;
      let bigger = st>et?st:et;
      
      
      SetShiftStartTime(startSft?startSft[0]+":"+startSft[1]:'-');
      SetShiftEndTime(endSft?endSft[0]+":"+endSft[1]:'-');
      
      for(let i=0;i<24;i++){
        if(st > et){
          SetStartBigger(true);
          stbigger= true;
          if( (smaller <= i) && (bigger > i) ){

            }else{
                shiftHour.push(i);
            }
        }else{
          SetStartBigger(false);
          stbigger= false;
            if( (smaller <= i) && (bigger > i) ){
                shiftHour.push(i);    
            }
        }
    }        

  
     let sDate= moment(requestBody.startdate).set({hour:st}).toDate();
          let eDate= moment(requestBody.startdate).set({hour:et}).toDate();
          if(st > et && props.workday.start_hour!="00:00:00" ){
              eDate = moment(requestBody.startdate).add(1,'days').set({hour:et}).toDate();
          }
      let allHour =  fetchAllHour(sDate,eDate);
    
      SetShiftBasedDropdown(allHour.map(el=>el.hour))
      SetActiveHourDropdown(allHour);
      if(parseInt(startSft?startSft[0]:10) == 0 && parseInt(endSft?endSft[0]:11) < 12){
        stbigger= true;
        SetStartBigger(true);
      }
      

    }

    getIndividualProductivityList(requestBody,userSession,org_id,sub_org_id).then(res=>{
   
      if(res.data && res.data.productive){
        let data = res.data.productive;
        for(let i=0;i<data.length;i++){
          let item = locationCordinate.find(item=> item.tag_serial == data[i].tag_serial)
          if(item){prod.push(item);updateDataLayer(item,ProductiveIcon)}  
        }          
      }
      if(res.data && res.data.less_productive){
        let data = res.data.less_productive;
        for(let i=0;i<data.length;i++){
          let item = locationCordinate.find(item=> item.tag_serial == data[i].tag_serial)
          if(item){lessProd.push(item);updateDataLayer(item,lessProductiveIcon)}  
        }          
      }
      if(res.data && res.data.non_productive){
        let data = res.data.non_productive;
        for(let i=0;i<data.length;i++){
          let item = locationCordinate.find(item=> item.tag_serial == data[i].tag_serial)
          if(item){nonProd.push(item);updateDataLayer(item,nonProductiveIcon)}  
        }          
      }        
      SetProductiveLocation(prod);
      SetNonProductiveLocation(nonProd);
      SetLessProductiveLocation(lessProd);  

      
      if(props.match.params.hour == 'all' && props.match.params.tag == employeeID){
       
        SetStartTime(shiftHour);
        filterResultHandler(employeeID,shiftHour,stbigger)
      }
      else if(props.match.params.hour == start_time && props.match.params.tag == employeeID){
        filterResultHandler(employeeID,[start_time],stbigger)
      }

      //shiftHour

    })


    
  })

  }
  
},[permission,employeeID,locationCordinate,dailyEmpDefault,employeeDefault])

    function fetchAllHour(start,end){
        const startDate = start;
const endDate = end; // Assuming end_time is on the next day

const hourArray = [];

while (startDate < endDate) {
  const hourString = moment(startDate).format('H');
  hourArray.push({hour:hourString,date:moment(startDate).format('YYYY-MM-DD')});

  startDate.setHours(startDate.getHours() + 1);
}
return hourArray;
    }

useEffect(()=>{ 


},[permission,employeeID,start_time,department_id,sub_department_id,slug])

// useEffect(() => {
//   if(slideTooltip.current){
    
//   }
  
// }, [slideTooltip]);

function removeLayerSmplrSpace(){
  
    for(let i=0;i<layer.length;i++){
  
      let poly=[];
  
      space.removeDataLayer(layer[i].id);
      space.removeDataLayer(layer[i].polyID);
  
      setCount(0)
    }
  
    let data = [...locationCordinate];
    
    for (let i = 0;i<data.length;i++){
    
          let url = LocationIcon
        if(productiveLocation.indexOf(data[i])!=-1){
          
          url = ProductiveIcon
        }
        else if(nonproductiveLocation.indexOf(data[i])!=-1){
          
          url = nonProductiveIcon
        }
        else if(lessproductiveLocation.indexOf(data[i])!=-1){
          
          url = lessProductiveIcon
        }
        
        updateDataLayer(locationCordinate[i],url)
      }        
    }



  function handleDateSelect(date) {
    updateSelectedDate(date);
    SetSlug('');
    SetStartTime([]);

      removeLayerSmplrSpace()    
             setCount(0);
           SetSucessMessage(false);
           SetLoaderEmp(false);
       SetDefaultPoly([])
    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }

  function AddDataLayer(data){

    if(space){
    space.addDataLayer({
      id: data.id,
      type: 'icon',
    
      data: [{
        position: data.position, 
      }],
      icon: {
        url: data.url,
        width: 96,
        height: 96
      },
      width: GeoWidth,
      anchor: 'top',
      tooltip: d => data.tooltip,
      onClick: d=> filterResultLocation(data.tooltip),
    })
  }
}

function convertTimeZone(time){
 const utcTimeString = time;
const utcDateTime = new Date(utcTimeString + "Z"); // Append 'Z' to indicate UTC time

const indonesiaOptions = { timeZone: "Asia/Jakarta" };
const indonesiaTimeString = utcDateTime.toLocaleString("en-US", indonesiaOptions);

return moment(indonesiaTimeString).format('hh:mm:ss A')

} 

  function updateDataLayer(data,url,width,widthSize,inc){
    inc=inc?inc:0;
   

    if(space){
    if(url){
      space.updateDataLayer({
        id: data.unique_loc_id,
        data: [{
          position: data.position, 
        }],
        icon: {
          url: url,
          width: width?width:96,
          height: width?width:96
        },
        width: data.widthSize?data.widthSize:GeoWidth,
        anchor: 'top',
        tooltip: d => data.name +" : "+moment.utc(inc*1000*30).format("HH:mm:ss"),
        onClick: d=> filterResultLocation(data.name),
      })
    
    }else{
    
      space.updateDataLayer({
        id: data.unique_loc_id,
        data: [{
          position: data.position, 
        }],
        width: widthSize?widthSize:GeoWidth,
        anchor: 'top',
        tooltip: d => data.name +" : "+moment.utc(inc*1000*30).format("HH:mm:ss"),
        onClick: d=> filterResultLocation(data.name),
      })
    }
  }

    
  }

  function filterResultLocation(name){

  }
  

  
  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(()=>{
    if (!space) {
      return
    }
    let data = [...locationCordinate] 
    if(data.length>0){


      for (let i = 0;i<data.length;i++){
       
      
        let arr={};
        arr.type='add'
        arr.icon={}
        arr.id=data[i].unique_loc_id;
        arr.tooltip=data[i].name 
        arr.position= data[i].position
        // arr.position.elevation=GeoElevation
        if(arr.position && arr.position.elevation){
          arr.position.elevation=GeoElevation
        }
        arr.name = data[i].name
        arr.width = 96

        arr.url = LocationIcon
        if(productiveLocation.indexOf(data[i])!=-1){
         
          arr.url = ProductiveIcon
        }
        else if(nonproductiveLocation.indexOf(data[i])!=-1){
         
          arr.url = nonProductiveIcon
        }
        else if(lessproductiveLocation.indexOf(data[i])!=-1){
         
          arr.url = lessProductiveIcon
        }
        arr.widthSize=GeoWidth
        //dispatchIcon(arr);
        AddDataLayer(arr)

      }
    }    
  },[locationCordinate,spaceID,space])


  useEffect (() => {
    if(permission.employee=='False'){
      return
    }

    if (!space) {
      return
    }
 
    space.addDataLayer({
      id: 'icons',
      type: 'icon',
      data: autoElevation(icons),
      icon: {
        url: LocationIcon,
        width: 512,
        height: 512
      },
      width: GeoWidth,
      anchor: 'top',
      tooltip: d => d.name,
    })
    
  }, [space, icons, autoElevation,permission])

  



  
  const  [marks,SetMark]  = useState([]) 
  const [marks2,SetMark2] =useState([]) 
  






  const [defaultPoly,SetDefaultPoly] = useState([])

  const [currentCount, setCount] = useState(0);
  const randomcolor = ['#000','#d95780','#51ac30','#101535','#b8cdda','#ffa500','#ff0000']

useEffect(() => {
  if (!space) {
    return
  }
  let ctPosition  = [...dualLocationList];
  let grayLayer=[{
    "id": "9e2f2088-01f9-511b-9740-207f8a2887-gray",
    "name": "W3",
    "coordinates": []
}]
  let yellowLayer=[{
    "id": "9e2f2088-01f9-511b-9740-207f8a2887-yellow",
    "name": "W3",
    "coordinates": []
}]
let redLayer=[{
  "id": "9e2f2088-01f9-511b-9740-207f8a2887-yellow",
  "name": "W3",
  "coordinates": []
}]
  
for(let i=0;i<layer.length;i++ ){
  space.removeDataLayer(layer[i].id);
  space.removeDataLayer(layer[i].polyID);
}
layer=[];
let oldCount = oldPosition
  let locationList = dualLocationList.filter(item => item.position > oldPosition);
 
for(let i=0;i<locationList.length;i++ ){

  if(locationList[i].position <=currentCount){

    let polyCord= [];
      polyCord.push(locationList[i].old);
      polyCord.push(locationList[i].new);
      
      let count= defaultPoly.length;
      let poly=[{
        "id": "9e2f2088-01f9-511b-9740-207f8a2887"+i,
        "name": "W3",
        "coordinates": polyCord
    }]
  let countPosition = locationList[i].position;    
  let oldserial = locationList[i].old.tag_serial;    
  let countFilter = ctPosition.filter(item=> item.position <= countPosition);  

  if(countFilter.length> 0){

   
    countFilter  = countFilter.filter(item=>
        (item.new.tag_serial ==locationList[i].new.tag_serial && oldserial == item.old.tag_serial) ||   
        (item.new.tag_serial ==oldserial && item.old.tag_serial == locationList[i].new.tag_serial)
        ) ;
      }


    if(countFilter.length<= (( (120)*5)/100) ){

      polyCord[0].x=polyCord[0].x
      polyCord[0].y=polyCord[0].y
      polyCord[0].levelIndex=polyCord[0].levelIndex
      polyCord[0].elevation=GeoElevation - 0.5;
    
      polyCord[1].x=polyCord[1].x
      polyCord[1].y=polyCord[1].y
      polyCord[1].levelIndex=polyCord[1].levelIndex
      polyCord[1].elevation=GeoElevation;
      grayLayer[0].coordinates.push(...polyCord)
    }else if(countFilter.length > ((120*5)/100) && countFilter.length <=((120*10)/100)){
      polyCord[0].x=polyCord[0].x
      polyCord[0].y=polyCord[0].y
      polyCord[0].levelIndex=polyCord[0].levelIndex
      polyCord[0].elevation=GeoElevation + 1;
      polyCord[1].x=polyCord[1].x
      polyCord[1].y=polyCord[1].y
      polyCord[1].elevation=GeoElevation + 1
      polyCord[1].levelIndex=polyCord[1].levelIndex
      yellowLayer[0].coordinates.push(...polyCord)
    }else{
      polyCord[0].x=polyCord[0].x
      polyCord[0].y=polyCord[0].y
      polyCord[0].levelIndex=polyCord[0].levelIndex
      
      polyCord[0].elevation=GeoElevation + 2;
      polyCord[1].x=polyCord[1].x
      polyCord[1].y=polyCord[1].y
      polyCord[1].elevation=GeoElevation + 2;
      polyCord[1].levelIndex=polyCord[1].levelIndex
      redLayer[0].coordinates.push(...polyCord)
    } 
    }
  }

  if(grayLayer[0].coordinates.length> 0){
    
    
    layer.push({
      "id": grayLayer[0].id,
      "polyID":'polylines-grey',
  
  });  
    space.addDataLayer({
        id: 'polylines-grey',
        type: 'polyline',
        data: compose(
          map(
            evolve({ coordinates: map(evolve({ elevation: noElevationIn2D })) })
          ),
          reject(p => isEmpty(p.coordinates))
        )(grayLayer),
        shape: 'triangle',
        cap:true,
        stepSize:15,
        scale: 0.5,
        color:'#413d3d',
        tooltip: d => d.name,
      })
  }
  if(yellowLayer[0].coordinates.length> 0){      
    layer.push({
      "id": yellowLayer[0].id,
      "polyID":'polylines-yellow',
  });  
    space.addDataLayer({
        id: 'polylines-yellow',
        type: 'polyline',
        data: compose(
          map(
            evolve({ coordinates: map(evolve({ elevation: noElevationIn2D })) })
          ),
          reject(p => isEmpty(p.coordinates))
        )(yellowLayer),
        shape: 'triangle',
        cap:true,
        stepSize:15,
        scale: 0.5,
        color:'#cf8c12',
        tooltip: d => d.name,
      })
  }
  if(redLayer[0].coordinates.length> 0){      
    layer.push({
      "id": redLayer[0].id,
      "polyID":'polylines-red',
  });  
  space.addDataLayer({
      id: 'polylines-red',
      type:'polyline' ,
      data: compose(
        map(
          evolve({ coordinates: map(evolve({ elevation: noElevationIn2D })) })
        ),
        reject(p => isEmpty(p.coordinates))
      )(redLayer),
      shape: 'triangle',
      cap:true,
      stepSize:15,
      scale: 0.5,
      color:'#9c0808',
      tooltip: d => d.name,
  })
  }
  

}, [space,dualLocationList,currentCount, noElevationIn2D])

  

  const timer = () => {
    setCount(currentCount + 1);    
  }

  useEffect(() => {

        if (!space) {

          return
        }   
        if (currentCount > (defaultPoly.length) || autoPlay===false || loaderEmp) {
        
            return;
        }
        const id = setInterval(timer, 500);

         let line = [...polylines];
         let cord = defaultPoly.slice(0,(currentCount + 1)); 
        let datatimeData = [...filterTimeData] 
        
          if(defaultPoly[currentCount]){          
            
            
            
            if(enable){
              let previousData = datatimeData[datatimeData.length - 1]?datatimeData[datatimeData.length - 1]:{name:""}

              if(defaultPoly[currentCount].name != previousData.name ){
                defaultPoly[currentCount].time_spent = 30000; 
                
                SetFilterTimeData(current => [...current, defaultPoly[currentCount]]);
                    
              }else{
                let old= [...filterTimeData];
                if(datatimeData[datatimeData.length - 1]){
                  datatimeData[datatimeData.length - 1].time_spent = datatimeData[datatimeData.length - 1].time_spent + 30000;
                  SetFilterTimeData(datatimeData);
                    
                }else{
                
                }
                
              }
            }else{
              SetFilterTimeData(current => [...current, defaultPoly[currentCount]]);
                                    
            }  



          let sizeUpdate= cord.filter(item=>item.tag_serial == defaultPoly[currentCount].tag_serial);
          let currentLocation = locationCordinate.find(item=>item.tag_serial==defaultPoly[currentCount].tag_serial);
          if(currentLocation){
            // updateDataLayer(currentLocation,'','',(4+sizeUpdate.length/100),sizeUpdate.length==0?1:sizeUpdate.length + 1)
            updateDataLayer(currentLocation,'','',(GeoWidth+sizeUpdate.length/100),sizeUpdate.length==0?1:sizeUpdate.length )
            
           }
        }

          dispatchPolyline({
            type: 'updateCoordinates',
            id: line[0].id,
            coordinates:cord
          })
      
       return () => clearInterval(id);
      },[currentCount,space,autoPlay]);

      useEffect(() => {

        if (!space) {

          return
        }   
        if(autoPlay){

        }else{
          let line = [...polylines];
          //let cord = defaultPoly.slice(0,currentCount+1);
          let cord = defaultPoly.slice(0,(currentCount));
          

          let filterData = defaultPoly.slice(0,(currentCount));
          
      
            //SetFilterTimeData(filterData);

            if(enable){
              //here formattion occur filterData
              let unqiueLoc=[];
              let prevName='';
              for(let i= 0;i<filterData.length;i++){
                 
                  if(filterData[i].name!=prevName){
                    prevName = filterData[i].name
                    filterData[i].time_spent = 30000;
                    unqiueLoc.push(filterData[i])
                  }else{
                    filterData[i - 1].time_spent = filterData[i - 1].time_spent + 30000;
                  }
              }
              SetFilterTimeData(unqiueLoc);
                                   

            }else{
              SetFilterTimeData(filterData);
                                   
            }


            for (let i = 0;i<dataUniqueLocation.length;i++){
              let filterLength= locationCordinate.filter(item => item.name == dataUniqueLocation[i]) 
              let sizeUpdate= cord.filter(item=>item.name == dataUniqueLocation[i]);
              let currentLocation = locationCordinate.find(item=>item.name == dataUniqueLocation[i]);
              if(currentLocation){
                // updateDataLayer(currentLocation,'','',(4+sizeUpdate.length/40),sizeUpdate.length==0?1:sizeUpdate.length + 1)
                updateDataLayer(currentLocation,'','',(GeoWidth+sizeUpdate.length/40),sizeUpdate.length==0?1:sizeUpdate.length )
              }
            }

            
            dispatchPolyline({  
              type: 'updateCoordinates',
              id: line[0].id,
              coordinates:cord
            })
          }
          
      },[currentCount,space,autoPlay]);



      function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }
        
    useEffect(() => {
      if (props.language) {
        updateSelectedLangValue(props.language);
      }
    }, [props.language]);

    
  
  
    const handleDate = (date, type) => {
      // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
    };

    function changeLanguage(lang) {
      getLanguageTranslation(lang).then((res) => {
        if (res && res.status >= 200 && res.status <= 200) {
          localStorage.setItem("languageData", JSON.stringify(res.data));
          localStorage.setItem("selectedLanguage", lang);
          props.setSelectedLanguage(lang);
        }
      });
    }

    function changeTab(name){
      SetActiveTab(name);
    }
    function HandleRange(e){
      if(currentCount> e){
        SetForward(false);
      }else{
        SetForward(true);
      }
      setCount(e);

      // SetAutoPlay(false);       
    }
   function autoPlayhandler(){
    // SetAutoPlay(!autoPlay)
    if(currentCount > icons.length){
      //setCount(0);
    }else{
      
    }
   }

   function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  

  function filterResultHandler(tag=employeeID,stimeInput=start_time,bigTimer=startBigger){


  dataUniqueLocation =[];
  
  let previousLocation=[]
  let dualLocation=[]
  let inc=0;
  let incCount=0;
  SetLoaderEmp(true);
  setCount(0);
  let arr = [];
  let locationPoint = [...locationCordinate];

  //let data = [...dummydata]
  let mk = []
  let mk2 = []
  SetError('');
  SetFilterTimeData([])

  SetSucessMessage(false);
let stime = stimeInput.map(el=>activeHourDropdown.find(item=>item.hour == el));
  
for(let i=0;i<stime.length;i++){


    let requestBody={}
    
    requestBody.worker_id= employeeID
    requestBody.date= stime[i].date?getDateFormat(stime[i].date):getDateFormat(selectedDate);

    let stTimeBody = stime[i]?parseInt(stime[i].hour):parseInt(start_time[i]);


   let endTime = stTimeBody+ 1;
    requestBody.start_time = stTimeBody+":00:00"
    requestBody.end_time =(stTimeBody==23?"00":stTimeBody + 1)+":00:00";
    requestBody.work_day = moment(selectedDate).format("YYYY-MM-DD")
    const diff =  moment(endTime) - moment(stTimeBody) ;
      let aMs = moment(stTimeBody, 'HH:mm:ss').format('x')
      let bMs = moment(endTime, 'HH:mm:ss').format('x')
  
      
    let difference =   bMs - aMs

 
  
    
 
    getEmployeeTracker(requestBody,userSession,org_id,sub_org_id).then(res=>{
      inc++;
      if(res.status==200){
  

       let data = res.data[tag_serial]?res.data[tag_serial]:res.data;
      //  let data = res.data['01D89D47E1BEB7']?res.data['01D89D47E1BEB7']:res.data;
    
        let locName='';
        if(data.length> 0){
          data = data.sort((a,b) => {
            let aMs = moment(Object.values(a)[0])
            let bMs = moment(Object.values(b)[0])
    
            return    aMs - bMs
          })
        }

        Object.keys(data).forEach((key) => {

          let findPOs = locationPoint.find(item=>item.tag_serial == Object.keys(data[key])[0]) 

          
          let polylineFormat = {}
          if(findPOs){
            mk.push(moment(Object.values(data[key])[0]));
            let indexLoc = dataUniqueLocation.indexOf(findPOs.name);
            if(indexLoc === -1){
              dataUniqueLocation.push(findPOs.name);  
            }
          
            polylineFormat.levelIndex= findPOs.position.levelIndex?findPOs.position.levelIndex:0
            polylineFormat.x=findPOs.position.x +  ((0.1*(Math.random() - .1) * 10))
            polylineFormat.z=findPOs.position.z +  ((0.1*(Math.random() - .1) * 10))
            polylineFormat.elevation =5
            polylineFormat.name=findPOs.name
            polylineFormat.tag_serial=findPOs.tag_serial
            polylineFormat.time=Object.values(data[key])[0]
            polylineFormat.position=incCount;
            arr.push(polylineFormat);
            
          

          if(locName==Object.keys(data[key])[0]){

          }else{

            previousLocation.push(polylineFormat);
  
            if(previousLocation.length> 1){
              let oldloc = previousLocation[previousLocation.length - 2];
              let newloc = previousLocation[previousLocation.length - 1];
              let merge = {}
              merge.old={}
              merge.new={}
              merge.old.levelIndex=oldloc.levelIndex?oldloc.levelIndex:0;
              merge.old.x=oldloc.x?oldloc.x:"";
              merge.old.z=oldloc.z?oldloc.z:"";
              merge.old.elevation =oldloc.elevation?oldloc.elevation:"";
              merge.old.name=oldloc.name?oldloc.name:"";
              merge.old.tag_serial=oldloc.tag_serial?oldloc.tag_serial:""
              merge.old.time=oldloc.time?oldloc.time:""
              merge.new.levelIndex=newloc.levelIndex?newloc.levelIndex:0;
              merge.new.x=newloc.x?newloc.x:"";
              merge.new.z=newloc.z?newloc.z:"";
              merge.new.elevation =newloc.elevation?newloc.elevation:""
              merge.new.name=newloc.name?newloc.name:""
              merge.new.tag_serial=newloc.tag_serial?newloc.tag_serial:""
              merge.new.time=newloc.time?newloc.time:""
              merge.position=incCount;
            
              dualLocation.push(merge);

          
            }


            
            locName=Object.keys(data[key])[0];
            mk2.push(moment(Object.values(data[key])[0]));
          } 
          incCount++;       
      }
    });





 
 if(inc == stime.length){
   SetDefaultPoly(arr);
   
   arr = arr.sort(function(a,b){
    return new Date(a.time) - new Date(b.time);
  });
  mk = mk.sort(function(a,b){
    return  new Date(a) - new Date(b);
  });
   mk = mk.map((item)=> {return  moment(item).utc().format('hh:mm:ss A') })

   SetDualLocationList(dualLocation);

   SetSucessMessage(true);
   SetOldPosition(0)     
   setCount(arr.length);  
   SetMark(mk);

   SetLoaderEmp(false);
   SetSelectedStartTime(stime);
  //  SetEnable(true);
 }else{

 }


      }


  })  
  
} 

   
    
  }

  function onChangeEnable(val){
    
    // SetEnable(val);

    let data= [...filterTimeData];
    let filterData = defaultPoly.slice(0,(currentCount+1));
    
   
    if(val){

      let unqiueLoc=[];
      let prevName='';
      for(let i= 0;i<filterData.length;i++){
          if(filterData[i].name!=prevName){
            prevName = filterData[i].name
            filterData[i].time_spent = 30000;
            unqiueLoc.push(filterData[i])
          }else{
            prevName = filterData[i].name
            filterData[i - 1].time_spent = filterData[i - 1].time_spent + 30000;
            
          }
      }
      SetFilterTimeData(unqiueLoc);
    
    }else{
      SetFilterTimeData(filterData);
    
    }

  }
  function formatAMPMHour(date) {
    var hours = date;
    var minutes = 0;
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  function onChangeStartTime(val){

    let dept= shiftBasedDropdown;
  
    if(val.indexOf('all')> -1){
        let value =dept;
        if((val.length -1) == value.length ){
          SetStartTime([])
        }else{
          SetStartTime(value);
        }
    }else{
      SetStartTime(val);
    }
  }
  
  return (<>
    {permissionAPICall?permission.length > 0? 
    <div className="manpowerManagementMainDiv">
    <Container className="header-breadcrumb">
      <Row>
        <Col lg={6}>
          
          <div className='tabHeader'>
            
              {props.pages.filter(el=> el && el.sub_page_name =="Employee Tracker" ).length >0?<div className='tabHeaderList active' >
              <CommonHeading title="Employee Tracker"/>
              </div>:""}   
              {props.pages.filter(el=> el && el.sub_page_name =="Facility Tracker" ).length >0?<div className='tabHeaderList ' onClick={()=>props.history.push('/geo-map-management')}>
              <CommonHeading title="Facility Tracker" />
              </div>:""}
              {props.pages.filter(el=> el && el.sub_page_name =="Movement Analyzer" ).length >0?<div className='tabHeaderList ' onClick={()=>props.history.push('/movement-analyzer')}>
                  <CommonHeading title="Movement Analyzer" />
              </div>:""}
           </div>   
        </Col>
        <Col lg={6} className="text-right">
          <div className="commonLangaugeStyleDiv">
            <DashboardLanguage
              selectedLangValue={selectedLangValue}
              changeLanguage={changeLanguage}
            />
          </div>

          <div className="commonHeadingDateMainDivParent">
            <CommonDatePicker
              selectedDate={selectedDate}
              handleSelectDate={handleDateSelect}
                weekDays={props.weekDays}
                holiday={props.holiday}
workday={props.workday}
            />
          </div>
        </Col>
      </Row>

      <Row className="m-t" >
        <Col lg={12} >
          <div className='manStyle' style={{backgroundColor:"#fff",width:"100%",float:"left"}}>
            <div className='inlineBlockFilter'>
              <h5 style={{marginBottom:0,lineHeight:"35px"}}> Filter</h5>
            </div>
            <div className='inlineBlockFilter'>
            <Select
                placeholder="Selct User Type"
                optionFilterProp="children"
                onChange={(val)=>{SetSlug(val);setTimeout(function(){removeLayerSmplrSpace()},100)}}
                //defaultValue={'Select Department'}
                value={slug==''?'Select User Type':slug}
                key="departmentUserType"
                style={{width:140}}
                disabled={!lagFreeFlag}
                
            >
              <Option value='employee' key={"UserTypeRegular All"}>Regular Worker</Option>
              <Option value='daily_worker' key={"UserTypeDaily All"}>Daily Worker</Option>
              
            </Select>            
          </div>

            <div className='inlineBlockFilter'>
              <Select
                placeholder="Select Department"
                onChange={(val)=>{SetDepartmentID(val);setTimeout(function(){removeLayerSmplrSpace()},100) }}
                value={department_id==''?"Select Department":department_id}
                key="departmentFilterv1"
                style={{width:200}}
                dropdownClassName={'smallerDrop'}
                disabled={slug==''?true:false}
            >
             
                {
                department && department.length > 0? department.map((item)=>{
                    return <Option value={item.name} key={"Department V1"+item.name}>{item.name}</Option>
                }):""
                }
            </Select>
                </div>
                <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Sub Department"
                    optionFilterProp="children"
                    style={{width:200}}
                    onChange={(val)=>{SetSubDepartmentID(val);setTimeout(function(){removeLayerSmplrSpace()},100)  }}
                    disabled={department_id==''?true:false}                   
                    value={sub_department_id==''?"Select Sub Department":sub_department_id}
                    key="SubdepartmentFilterv1"
                    dropdownClassName={'smallerDrop'}

                >
                  <Option value='all' key={"Sub Department v1 All"}>Select All</Option>
                    {
                    sub_department && sub_department.length > 0? sub_department.map((item)=>{
                        return <Option value={item} key={"Sub Department V1"+item}>{item}</Option>
                    }):""
                    }
                    
                </Select>
                </div>
                <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Employee"
                    optionFilterProp="children"
                    style={{width:150}}
                    onChange={(val)=>{SetEmployeeID(val);SetTagSerial(employeeAll.find(el=>el.worker_id==val)?.tag_serial);setTimeout(function(){removeLayerSmplrSpace()},100) }}
                    disabled={department_id=='' || sub_department_id.length == 0?true:false}                   
                    value={employeeID==''?"Select Employee":employeeID}
                    key="SubdepartmentFilterv1"
                    dropdownClassName={'smallerDrop'}
                >
                    {
                    employeeAll && employeeAll.length > 0? employeeAll.map((item)=>{
                        return <Option value={item.worker_id} key={"Employee V1"+item.emp_name}>{item.emp_name}</Option>
                    }):""
                    }
                    
                </Select>
                </div>
                <div className='inlineBlockFilter inlineBlockFilterLabel' style={{width:"150px"}}>
                  <label>Shift</label> <span style={{float:"right"}}>{shiftStarttime} - {ShiftEndtime}</span>
                </div>
                <div className='inlineBlockFilter'>
                <Select
                    placeholder="Select Time"
                    optionFilterProp="children"
                    style={{width:150}}
                    onChange={(val)=>{onChangeStartTime(val);setTimeout(function(){removeLayerSmplrSpace()},100)}}
                    value={start_time}
                    key="EndTimeV1"
                    dropdownClassName={'smallerDrop'}
                    disabled={shiftStarttime==''||ShiftEndtime==''}
                    mode={'multiple'}
                >
                  <Option value='all' key={"All shift Hour v1 All"}>All Shift Hour</Option>
                    {
                    shiftBasedDropdown.map((item)=>{

                        return <Option value={item} key={"Employee V2"+item}>{item+":00"}</Option>
                    })
                    }
                    
                </Select>
                </div>
                <div className='inlineBlockFilter'>
                  <div className="btndiv">
                      <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler()} disabled={ department_id==''  || start_time.toString()==''  || employeeID==''}>Submit</button>
                      
                  </div>                
                </div>


</div>
</Col>
    </Row>






      <Row className="m-t">
        <Col lg={9} className='tabletfull' ref={elementRef}>
 
 
                    {loaderEmp? <div className='loaderStyle' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>
                        <span>Fetching Employee detail please wait</span>
                         <span className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                          </span>
                      </h4> 
                    
                    

                  </div></div>:''}
                  
                  {sucessMessage && defaultPoly.length >0?<div className='loaderStyle loadStyleSucess' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>Ready</h4> 
                    
                    

                  </div></div>:sucessMessage && defaultPoly.length ==0?<div className='loaderStyle loadStyleError' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>Oops! This Hour data not available</h4> 
                  </div></div>:""}
                  {isloading? <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:spaceID!=''?<>
                                         <div ><Viewer onReady={onReady} spaceID={spaceID} changeTab={changeTab} 
                                          /></div>
                                          <div position='center' my='md'>
        
       
        <Row classNam
        e='nicerFormatRange'>
          <Col lg={12}>
            <Slider
              //marks={marks}
            
              min={0} 
               max={defaultPoly.length==0?120:defaultPoly.length}
              value={currentCount}
              onChange={HandleRange}
               disabled={!space}
              tooltipVisible={false}
              //  tooltip={{
              //   formatter: value => value 
              // }}
            />
            {space && defaultPoly.length > 0 && marks[currentCount]?<div className='sliderLeftTooltip'>
                <div className='tooltipCustom' style={{left:( ((currentCount * width)/ 120) *100)/(width)+"%"}}>
                  <span className='tooltipArea' style={{float:"right"}}>
                   {marks[currentCount - 1]}
                  </span>
                </div>
              {/* left {width} {(currentCount * width)/ 120} {  } */}
            </div>:""}


            {/* <Button disabled={!space} className='autoplayStop btn-smaller' onClick={autoPlayhandler}>{autoPlay&&currentCount<=(icons.length-1)?<Play/>:<Pause/>}</Button>   */}
          </Col>
          <Col lg={2}>
          
          </Col>
          {/* <Col lg={5}>
            <Slider
              marks={marks2}
              defaultValue={currentCount} 
              value={currentCount}
              tooltipVisible={false} 
              onChange={HandleRange}
              disabled={!space}
              key={'range2'}
            />
          </Col> */}
        </Row>
          
      </div>                                
                                    </>:<h2>Please Setup Geo Map or contact to administrator</h2>}








                  </Col>
          <Col lg={3} className='tabletfull p-l-0'>
          <div className="legendArea manStyle legendArea2" style={{height:heightMap+50,background:"#fff"}}>
            {filterTimeData.length>0?<div className='legendSmallDate'>Date <strong>{startBigger ===true  && start_time < 11 ?moment(selectedDate, "YYYY-MM-DD").add(1, 'days').format("MMM DD"):moment(selectedDate).format("MMM DD")}</strong> ( { formatAMPMHour(start_time)})</div>:""}
            <div className='legendStart'>
            {/* <div className='legendSwitch'>
                
                <Switch defaultChecked={enable} onChange={onChangeEnable} />
                <span className='title'>{enable?"Location Log":"All Log"}</span>
              </div> */}
              <div className={!space?enable?'legendList legendHeader disable three-colsHeader':'legendList legendHeader disable':enable?'legendList legendHeader  three-colsHeader':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Location</strong></div>
                  {enable?<div className='legendLeftAreaTime'><strong>Spend</strong></div>:""}
                  <div className='legendRightAreaProd'><strong>Time</strong></div>
              </div>

                    <Scrollbars style={{height:(heightMap -50)}}>{filterTimeData.map((item,index)=>{
                   
                      return(
                                <div key={"tracker"+item.time+index} className={!space?enable?'legendList  disable three-colsHeader':'legendList  disable':enable?'legendList three-colsHeader':'legendList'}>
                                        <div className='legendLeftAreaProd'>{item.name}</div>
                                        {enable?<div className='legendLeftAreaTime'>{moment.utc(item.time_spent).format("HH:mm:ss")}</div>:""}
                                        <div className='legendRightAreaProd'>{convertTimeZone(item.time)}</div>
                                </div>
                                )
                    })}</Scrollbars>

      {/* <div className={!space?'legendList legendHeader legendFooter disable':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Total</strong></div>
                  <div className='legendRightAreaProd'><strong>{0}</strong></div>
              </div> */}

                </div>

          </div>
        </Col>        

      

      </Row>
    </Container>
  </div>:
<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}
                            </>

  )
}

export default Simplr