import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";

import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";

import { Tabs, Table, Collapse } from 'antd';
import Highcharts from 'highcharts';

import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import { getChatGPTRecommendation, getFopReportFilter, getOverallPerformanceReport, getPerformanceHistory } from "../../actionMethods/actionMethods";

import spinnerLoader from "../../../assets/images/loader.svg";
import ExcelJS from 'exceljs';
import TraceLogo from '../../../assets/traceplusImages/trace_logo.png'
import { ToastContainer, toast } from "react-toastify";

import { getAttendanceTime } from "../../actionMethods/actionMethods";
import { getOnBoardEmp } from "../../../costManagement/actionMethods/actionMethods";
import ReactHighcharts from "react-highcharts";
import Scrollbars from "react-custom-scrollbars";
import AttendanceUseCases from "./AttendanceUseCases";
import ProcessUseCases from "./ProcessUseCases";
import { RetainedQueryData } from "maplibre-gl";
import {ReactComponent as EyeIcon} from '../../../assets/images/eye.svg'
import { Button, Modal } from 'antd';




const { TabPane } = Tabs;
const { Panel } = Collapse;


export default function ShiftAnalysis (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });


    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };

    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;
    
      
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [plannedEmp,SetPlannedEmp]= useState([])
    const [employee,SetEmployee]= useState([]);
    const [filterData,SetFilterData] = useState([]);
    const [chartData,SetChartData] = useState([]);
    const [chartData2,SetChartData2] = useState([]);
    const [activeTab,SetActiveTab] = useState('percent');
    
    const [performanceAll,SetPerformanceAll] = useState([]);
    const [performanceThreeshold,SetPerformanceThreeshold] = useState([]);
    const [recommendation1,SetRecommendation1] = useState([])
    const [recommendation1Loader,SetRecommendation1Loader]= useState(false);
    const [recommendation2Loader,SetRecommendation2Loader]= useState(false);
    const [recommendation2,SetRecommendation2] = useState([])

    const [open, setOpen] = React.useState(false);
    const [activeRecommendation,SetActiveRecommendation]= useState('');

    const [modal,SetModal]= useState(false);

    

    const indonesiaTimeZone = 'Asia/Jakarta';  
    let workday = props.workday;
    let start_hour= props.workday.start_hour?props.workday.start_hour.split(":"):'07:00:00'.split(":");
    let end_hour= props.workday.end_hour?props.workday.end_hour.split(":"):'06:00:00'.split(":");
      const [config,SetConfig]= useState({
        chart: {
            type: 'spline', // Change type to spline
            height:250
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
            tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment = Math.ceil((this.dataMax - this.dataMin) / 6);
    
                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            }
        },
        yAxis: {
          min:0,
            title: {
                text: ''
            },
                        labels: {
                enabled: true,
            },
            gridLineWidth:0,
            labels: {
                useHTML:true,
                formatter: function () {
                        return this.value+"%"                         
                }
            },
            min:0,
            max:100,
            tickAmount:6,


        },
        tooltip: {
            shared: true,
            visible: true // Set tooltip always visible
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: true
                }
            }
        },
        series: []
      });
      
      const [config2,SetConfig2]= useState({
        chart: {
            type: 'spline', // Change type to spline
            height:250
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: [],
            tickPositioner: function () {
                let positions = []
                let tick = Math.floor(this.dataMin)
                let increment = Math.ceil((this.dataMax - this.dataMin) / 6);
    
                if (this.dataMax === this.dataMin) {
                    return [0]
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            }
        },
        yAxis: {
          min:0,
            title: {
                text: ''
            },
                        labels: {
                enabled: true,
            },
            gridLineWidth:0,
            
            min:0,
                        tickAmount:6,


        },
        tooltip: {
            shared: true,
            visible: true // Set tooltip always visible
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: true
                }
            }
        },
        series: []
      });
      
  const [loader,SetLoader]= useState(true);


const columns = [
  { headerName: 'Name',title:"Name", dataIndex:"name", field: 'name' },
  { headerName: 'Worker ID',title:"Worker ID",dataIndex:"worker_id", field: 'worker_id' },
  { headerName: 'Agency',title:"Agency",dataIndex:"agency", field: 'agency' },
];

function capitalizeFirstLetterNew(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
 function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }

  const month=[
        {month:"Jan",days:31,intMonth:1},
        {month:"Feb",days:28,intMonth:2},
        {month:"Mar",days:31,intMonth:3},
        {month:"Apr",days:30,intMonth:4},
        {month:"May",days:31,intMonth:5},
        {month:"Jun",days:30,intMonth:6},
        {month:"Jul",days:31,intMonth:7},
        {month:"Aug",days:31,intMonth:8},
        {month:"Sep",days:30,intMonth:9},
        {month:"Oct",days:31,intMonth:10},
        {month:"Nov",days:30,intMonth:11},
        {month:"Dec",days:31,intMonth:12},
    ]    

  const getMonths = (fromDate, toDate, performance) => {
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];
    if (performance == "month") {
      let loop = 0;
      for (let year = fromYear; year <= toYear; year++) {
        let monthNum = year === fromYear ? fromMonth : 0;
        const monthLimit = year === toYear ? toMonth : 11;

        for (; monthNum <= monthLimit; monthNum++) {
          let month2 = monthNum + 1;
          let MonthAct = month.find((item) => item.intMonth == month2);
          let start = "";
          let end = "";
          let cond = "";

          if (loop == 0 && year == toYear && monthNum == monthLimit) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 1";
          } else if (loop == 0) {
            start = moment(fromDate).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 2";
          } else if (year == toYear && monthNum == monthLimit) {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(toDate).format("YYYY-MM-DD");
            cond = "conditon 3";
          } else {
            start = moment(`${year}-${month2}-01`).format("YYYY-MM-DD");
            end = moment(start).endOf('month').format('YYYY-MM-DD');
            cond = "conditon 4";
          }
          loop++;

          months.push({
            year,
            month: month2,
            start: start,
            end: end,
            cond: cond,
          });
        }
      }

      return months;
    }
    // else {return getMonths(fromDate, toDate,'month');}
   else {

      return generateWeeksBard(fromDate, toDate);
    }
  };

function generateWeeksBard(startDate, endDate) {
  const weeks = [];
  let currentDate = moment(startDate);
  const finalDate = moment(endDate);

  // Adjust the first week to end on the next Sunday
  let firstWeekEnd = moment(currentDate).day(7); // Sunday of the first week

  if (firstWeekEnd > finalDate) {
    firstWeekEnd = finalDate;
  }

  weeks.push({
    start: moment(currentDate).format('YYYY-MM-DD'),
    end: moment(firstWeekEnd).format('YYYY-MM-DD'),
    month: moment(currentDate).month() + 1,
    year: moment(currentDate).year(),
    weekName: `Week ${getWeekNumber(moment(currentDate).toDate())}`,
  });

  currentDate = moment(firstWeekEnd).add(1, 'days');

  // Process full weeks from Monday to Sunday
  while (currentDate <= finalDate) {
    let weekStart = moment(currentDate).day(1); // Set to Monday
    let weekEnd = moment(weekStart).day(7); // Set to Sunday

    if (weekEnd > finalDate) {
      weekEnd = finalDate;
    }

    weeks.push({
      start: moment(weekStart).format('YYYY-MM-DD'),
      end: moment(weekEnd).format('YYYY-MM-DD'),
      month: moment(weekStart).month() + 1,
      year: moment(weekStart).year(),
      weekName: `Week ${getWeekNumber(moment(weekStart).toDate())}`,
    });

    currentDate = moment(weekEnd).add(1, 'days');
  }

  return weeks;
}

function getWeekNumber(date) {
  const oneJan = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - oneJan) / 86400000);
  return Math.ceil((days + oneJan.getDay() + 1) / 7);
}


const getRandomValue = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const generateData = (startDate, endDate,morningMinMax = {min:50,max:100},afternoonMinMax = {min:50,max:100},nightMinMax = {min:50,max:100}) => {

  const data = [];
  let currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(moment(endDate))) {
    const dateStr = currentDate.format('YYYY-MM-DD');
    
    const morning = getRandomValue(morningMinMax.min, morningMinMax.max);
    const afternoon = getRandomValue(afternoonMinMax.min, afternoonMinMax.max);
    const night = getRandomValue(nightMinMax.min, nightMinMax.max);
    const overall = Number(((morning + afternoon + night)/3).toFixed(2));



    data.push({
      date: dateStr,
      overall,
      morning,
      afternoon,
      night,
    });

    currentDate = currentDate.add(1, 'days');
  }
  return data;
};


const formatPerformanceData = (apiData,type) => {
  const formattedData = [];
  let highPerformanceData =[];


  for (const day in apiData.data.performance_averages) {
    let dayName =day
    const dayData = apiData.data.performance_averages[day];
    const lowPerformanceData = apiData.data.low_performance[day];
      if(type==="day"){
          dayName =  moment(`01-01-${moment(props.filterPassData.start_date).format('YYYY')}`).add(parseInt(day.replaceAll('day ',"")  - 1),'days').format("DD MMM YY");
      }else if(type==="month"){
        dayName =  moment('01-01-2024').add(parseInt(day.replaceAll('month ',"")   - 1),'month').format("MMMM")
      }
      


    formattedData.push({
      date: dayName, // or convert to date if needed
      overall: lowPerformanceData.overall,
      overall_percentage: lowPerformanceData.overall_percentage,
      morning: lowPerformanceData ? lowPerformanceData.P : null,
      morning_percentage: lowPerformanceData ? lowPerformanceData.P_percentage : null,
      afternoon: lowPerformanceData ? lowPerformanceData.S : null,
      afternoon_percentage: lowPerformanceData ? lowPerformanceData.S_percentage : null,
      night: lowPerformanceData ? lowPerformanceData.M : null,
      night_percentage: lowPerformanceData ? lowPerformanceData.M_percentage : null
      
    });

    highPerformanceData.push({
      date: dayName, // or convert to date if needed
      overall: dayData.overall,
      morning: dayData ? dayData.P : null,
      afternoon: dayData ? dayData.S : null,
      night: dayData ? dayData.M : null
    })

  }

  return ({low:formattedData,high:highPerformanceData});
}


useEffect(()=>{
  let reportHead=[]
  SetLoader(true);
async function filterResultHandler(){
    try{
        if(props.filterActive){
          let requestBody = props.filterPassData;

          let resp = await getOverallPerformanceReport(userSession,org_id,sub_org_id,getDateFormat(props.filterPassData.start_date),getDateFormat(props.filterPassData.end_date),props.filterPassData.threeshold,props.filterPassData.reportBy);              
 


          let data = formatPerformanceData(resp,props.filterPassData.reportBy).high;

          let data2 = formatPerformanceData(resp,props.filterPassData.reportBy).low          
          
            let series= [];
            if(props.filterPassData.reportBy =="day"){
              data = data.sort((a,b)=>new Date(a.date) - new Date(b.date));
              data2 = data2.sort((a,b)=>new Date(a.date) - new Date(b.date));
            }
        
            series.push({name:"Overall",data:data.map(el=> {if(el.overall){return el.overall} else{ return 0} } )})
            series.push({name:"Morning",data:data.map(el=> {if(el.morning){return el.morning} else{ return 0} } )  })
            series.push({name:"Afternoon",data:data.map(el=>{if(el.afternoon){return el.afternoon} else{ return 0} })})
            series.push({name:"Night",data:data.map(el=>{if(el.night){return el.night} else{ return 0} })});

            
            let cat = data.map(el=>moment(el.date).isValid()? moment(el.date).format("DD MMM"):el.date);

            SetChartData({cat:cat,series:series});
            SetPerformanceAll(data);


            let series2 = [];
            let newConfig2 = {...config2}
            if(activeTab == 'count'){
            series2.push({name:"Overall",data:data2.map(el=> {if(el.overall){return el.overall} else{ return 0} } )})
            series2.push({name:"Morning",data:data2.map(el=> {if(el.morning){return el.morning} else{ return 0} } )  })
            series2.push({name:"Afternoon",data:data2.map(el=>{if(el.afternoon){return el.afternoon} else{ return 0} })})
            series2.push({name:"Night",data:data2.map(el=>{if(el.night){return el.night} else{ return 0} })});
            newConfig2.yAxis.min =0;
            if(newConfig2.yAxis.max){
              delete newConfig2.yAxis.max
            }  
          
            }else{
              series2.push({name:"Overall",data:data2.map(el=>{if(el.overall_percentage){return el.overall_percentage} else{ return 0} } )})
              series2.push({name:"Morning",data:data2.map(el=>{if(el.morning_percentage){return el.morning_percentage} else{ return 0} })})
              series2.push({name:"Afternoon",data:data2.map(el=>{if(el.afternoon_percentage){return el.afternoon_percentage} else{ return 0} })})
              series2.push({name:"Night",data:data2.map(el=>{if(el.night_percentage){return el.night_percentage} else{ return 0} } )});
              newConfig2.yAxis.min =0;
              newConfig2.yAxis.max =100;


            }
            
console.log("newConfig2",newConfig2);
            
            SetPerformanceThreeshold(data2);
            

             let newConfig={...config};
                    newConfig.xAxis.categories=cat;
                    newConfig.series = series; 

            SetConfig(newConfig);


                    newConfig2.xAxis.categories=cat;
                    newConfig2.series = series2;
            SetChartData2({cat:cat,series:series2});
            SetConfig2(newConfig2);

          
          SetLoader(false);






          
        }
              

    }catch(error) {
          
          } 
    }
    
    filterResultHandler()

},[props.filterPassData, props.filterFOP]);


useEffect(()=>{
if(performanceThreeshold.length > 0 ){
let series2 = [];
let data2 = [...performanceThreeshold];
let cat = data2.map(el=>moment(el.date).isValid()? moment(el.date).format("DD MMM"):el.date);


            let newConfig2 = {...config2}
            if(activeTab == 'count'){
                          series2.push({name:"Overall",data:data2.map(el=>el.overall)})
            series2.push({name:"Morning",data:data2.map(el=>el.morning)})
            series2.push({name:"Afternoon",data:data2.map(el=>el.afternoon)})
            series2.push({name:"Night",data:data2.map(el=>el.night)});
            
            newConfig2.yAxis.min =0;
                        newConfig2.yAxis.labels =  {
                useHTML:true,
                formatter: function () {
                        return this.value                        
                }
            }
            if(newConfig2.yAxis.max){
              delete newConfig2.yAxis.max
            }  
          
            }else{
            series2.push({name:"Overall",data:data2.map(el=>el.overall_percentage)})
            series2.push({name:"Morning",data:data2.map(el=>el.morning_percentage)})
            series2.push({name:"Afternoon",data:data2.map(el=>el.afternoon_percentage)})
            series2.push({name:"Night",data:data2.map(el=>el.night_percentage)});
            newConfig2.yAxis.min =0;
            newConfig2.yAxis.max =100;
            newConfig2.yAxis.labels =  {
                useHTML:true,
                formatter: function () {
                        return this.value+"%"                         
                }
            }
            }
      
           
            
                    newConfig2.xAxis.categories=cat;
                    newConfig2.series = series2;
            SetConfig2(newConfig2);
}
  

},[activeTab,performanceThreeshold])


useEffect(()=>{
  if(props.exportEnable){
    generateExcelFile(filterData);
  }
},[props.exportEnable,filterData])

 const generateExcelFile = async (data) => {
    
      props.exportFalseFOP();
  };

  function valueRemoveNull(val){
    if(isNaN(val)){
      return 0
    }else if(val===null || val===undefined){
      return 0
    }else{
      return val
    }

  }

    const generateSheet = async (workbook, sheetName,data, logoID) => {
    
  };
  

  function loadRecomend1(message){
    
    getChatGPTRecommendation('Hi gpt, this is performance data of organisation from '+moment(props.filterPassData.start_date).format('MMM DD')+' to '+moment(props.filterPassData.end_date).format('MMM DD')+'  data below'+JSON.stringify(message)+"defination of M:Night Shift, S:Afternoon shift, P:Morning shift. please share result only takeways point. So, What are takeaways from data? dont share in 1., 2. ways").then(res=>{
      
      let output = res.replaceAll("- ","").replaceAll("1. ","").replaceAll("2. ","").replaceAll("3. ","").replaceAll("4. ","").replaceAll("5. ","").replaceAll("6. ","").replaceAll("7. ","").replaceAll("8. ","").replaceAll("9. ","").replaceAll("10. ","").replaceAll("11. ","").replaceAll("12. ","").replaceAll("13. ","").replaceAll("14. ","").replaceAll("15. ","").split("\n").filter(el=>el!="");
      
      SetRecommendation1(output);
      SetRecommendation1Loader(false);
    })
  }

  

  function loadRecomend2(message){
    
    getChatGPTRecommendation('Hi gpt, this is data of % or count of Low performing workers in the organisation from '+moment(props.filterPassData.start_date).format('MMM DD')+' to '+moment(props.filterPassData.end_date).format('MMM DD')+'  data below'+JSON.stringify(message)+"defination of M:Night Shift, S:Afternoon shift, P:Morning shift. please share result as only takeways point. So, What are takeaways from data?").then(res=>{
      
      let output = res.replaceAll("- ","").replaceAll("1. ","").replaceAll("2. ","").replaceAll("3. ","").replaceAll("4. ","").replaceAll("5. ","").replaceAll("6. ","").replaceAll("7. ","").replaceAll("8. ","").replaceAll("9. ","").replaceAll("10. ","").replaceAll("11. ","").replaceAll("12. ","").replaceAll("13. ","").replaceAll("14. ","").replaceAll("15. ","").split("\n").filter(el=>el!="");
      
      SetRecommendation2(output);
      SetRecommendation2Loader(false);
    })
  }

  const getImageId = async (workbook, imageUrl) => {
    const response = await fetch(imageUrl);
    const arrayBuffer = await response.arrayBuffer();
    const base64Image = Buffer.from(arrayBuffer).toString('base64');

    return workbook.addImage({
      base64: base64Image,
      extension: 'png', // Change the extension based on your image format
    });
  };

  function capitalizeFirstLetter(string) {
      if(string){
        return string.toLowerCase();
      }
  }

  function AGGridEMptyMEssage(key){
    let msg;
        msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
    return msg
  }


const compressedTableStyles = {
  margin: 0,
  padding: 0,
};
const compressedRowStyles = {
  lineHeight: '1.2rem', // Adjust the line height as needed
};


 const getImageSize = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
      toast.error("Fail to export please again after sometime");
    }
  const blob = await response.blob();
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.src = URL.createObjectURL(blob);
  });
};

function AGTableShift(data,activeTab){    
    

        let arr;
        let date='';
        if(props.filterPassData.reportBy=="day"){
          date=             <AgGridColumn
              field="date"
              headerName={"Date"}
              valueFormatter={(params)=>params.value}
              cellClass={'textCapitalize textCap'}
              flex={1}
              width={150}
              maxWidth={200}
          />
        }else{
          date=             <AgGridColumn
              field="date"
              headerName={"Date"}
              valueFormatter={(params)=>params.value}
              cellClass={'textCapitalize textCap'}
              flex={1}
              width={150}
              maxWidth={200}
          />
        }

        if(activeTab=="percent"){
 arr=<div className={"keyAGTableSmallPlan"}><div className="ag-theme-alpine if cell-size-40" style={{minHeight:350,height:(data.length * 32) + 36,maxHeight:375 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={30}
            
              headerHeight={20}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45,flex: 1, suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
          {date}
          
          
          <AgGridColumn 
              field="morning_percentage" 
              headerName="Morning" 
              valueFormatter={(params) =>params.value?`${params.value}%`:`0%`}    
              cellClass={'textCapitalize textCap'}
              flex={1}
            />
          <AgGridColumn 
              field="afternoon_percentage" 
              headerName="Afternoon" 
              valueFormatter={(params) =>params.value?`${params.value}%`:`0%`}    
              cellClass={'textUppercase textCap'}
              flex={1}
             />
            <AgGridColumn 
              field="night_percentage" 
              headerName="Night" 
              valueFormatter={(params) =>params.value?`${params.value}%`:`0%`}    
              cellClass={'textCapitalize textCap'}
              flex={1}
             ></AgGridColumn>
            <AgGridColumn 
              field="overall_percentage" 
              headerName="Overall" 
              valueFormatter={(params) =>params.value?`${params.value}%`:`0%`}    
              
              flex={1}
             ></AgGridColumn>
 
      </AgGridReact>
      </div></div>
        }
        else{
          arr=<div className={"keyAGTableSmallPlan"}><div className="ag-theme-alpine if cell-size-40" style={{minHeight:350,height:(data.length * 32) + 36,maxHeight:375 , width: "calc(100% - 1px)"}}>
          <AgGridReact
              rowHeight={30}
            
              headerHeight={20}
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45,flex: 1, suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >
          {date}
          
          
          <AgGridColumn 
              field="morning" 
              headerName="Morning" 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
            />
          <AgGridColumn 
              field="afternoon" 
              headerName="Afternoon" 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              cellClass={'textUppercase textCap'}
              flex={1}
             />
            <AgGridColumn 
              field="night" 
              headerName="Night" 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              cellClass={'textCapitalize textCap'}
              flex={1}
             ></AgGridColumn>
            <AgGridColumn 
              field="overall" 
              headerName="Overall" 
              valueFormatter={(params) =>params.value?`${params.value}`:"-"}    
              
              flex={1}
             ></AgGridColumn>
 
      </AgGridReact>
      </div></div>
        }
      return arr
    
    }
  



    
     return (props.filterActive?
      loader?                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:

    <div className="App">
<ToastContainer/>
<Tabs defaultActiveKey="1">
      <TabPane tab="Use case 1  : Performance" key="1">
    <Row>
      <Col lg="12" className=""><h4 style={{fontSize:16,marginBottom:10}}>Performance <span style={{color:"#ef5e8c",textTransform:"capitalize"}}>({props.filterPassData.reportBy})</span></h4></Col>
      

                    
                    <Col lg={6} className="someStyleHeadline" style={{display:"table-cell"}}>
                                                {AGTableShift(performanceAll,"%")}

                    </Col>
      <Col lg={6}>
                     <div className="reccomentTop"> <div className="recommendBox" style={{border:"1px solid #ddd"}}>
                        <div className="reccomentHeader"><h4>Insights  <span className="recommendationEye" style={{display:recommendation1.length?"inline-block":"none"}} onClick={()=>{SetActiveRecommendation('recommendation1');setOpen(true)}}><EyeIcon/></span> <span className="reloadRecommend" onClick={()=> {SetRecommendation1Loader(true);loadRecomend1(performanceAll); }}>{recommendation1.length > 0? "Reload":"Load"}</span></h4></div>                       
                        <div className="recommentContent" >
                           {/* <div dangerouslySetInnerHTML={{ __html: recommendation1}} />  */}
                           <Scrollbars style={{height:100}}>
                                                         {recommendation1Loader?<div style={{textAlign:"center"}}><img src={spinnerLoader} width={"70px"} /></div>:
                           <ul style={{listStyle:"none"}}>
                            {recommendation1.map((el,index)=>{
                              return <li key={"reccomend "+index}><span className="bulletinCircle">{index+ 1}</span><span>{el}</span></li>
                            })}
                           </ul>}
                           </Scrollbars>

                        </div>
                       </div>
                       </div>
        <ReactHighcharts config={config}/>
      </Col>
      
    </Row>
      <hr />

    <Row>
     

<Col lg={6} className="">

              <div className="leftTitle">
          <h4 style={{fontSize:16}}>Below {props.filterPassData.threeshold}% Perfromance  <span style={{color:"#ef5e8c",textTransform:"capitalize"}}>({props.filterPassData.reportBy})</span> </h4>
        </div>  
        <div className="rightTitle">
      <div className="buttonFilterArea">
      <button onClick={() => SetActiveTab('percent')} className={activeTab === 'percent' ? 'buttonChartFilter buttonChartFilterActive button btn' : 'buttonChartFilter button btn'}>Percent </button>
            <button onClick={() => SetActiveTab('count')} className={activeTab === 'count' ? 'buttonChartFilter buttonChartFilterActive button btn' : 'buttonChartFilter button btn'} > Count</button>
      </div>
      </div>

</Col>
<Col lg={6}></Col>
</Row>
<Row>

      
                    <Col lg={6} className="someStyleHeadline" style={{display:"table-cell"}}>
                       {AGTableShift(performanceThreeshold,activeTab)}

                    </Col>
                    <Col lg={6}>
                                           <div className="reccomentTop"> <div className="recommendBox" style={{border:"1px solid #ddd"}}>
                        <div className="reccomentHeader"><h4>Insights <span className="recommendationEye"  onClick={()=>{SetActiveRecommendation('recommendation2');setOpen(true)}} style={{display:recommendation2.length?"inline-block":"none"}}><EyeIcon/></span> <span className="reloadRecommend" onClick={()=> {SetRecommendation2Loader(true);loadRecomend2(performanceThreeshold); }}>{recommendation2.length > 0? "Reload":"Load"}</span> </h4></div>
                        <div className="recommentContent">

                          <Scrollbars style={{height:100}}>
                              {recommendation2Loader?<div style={{textAlign:"center"}}><img src={spinnerLoader} width={"70px"} /></div>:
                           <ul style={{listStyle:"none"}}>
                            {recommendation2.map((el,index)=>{
                              return <li key={"reccomend "+index}> <span className="bulletinCircle">{index+ 1}</span> <span>{el}</span></li>
                            })}
                           </ul>}
                           </Scrollbars>

                        </div>
                       </div>
                       </div>


        <ReactHighcharts config={config2}/>
      </Col>
      
    </Row>
        
        </TabPane>
        <TabPane tab="Use case 2  : Attendance" key="2">
          
        <AttendanceUseCases 
          filterPassData={props.filterPassData}
          widthContainer={props.widthContainer} 
          workday={props.workday}
          filterActive={props.filterActive}
          exportEnable={props.exportEnable} 
          exportFalseFOP={()=>props.SetExportEnable(false)}
        />
      </TabPane>
      <TabPane tab="Use case 3  : Process Movement" key="3">
        <ProcessUseCases
                  filterPassData={props.filterPassData}
          widthContainer={props.widthContainer} 
          workday={props.workday}
          filterActive={props.filterActive}
          exportEnable={props.exportEnable} 
          exportFalseFOP={()=>props.SetExportEnable(false)}
        />

      </TabPane>
  </Tabs>

<Modal
        title={"Insights"}
        footer={null}

        open={open}
        visible={open}
        onCancel={() => setOpen(false)}
      >
        <ul>
          <Scrollbars style={{height:400}}>
          {activeRecommendation=="recommendation1"?recommendation1.map((el,index)=>{
            return <li key={"recommendModa "+index}> <span className="bulletinCircle">{index+ 1}</span> <span>{el}</span></li>
          }):
          recommendation2.map((el,index)=>{
            return <li key={"recommendModal2 "+index}> <span className="bulletinCircle">{index+ 1}</span> <span>{el}</span></li>
          })
      }
      </Scrollbars>          
        </ul>
        
      </Modal>
    </div>:""
    )

}